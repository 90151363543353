import { Button } from '@geberit/gdds';

// styles
import styles from './contactModuleItem.module.scss';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Translation } from 'components/Translation/Translation';
import { ExternalLink } from 'components/Link/ExternalLink';

// utils
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';

type ContactModuleItemProps = {
  name: string;
  address: string;
  mail: string;
  phone: string;
  availability: string;
  buttonTarget: string | false;
  isDetail: boolean;
};

export function ContactModuleItem({
  name,
  address,
  mail,
  phone,
  availability,
  buttonTarget,
  isDetail,
}: Readonly<ContactModuleItemProps>) {
  return (
    <div className={classNameBuilder(styles.contactModuleItem, isDetail && styles.isDetail)}>
      {name && <InnerHtml as="h3" className={styles.contactName} content={name} />}
      {address && <InnerHtml as="div" className={styles.contactAddress} content={address} />}

      <div className={styles.contactModuleItemBottom}>
        <div className={styles.contactOptions}>
          {mail && (
            <ExternalLink
              content={{
                target: `mailto:${decodingContent(mail)}`,
              }}
            >
              <span className={styles.contactMail}>{decodingContent(mail)}</span>
            </ExternalLink>
          )}

          {phone && (
            <ExternalLink
              content={{
                target: `tel:${decodingContent(phone)}`,
              }}
            >
              <span className={styles.contactPhone}>{decodingContent(phone)}</span>
            </ExternalLink>
          )}
        </div>

        {!isEmpty(availability) && (
          <div className={styles.contactAvailability}>
            <strong>
              <Translation id="campus_availability" />
            </strong>
            <InnerHtml as="div" content={availability} />
          </div>
        )}
      </div>
      {buttonTarget && (
        <div className={styles.contactModuleFurther}>
          <Button
            onClick={() => {
              window.open(buttonTarget, '_blank');
            }}
          >
            <Translation id="campus_more_details" />
          </Button>
        </div>
      )}
    </div>
  );
}
