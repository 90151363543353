// components
import Togglebutton from '../fields/toggle-button/toggle-button';
import InputField from '../fields/input-field/input-field';
import InlineText from '../fields/inline-text/inline-text';
import DateOrTimePicker from '../fields/date-or-time-picker/date-or-time-picker';
import Separator from '../fields/separator/separator';
import { SubmitForm } from '../fields/submit-form/submit-form';
import PreconDropdownContainer from '../fields/dropdown/precon-dropdown-container';
import Dropdown from '../fields/dropdown/dropdown';

export const PRE_CON_VARIABLE_NAMES = ['name', 'nameColor', 'namePanel', 'nameArtId'];
export const PRE_CON_INIT_VALUE_MAPPING = ['modelName', 'color', 'colorPanel', 'modelNumber'];
export const PRE_CON_LABEL_MAPPING = ['label', 'labelColor', 'labelPanel'];

export const FORM_WITH_CONFIRMATION_MESSAGE = ['leadmodule', 'oxomi', 'downloadCenter'];
export const TRACKING_SUBMIT_NAME = { oxomi: 'Oxomi', leadmodule: 'Lead Module' };

/**
 * Map fs type to get the needed fed Field type
 */
export const CUSTOM_FIELD_MAPPING = {
  datepicker: 'date',
  timepicker: 'time',
  file: 'file',
  name: 'text',
  subject: 'text',
};

// firstspirit form field type to react component mapping
export const FIELD_MAPPING = {
  form_field_dropdown: Dropdown,
  form_field_radiobuttons: Togglebutton,
  form_field_radiobutton_images: Togglebutton,
  form_field_checkbox: Togglebutton,
  form_field_checkbox_crm: Togglebutton,
  form_field_checkbox_images: Togglebutton,
  form_field_checkbox_group: Togglebutton,
  form_field_button: SubmitForm,
  form_field_text: InputField,
  form_field_email: InputField,
  form_field_password: InputField,
  form_field_datepicker: DateOrTimePicker,
  form_field_timepicker: DateOrTimePicker,
  form_field_hidden: InputField,
  form_field_number: InputField,
  form_field_inlinetext: InlineText,
  form_field_textarea: InputField,
  form_field_file_upload: InputField,
  form_separator: Separator,
  form_field_dropdown_models: PreconDropdownContainer,
};

/**
 * Array of invalid file types
 */
export const INVALID_FIELD_TYPES = [
  'application/bat',
  'application/textedit',
  'application/x-bat',
  'application/x-dosexec',
  'application/x-javascript',
  'application/x-msdos-program',
  'application/x-msdownload',
  'application/x-httpd-php',
  'text/javascript',
  'text/html',
];
