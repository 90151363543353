import { useEffect, useRef } from 'react';

// types
import type { ChannelMainVideoProps } from './types';

// components
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import ConfigurableYoutube from './configurable-youtube';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { useHashState } from 'utils/hooks/use-hash-state';

export default function ChannelMainVideo({ video, title }: Readonly<ChannelMainVideoProps>) {
  const [hash] = useHashState();
  const sectionId = useSectionId(title, '', false, 0);
  const videoItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash === video.videoId && videoItemRef.current) {
      videoItemRef.current.scrollIntoView();
    }
  }, [hash, video.videoId]);

  return (
    <div id={sectionId} ref={videoItemRef}>
      {video.type === 'youtube' ? (
        <ConfigurableYoutube
          videoId={video.videoId}
          playerControl={video.playerControl}
          language={video.language}
          showLink={video.showLink}
        />
      ) : (
        <MiVideoContainer
          className="c-text-video"
          content={{
            video: video,
            autoplay: false,
            buttonText: '',
            videoVariant: 'buttonOnly',
          }}
        />
      )}
    </div>
  );
}
