import { BaseListItem, SimpleListItem } from "../product.types";

export const panelUuids = {
  additionalInformation: 'additionalInformation',
  applicationPurposes: 'applicationPurposes',
  characteristics: 'characteristics',
  detail: 'detail',
  documents: 'documents',
  notIncluded: 'notIncluded',
  scopeOfDelivery: 'scopeOfDelivery',
  spareParts: 'spareParts',
  technicalDataList: 'technicalDataList',
  technicalDrawings: 'technicalDrawings',
} as const;

const sortList = (a: BaseListItem, b: BaseListItem) => a.sequence - b.sequence;
const mapName = (item: SimpleListItem) => item.name;
export const sortScope = (scope: SimpleListItem[]) => scope.sort(sortList).map(mapName);
