import styled from 'styled-components';
import { styleConfig } from 'config';
import { pxToRem } from 'utils/pxToRem';

export const ButtonWrapperNord = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;

  @media ${({ theme }) => theme.device.medium} {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  @media (max-width: ${pxToRem(styleConfig.responsive.tabletMin)}) {
    button {
      min-width: 100%;
    }
  }
`;
