import { Toggle } from '@geberit/gdds';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash.isequal';
import key from 'weak-key';

// styles
import styles from 'components/ContentElements/Form/form.module.scss';

// components
import ToggleButtonView from './toggle-button-view';
import { Image } from 'components/image/image';

// utils
import { useGdds } from 'utils/hooks/use-gdds';
import { isEmpty } from 'utils/is-empty';

export type ToggleButtonField = {
  name: string;
  type: string;
  fieldType: string;
  alignment: string;
  label: string;
  comment: string;
  rules: Rule[];
  options?: Option[];
  checkedValue?: string;
  reduceBottomDistanceToNextElement?: boolean;
};

interface ToggleButtonProps {
  field: ToggleButtonField;
  hasValidPrecondition?: string;
}

export default function ToggleButton({ field, hasValidPrecondition }: Readonly<ToggleButtonProps>) {
  const { fieldType, name, type, alignment, label, rules } = field;
  let { options } = field;
  const { setFieldValue, setTouched, values, errors, touched } =
    useFormikContext<Record<string, string | string[]>>();
  const isGdds = useGdds();
  const isCheckbox = type === 'checkbox';

  const withImage =
    fieldType === 'form_field_radiobutton_images' || fieldType === 'form_field_checkbox_images';
  if (fieldType === 'form_field_checkbox_crm' && !options) {
    options = [
      {
        title: label,
        value: name,
      },
    ];
  }

  useEffect(() => {
    // if this field has a precondition and this is not enabled, don't throw an error
    if (errors[name] && hasValidPrecondition) {
      delete errors[name];
    }

    // reset value if it is hidden
    const defaultValue = isCheckbox ? [] : '';
    if (values[name] && !isEqual(values[name], defaultValue) && hasValidPrecondition) {
      setFieldValue(name, defaultValue);
    }
  }, [errors, hasValidPrecondition, isCheckbox, name, setFieldValue, values]);

  /**
   * Change toggle values in formik values
   *
   * @param value
   */
  const handleToggleChange = (value: string) => {
    let nextValues;

    if (isGdds) {
      setTouched({ ...touched, [name]: true });
    }

    if (isCheckbox && values[name].includes(value)) {
      nextValues = (values[name] as string[]).filter((checkedValue) => checkedValue !== value);
      setFieldValue(name, nextValues);
    } else if (isCheckbox) {
      nextValues = values[name].concat(value);
      setFieldValue(name, nextValues);
    } else {
      setFieldValue(name, value);
    }
  };

  /**
   * Create list of classnames
   *
   * @returns {string}
   */
  const getClassNames = () => {
    const classes = ['form-items'];

    if (withImage) {
      classes.push('form-items--images');
    }

    if (!withImage && alignment === 'vertical') {
      classes.push('form-items--vertical');
    }

    if (!isEmpty(errors[name]) && touched[name] && !hasValidPrecondition) {
      classes.push('form-item--error');
    }

    if (field.reduceBottomDistanceToNextElement) {
      classes.push('consent-form-checkbox');
    }

    return classes.join(' ');
  };

  if (isGdds) {
    const required =
      !isEmpty(rules) && rules.some((attribute) => attribute.formValidator.includes('NotEmpty'));

    return (
      <div
        className={
          !isEmpty(errors[name]) && touched?.[name] ? `form-item--error ${styles.hasError}` : ''
        }
      >
        {fieldType !== 'form_field_checkbox_crm' && (
          <span className={styles.gddsToggleLabel}>
            {label}
            {required ? '*' : ''}
          </span>
        )}
        {withImage ? (
          <div className={`${styles.gddsToggleButtons} ${styles.gddsToggleButtonsImage}`}>
            {options?.map((option, i) => (
              <div key={key(option)}>
                {withImage && (
                  <Image
                    format={option.imageObject?.format}
                    data={option.imageObject}
                    alt={option.title}
                    aspectRatio="1_1"
                  />
                )}
                <Toggle
                  type={type as 'radio' | 'checkbox' | 'checkboxGroup'}
                  name={name}
                  label={label}
                  items={[{ text: option.title, ...option }]}
                  values={values}
                  errors={touched?.[name] && errors}
                  onChange={handleToggleChange}
                  required={required}
                  alignByContent="left"
                  alignment={(alignment as 'horizontal' | 'vertical' | undefined) ?? 'horizontal'}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.gddsToggleButtons}>
            <Toggle
              type={type as 'radio' | 'checkbox' | 'checkboxGroup'}
              name={name}
              label={label}
              items={options?.map((i) => ({ text: i.title, ...i }))}
              values={values}
              errors={touched?.[name] && errors}
              onChange={handleToggleChange}
              required={required}
              alignByContent="left"
              alignment={(alignment as 'horizontal' | 'vertical' | undefined) ?? 'horizontal'}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <ToggleButtonView
      field={{ ...field, options }}
      className={getClassNames()}
      withImage={withImage}
      handleToggleChange={handleToggleChange}
    />
  );
}
