import { useEffect } from 'react';
import { Button } from '@geberit/gdds';
import { useRouter } from 'next/navigation';
import striptags from 'striptags';

// styles
import styles from './coursebookedcontent.module.scss';

// components
import ProgressBar from '../Course/ProgressBar';
import { Loader } from 'components/Loader/Loader';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import {
  useCourseSubitemDescription,
  useScormAuth,
  useCampusMediaStateStarted,
  useCampusMediaStatePassed,
  useCampusAuthGate,
  useCampusEnv,
} from 'utils/hooks/useCampus';
import { emptyStringFallback } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';

interface CourseModuleProps {
  progress: number;
  courseId: number;
  subItemId: number;
  language: string;
  forceStartable: boolean;
}

export function CourseModule({
  courseId,
  subItemId,
  language,
  progress,
  forceStartable = false,
}: Readonly<CourseModuleProps>) {
  const router = useRouter();
  const { openScorm } = useScormAuth(courseId);
  const translate = useTranslationFunction();
  const [data, isLoading] = useCourseSubitemDescription(courseId, subItemId, language);
  const media = data?.media;
  const mediaId = media?.id;
  const startLabel = translate(media?.contentType === 'FILE' ? 'campus_open' : 'campus_start');
  const isLocked = !forceStartable && progress === 0;
  const isDone = progress > 99;
  const { setStarted } = useCampusMediaStateStarted(courseId);
  const { setPassed } = useCampusMediaStatePassed(courseId);
  const { employeeToken, ciamToken } = useCampusAuthGate(courseId);
  const { CAMPUS_BASE_URL } = useCampusEnv();
  let timeoutId: NodeJS.Timeout;

  const accessToken = emptyStringFallback(ciamToken, employeeToken);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  if (isLoading && !data) {
    return <Loader />;
  }

  return (
    <li
      className={classNameBuilder(
        styles.module,
        isDone && styles.moduleFinished,
        isLocked && styles.moduleLocked,
      )}
    >
      <ProgressBar progress={progress} locked={isLocked} isMobile={false} />
      <div className={styles.textBody}>
        <strong>{media.name}</strong>
        <p>{decodingContent(striptags(media.description))}</p>
      </div>
      <div className="actions">
        {media?.contentType !== 'LINK' ? (
          <Button
            buttonType="button"
            disabled={isLocked}
            onClick={() => {
              if (media.contentType === 'MEDIA_FILE') {
                router.push(`#content-${mediaId}`);
              } else if (media.contentType === 'FILE') {
                // for files we need to set the media as started to set it to done
                setStarted({ mediaId, accessToken });
                timeoutId = setTimeout(() => setPassed({ mediaId, accessToken }), 500);
                Object.assign(document.createElement('a'), {
                  target: '_blank',
                  href: `${CAMPUS_BASE_URL}${media.mediaFileLink}`,
                }).click();
              } else {
                openScorm(mediaId);
              }
            }}
          >
            {!forceStartable && !isDone && !isLocked && translate('campus_continue')}
            {!isDone && (forceStartable || isLocked) && startLabel}
            {isDone && translate('campus_repeat')}
          </Button>
        ) : (
          <Button
            buttonType="button"
            disabled={isLocked}
            onClick={() => {
              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: media.source,
              }).click();
            }}
          >
            {startLabel}
          </Button>
        )}
      </div>
    </li>
  );
}
