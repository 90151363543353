import { Col, Container, Row, Typo } from '@geberit/gdds';

// styles
import styles from 'features/download-center/components/nordics/dlc-nordics.module.scss'

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { Translation } from 'components/Translation/Translation';

// utils
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types'
import { buildSize, gridSizes } from 'utils/gridSize';
import { useTranslationFunction } from 'utils/hooks/use-translations';

type NoResultProps = {
  query: string;
  headline: string;
};

function NoResult({ query = '', headline = '' }: Readonly<NoResultProps>) {
  const translate = useTranslationFunction();

  return (
    <>
      <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
        <Row justify="center">
          <Col size={[4, 8, 8]}>
            <Headline
              isFlexItem
              className={styles.noResultsHeadline}
              tag={Formats.h2}
              title={headline}
            />
            <Typo tag='p'>
              <Translation id="web20_search_no-matches" placeholder={{ query }} />
            </Typo>
            <Headline
              isFlexItem
              className={styles.suggestionsHeadline}
              tag={Formats.h4}
              variant={Formats.subtitle}
              title={translate('web20_search_suggestions')}
            />
            <ul>
              <li>
                <Typo tag='p'>
                  <Translation id="web20_search_spelled-correctly" />
                </Typo>
              </li>
              <li>
                <Typo tag='p'>
                  <Translation id="web20_search_different-keywords" />
                </Typo>
              </li>
              <li>
                <Typo tag='p'>
                  <Translation id="web20_search_general-keywords" />
                </Typo>
              </li>
              <li>
                <Typo tag='p'>
                  <Translation id="web20_search_less-keywords" />
                </Typo>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NoResult;
