import React from 'react';

// types
import type { VideoItemLargeProps } from 'components/Video/VideoItemLarge';

// components
import VideoItem from 'components/Video/VideoItem';

type VideoSliderItemProps = VideoItemLargeProps & { index: number };

function VideoSliderItem({ video, ...props }: Readonly<VideoSliderItemProps>) {
  // copy props so we don't mutate the original reference afterwards
  // see also https://github.com/akiran/react-slick/issues/1012
  const slideProps = { ...props };
  delete slideProps.key;

  return (
    <div {...slideProps}>
      <VideoItem video={video} />
    </div>
  );
}

export default VideoSliderItem;
