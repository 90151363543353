import {
  Col,
  Container,
  Row,
  useDialogue,
  CarouselSSRWrapper,
} from '@geberit/gdds';
import { useEffect, useRef } from 'react';
import hash from 'weak-key';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';

// styles
import styles from './channel-carousel.module.scss';

// types
import type { ChannelCarouselProps, VideoTileProps } from './types';

// components
import VideoTile from './video-tile';
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { DialogueBody } from './dialogue-body';
import { CmsLink } from 'components/Link/CmsLink';
import { Translation } from 'components/Translation/Translation';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useTranslationFunction } from 'utils/hooks/use-translations';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useUniqueId } from 'utils/hooks/use-unique-id';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

function ChannelCarousel({ title, url, videos, metaData }: Readonly<ChannelCarouselProps>) {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const { showDialogue, closeDialogue } = useDialogue();
  const router = useRouter();
  const carouselRef = useRef<HTMLDivElement>(null);
  const translate = useTranslationFunction();
  const id = useUniqueId();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const videoParam = String(searchParams.get('video'));

  // adjust carousel button heights
  useEffect(() => {
    const image = carouselRef.current?.querySelector('.image') as HTMLImageElement;

    if (isDesktop && image) {
      const onResize = () => {
        const height = image.offsetHeight;
        const carousel = image.closest('.gdds-carousel');
        const btnLeft = carousel?.querySelector('.gdds-carousel__left-button') as HTMLButtonElement;
        if (btnLeft) {
          btnLeft.style.maxHeight = `${height}px`;
        }
        const btnRight = carousel?.querySelector(
          '.gdds-carousel__right-button',
        ) as HTMLButtonElement;
        if (btnRight) {
          btnRight.style.maxHeight = `${height}px`;
        }
      };
      const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
      const observer = new ResizeObserver(onResize);
      observer.observe(image);
      onResize();

      return () => {
        observer.disconnect();
      };
    }

    return () => {};
  }, [isDesktop, videos]);

  useEffect(() => {
    if (videoParam) {
      const video = videos.find((v) => v.video.videoId === videoParam);
      if (!video) return;
      showDialogue({
        type: 'custom',
        size: 'l',
        body: (
          <DialogueBody
            metaData={{ ...metaData, url: window.location.href, title: video.title }}
            isYoutubeVideo={video.video.type === 'youtube'}
            videoId={video.video.videoId}
            title={decodingContent(video.title)}
            text={decodingContent(video.description)}
            sharePageText={decodingContent(translate('web20_social_share_headline'))}
          />
        ),
        titleLabel: decodingContent(video.title),
        noFooterButton: true,
        fullscreen: true,
        onClose: () => {
          const { video: _, ...restQuery } = Object.fromEntries(searchParams);
          const updatedQuery = new URLSearchParams(restQuery);
          router.replace(`${pathname}?${updatedQuery.toString()}`, { scroll: false });
        },
      });
    } else {
      closeDialogue();
    }
  }, [videoParam]);

  const hideControls =
    (isMobile && videos.length === 1) ||
    (isTablet && videos.length < 3) ||
    (isDesktop && videos.length < 4);

  return (
    <>
      <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
        <Row className={styles.actions}>
          <Col size={[4, 6, 8]} className={styles.headlineWrapper}>
            <Headline
              isFlexItem
              title={title}
              tag={Formats.h2}
            />
          </Col>
          <Col size={[4, 2, 4]} className={styles.url}>
            {url && (
              <Link href={url} passHref shallow>
                <CmsLink
                  link={{
                    target: url,
                    showArrow: false,
                  }}
                  className="noArrow"
                  alignByContent="left"
                >
                  <Translation id="web20_videocenter_channel_link" />
                </CmsLink>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <Container
        maxContentWidth={buildSize(gridSizes.gddsCarouselFull)}
        className={classNameBuilder(styles.carousel, hideControls && styles.withoutControls)}
        ref={carouselRef}
      >
        <CarouselSSRWrapper
          id={id}
          isMobile={isMobile}
          isTablet={isTablet}
          autoSnap
          supportMouse
          hideButtonsTablet
          hideButtons={isMobile}
          customButtonRight={hideControls}
          customButtonLeft={hideControls}
          hideIndicator={isDesktop}
          slidesWidth={videos.map(() => {
            let width = 33;
            if (isTablet) width = 50;
            if (isMobile) width = 100;
            return width;
          })}
        >
          {videos.map((video: VideoTileProps) => (
            <VideoTile
              key={hash(video)}
              video={video}
              open={false}
              screenSize="small"
              onClick={() => {
                const writeQuery = new URLSearchParams(searchParams.toString());
                writeQuery.set('video', video.video.videoId);
                router.replace(`${pathname}?${writeQuery.toString()}`, { scroll: false });
              }}
            />
          ))}
        </CarouselSSRWrapper>
      </Container>
    </>
  );
}

export default ChannelCarousel;
