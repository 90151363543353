import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import striptags from 'striptags';

// styles
import styles from './courseList.module.scss';
import progressStyles from './progressBar.module.scss';

// components
import ProgressBar from './ProgressBar';
import MoreButton from './MoreButton';
import { BUTTON_COLORS, BUTTON_BACKGROUND, Button } from '../../Button/Button';
import { CmsLink } from '../../Link/CmsLink';
import { Translation } from '../../Translation/Translation';
import { Loader } from '../../Loader/Loader';
import { Icon } from '../../Icon/Icon';
import { CourseInformationItems } from '../CourseTeaser/CourseInformationItems';

// utils
import {
  useCampusTypes,
  useCourseCertificate,
  useCourseDetails,
  useCourseDownload,
  useCourseUserProgress,
  useClassifications,
  useCampusEnv,
} from 'utils/hooks/useCampus';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { generateSlug } from 'utils/slug';
import { decodingContent } from 'utils/decodingContent';
import { formatCampusDate } from 'utils/campus-date-format';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { getMetatag } from '../metatags';
import { useIsMobile } from 'components/App/SizeProvider';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { classNameBuilder } from 'utils/classNameBuilder';

interface CourseProps {
  componentId: string;
  id: string;
  courseName: string;
  firstname: string;
  lastname: string;
}

function Course({
  componentId: courseId,
  id: userId,
  courseName: title,
  firstname,
  lastname,
}: Readonly<CourseProps>) {
  const isMobile = useIsMobile();
  const { CAMPUS_META, CAMPUS_CLASS_TARGETGROUPS } = useCampusEnv();
  const referenceUrl = useSelector(campusReferenceUrlSelector);
  const language = useCurrentLanguage();
  const router = useRouter();
  const localeDateFormat = useDateFormat();

  const [downloadLink] = useCourseDownload(courseId, language);
  const { progress, isLoading } = useCourseUserProgress({ userId, courseId });
  const { data: details, isLoading: courseLoading } = useCourseDetails(courseId);

  const startDate = formatCampusDate(details?.startDateUTC, localeDateFormat);
  const endDate = formatCampusDate(details?.endDateUTC, localeDateFormat);
  const courseDates = startDate && endDate ? [startDate, endDate] : undefined;

  const getCertificate = useCourseCertificate(
    courseId,
    userId,
    details?.certificateAvailable && progress === 100,
  );

  const { data } = useCourseDetails(courseId);
  const [_, icon] = useCampusTypes(data?.topics);

  const detailsLink = {
    target: `${referenceUrl}${generateSlug(title)}/crs_${courseId}_pro_${userId}-${language}/`,
    type: 'internal_link',
    showArrow: true,
  };

  const metaTags = details?.metaTags;
  // extract targetGroups for booked course
  const { getClassification } = useClassifications();
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const courseTargetGroups = useMemo(() => {
    if (data && targetGroups) {
      const idInCourseTargetGroups = ({ id }) =>
        !!data?.topics?.find((i) => parseInt(i?.id, 10) === parseInt(id, 10));
      return targetGroups?.filter(idInCourseTargetGroups) ?? [];
    }
  }, [data, targetGroups]);

  const actions = (
    <aside className={styles.actions}>
      <Button
        background={BUTTON_BACKGROUND.WHITE}
        color={BUTTON_COLORS.BLACK}
        onClick={() => router.push(detailsLink.target)}
      >
        <Translation id="campus_course" />
      </Button>
      {(downloadLink || details?.certificateAvailable) && (
        <MoreButton text="···">
          {downloadLink && (
            <CmsLink link={{ target: downloadLink, showArrow: true, window: '_blank' }} download>
              <Translation id="campus_course_material" />
            </CmsLink>
          )}
          {details?.certificateAvailable && (
            <a
              href="#"
              className={progress !== 100 ? styles.certificateDisabled : ''}
              download="certificate.pdf"
              onClick={(e) => {
                e.preventDefault();
                getCertificate();
              }}
            >
              <Translation id="campus_course_certificate" />
            </a>
          )}
        </MoreButton>
      )}
    </aside>
  );

  return (
    <article className={styles.course}>
      <div className={classNameBuilder(styles.courseIcons, styles[icon])}>
        <span>
          <Icon symbol={icon} />
        </span>
      </div>
      {!isMobile && actions}
      <div className={styles.dataWrapper}>
        {isLoading ? (
          <div className={progressStyles.progressBar}>
            <Loader />
          </div>
        ) : (
          <ProgressBar progress={progress} isMobile={isMobile} />
        )}
        <div className={styles.courseData}>
          <div>
            <div className={styles.provider}>
              {firstname} {lastname}
            </div>
            <div className={styles.type}>{!courseLoading && details?.learningForm}</div>
            <h4 className={styles.courseTitle}>
              {decodingContent(getMetatag(CAMPUS_META.NAME, metaTags))}
            </h4>
            <div className={styles.text}>{striptags(details?.description)}</div>
          </div>
        </div>
      </div>
      <div className={styles.courseInformation}>
        <CourseInformationItems
          targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
          courseDate={courseDates}
          duration={details?.runningTime}
        />
      </div>
      {isMobile && actions}
    </article>
  );
}

export default Course;
