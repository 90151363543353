// styles
import styles from './videocenter-overview.module.scss';

// types
import type { VideoCenterProps } from './videocenter-overview.types';

// components
import Footer from 'components/Footer/Footer';
import VideoGallery from 'components/VideoGallery/VideoGallery';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import IntroText from 'components/ContentElements/IntroText/IntroText';
import HeroImageC from 'components/ContentElementsGdds/hero-image/hero-image-c';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { TitleFormats } from 'components/ContentElements/Title/title.types';

export default function VideocenterOverviewScene({
  breadcrumbs,
  metaData,
  page: { hero, headline, subHeadline, text, showAnchor },
  channels,
}: Readonly<VideoCenterProps>) {
  return (
    <>
      <main className={classNameBuilder(styles.videocenter, 'videocenter')}>
        {hero?.items.some((item) => item.type === 'hero_c_item') ? (
          <HeroImageC {...(hero?.items[0] as SliderItemProps)} />
        ) : (
          <HeroImageGdds {...hero} />
        )}
        <Breadcrumb items={breadcrumbs} className={styles.breadcrumb} />
        {(headline || subHeadline) && (
          <PageHeadline
            Format={TitleFormats.h1}
            title={headline}
            subtitle={subHeadline}
            pageHeadline
            hasIntroText
          />
        )}
        <IntroText text={text ?? ''} grid />
        <VideoGallery
          showAnchor={showAnchor}
          pageHeadline={headline ?? ''}
          metaData={metaData}
          channels={channels}
        />
      </main>
      <Footer />
    </>
  );
}
