'use client';

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { usePathname, useRouter } from 'next/navigation';
import { Col, Container, Row } from '@geberit/gdds';

// styles
import styles from './product-detail-container.module.scss';

// components
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import { AnchorBar } from 'components/ContentElementsGdds/anchorbar/anchorbar';
import ProductDetailGallery from '../DetailGallery/ProductDetailGallery';
import ProductDetailFilterBox from '../DetailFilterBox/ProductDetailFilterBox';
import { panelUuids, ProductDetailAccordion } from '../DetailAccordion';

// utils
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { convertItems, TOC_DATA_PDP } from 'components/TableOfContents/tocDataAdapter';
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { seoConformProductUrl } from 'utils/seoConformProductUrl';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { isClient } from 'utils/environment';
import { useTracking } from 'utils/hooks/useTracking';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useXy } from 'utils/hooks/use-xy';
import { trackingOnChange } from 'utils/trackAccordion';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';
import { buildSize, gridSizes } from 'utils/gridSize';

type ProductDetailContainerProps = {
  type: string;
  product: Product;
  isStorybook?: boolean;
  catalogLanguage?: string;
};

export default function ProductDetailContainer({
  type,
  product,
  product: { name, spareParts: sparePartsProps, documents: documentsProps, categories },
  catalogLanguage,
  ...restProps
}: Readonly<ProductDetailContainerProps>) {
  const router = useRouter();
  const pathname = usePathname();
  const track = useTracking();
  const isPreview = useIsPreview();
  const isXy = useXy();

  const [activePanel, setActivePanel] = useState<typeof panelUuids[keyof typeof panelUuids][]>([]);
  const [accItems, setAccItems] = useState({
    detail: false,
    spareParts: false,
    documents: false,
    scopeOfDelivery: false,
    technicalDrawings: false,
    characteristics: false,
    technicalDataList: false,
    applicationPurposes: false,
    additionalInformation: false,
    notIncluded: false,
  });
  const prevLocation = useRef(pathname);
  const [productPath, setProductPath] = useState<string>('');

  const language = useCurrentLanguage();
  const navigation = useSelector(navigationMainItemsSelector);
  const homepage = useSelector(homepageSelector);
  const translations = useSelector(translationKeysSelector);
  const anchorbarItems = isXy
    ? convertItems(isPreview, translations, TOC_DATA_PDP, { product }, null)
        .map(({ name }) => ({ titleInAnchor: true, title: name }))
    : [];


  const resetOpenPanels = () => {
    setAccItems({
      detail: false,
      spareParts: false,
      documents: false,
      scopeOfDelivery: false,
      technicalDrawings: false,
      characteristics: false,
      technicalDataList: false,
      applicationPurposes: false,
      additionalInformation: false,
      notIncluded: false,
    });
    setActivePanel([]);
  };

  useEffect(() => {
    if (isClient()) {
      window.addEventListener('scrollToSection', onScrollToPanel);
    }

    return () => {
      resetOpenPanels();
      if (isClient()) {
        window.removeEventListener('scrollToSection', onScrollToPanel);
      }
    };
  }, []);

  useEffect(() => {
    if (!isEqual(prevLocation.current, pathname)) {
      resetOpenPanels();
    }
  }, [pathname]);

  useEffect(() => {
    if (!isEmpty(navigation) && productPath === '') {
      setProductPath(seoConformProductUrl(product, navigation?.[0]?.url, language, categories));
    }
  }, [navigation]);

  // navigation is needed to render the component
  if (isEmpty(navigation)) return null;

  /**
   * Accordion onChange method
   *
   * @param e section title of scrolled to element
   */
  const onScrollToPanel = (e) => {
    expandAccordionItem(e.detail.id, true);
  };

  /**
   * Accordion onChange method
   *
   * @param e UUID array
   */
  const onChange = (e: typeof panelUuids[keyof typeof panelUuids][]) => {
    const activeDetail = e.some((panel) => panel === panelUuids.detail);
    const activeSpareParts = e.some((panel) => panel === panelUuids.spareParts);
    const activeDocuments = e.some((panel) => panel === panelUuids.documents);
    const activeTechnicalDrawings = e.some((panel) => panel === panelUuids.technicalDrawings);
    const activeScopeOfDelivery = e.some((panel) => panel === panelUuids.scopeOfDelivery);
    const activeNotIncluded = e.some((panel) => panel === panelUuids.notIncluded);
    const activeApplicationPurposes = e.some((panel) => panel === panelUuids.applicationPurposes);
    const activeTechnicalDataList = e.some((panel) => panel === panelUuids.technicalDataList);
    const activeCharacteristics = e.some((panel) => panel === panelUuids.characteristics);
    const activeAdditionalInformation = e.some((panel) => panel === panelUuids.additionalInformation);

    trackingOnChange(track, setActivePanel, activePanel, null, e, translations);

    setActivePanel(e);
    setAccItems({
      detail: activeDetail,
      spareParts: activeSpareParts,
      documents: activeDocuments,
      scopeOfDelivery: activeScopeOfDelivery,
      technicalDrawings: activeTechnicalDrawings,
      characteristics: activeCharacteristics,
      technicalDataList: activeTechnicalDataList,
      applicationPurposes: activeApplicationPurposes,
      additionalInformation: activeAdditionalInformation,
      notIncluded: activeNotIncluded,
    });
  };

  /**
   * Set active panel
   *
   * @param uuid
   * @returns {boolean}
   */
  const isPanelActive = (uuid: typeof panelUuids[keyof typeof panelUuids]) =>
    !isEmpty(activePanel) && activePanel.some((panel) => panel === uuid);

  /**
   * Change expanded state of regarding accordion item
   *
   * @param item AccordionItem expanded name
   * @param expanded
   */
  // not doing what it says
  const expandAccordionItem = (item: typeof panelUuids[keyof typeof panelUuids], expanded: boolean) => {
    setAccItems({
      ...accItems,
      [item]: expanded || !accItems[item],
    });
  };
  const category = categories.find((item) => !isEmpty(item.name));

  // get seoconform url for metadata
  if (productPath && productPath !== pathname && productPath !== prevLocation.current) {
    router.replace(productPath);
    return null;
  }

  const categoryItem = category && {
    label: category.name,
    url: '',
  };

  const {
    detail,
    spareParts,
    documents,
    scopeOfDelivery,
    technicalDrawings,
    characteristics,
    technicalDataList,
    applicationPurposes,
    additionalInformation,
    notIncluded,
  } = accItems;

  return (
    <>
      {!isEmpty(homepage) && !isEmpty(navigation) && (
        <Breadcrumb
          items={[
            { label: 'homepage', url: homepage.url },
            { ...categoryItem },
            { label: name, url: '' },
          ]}
        />
      )}
      <div className="l-product-page">
        <PageHeadline Format={TitleFormats.h1} title={name} pageHeadline />

        {isXy && <AnchorBar className={styles.anchorBar} collapseAnchor pageAnchorTitle={name} content={anchorbarItems} />}

        <Container maxContentWidth={buildSize(gridSizes[isXy ? 'gddsFullGrid' : 'full'])}>
          {!isXy && (
            <Row>
              <Col>
                <TableOfContents type={TOC_DATA_PDP} content={{ product }} headline={name} />
              </Col>
            </Row>
          )}
          <Row>
            <Col size={[4, 4, 6]}>
              <ProductDetailGallery product={product} {...restProps} />
            </Col>
            <Col size={[4, 4, 6]}>
              <ProductDetailFilterBox
                product={product}
                sparePartsGiven={!isEmpty(sparePartsProps.list)}
                documentsGiven={!isEmpty(documentsProps.list)}
                expandAccordionItem={expandAccordionItem}
                language={language}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProductDetailAccordion
                product={product}
                detailOpen={detail}
                sparePartsOpen={spareParts}
                documentsOpen={documents}
                technicalDrawingsOpen={technicalDrawings}
                scopeOfDeliveryOpen={scopeOfDelivery}
                onChange={onChange}
                isPanelActive={isPanelActive}
                notIncludedOpen={notIncluded}
                applicationPurposesOpen={applicationPurposes}
                characteristicsOpen={characteristics}
                technicalDataListOpen={technicalDataList}
                additionalInformationOpen={additionalInformation}
                catalogLanguage={catalogLanguage}
                {...restProps}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
