// types
import type { VideoLightboxViewProps } from './lightbox.types';

// components
import { Translation } from 'components/Translation/Translation';
import YoutubeVideo from 'components/Video/youtube/youtube-video';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Lightbox } from './lightbox';
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import SocialShare from 'components/SocialShare/SocialShare';
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import { CopyToClipboard } from 'components/copy-to-clipboard/copy-to-clipboard';

// utils
import { isEmpty } from 'utils/is-empty';
import { decodingContent } from 'utils/decodingContent';

export function VideoLightboxView({
  videoId,
  handleClose,
  isModal,
  title = '',
  isYoutubeVideo,
  text = '',
  channelUrl = '',
  hasVideoError,
  videoReady,
  videoUrl,
  isHeroImageForegroundVideoPlayer,
}: Readonly<VideoLightboxViewProps>) {
  const socialShare = {
    title,
    og_url: channelUrl,
  };

  return (
    <Lightbox onCloseRequest={handleClose}>
      <div className="c-lightbox__container">
        <div className="c-lightbox__video-title">
          <h4>{decodingContent(title)}</h4>
          <Button
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            onClick={handleClose}
            className="c-lightbox__close"
            noTracking
          >
            <Translation id="web20_lightbox_close" />
          </Button>
        </div>
        <div className="c-lightbox__video-container">
          {isYoutubeVideo ? (
            <YoutubeVideo id={videoId} />
          ) : (
            videoReady && (
              <MiVideoContainer
                hasError={hasVideoError}
                isModal={isModal}
                className="c-text-video"
                content={{ video: { videoId, title } }}
                isHeroImageForegroundVideoPlayer={isHeroImageForegroundVideoPlayer}
              />
            )
          )}
        </div>
        {text && (
          <div className="c-lightbox__video-description">
            <InnerHtml as="span" content={text} />
          </div>
        )}

        {!isEmpty(channelUrl) && (
          <SocialShare
            metaData={socialShare}
            contentType="video"
          />
        )}
        {videoUrl && <CopyToClipboard text={videoUrl} />}
      </div>
    </Lightbox>
  );
}

export default VideoLightboxView;
