import { useEffect, useRef, useState } from 'react';

// types
import { ILocation } from '../../locator.types';

// utils
import { Log } from 'services/log';

export function useDuration(
  start: ILocation | undefined,
  dest: ILocation,
  shouldCalculateDuration: boolean,
  memoise = true,
) {
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const durationRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    let timeout: Timeout | undefined;

    if (
      window.google &&
      shouldCalculateDuration &&
      (durationRef.current !== start?.address || !memoise)
    ) {
      const service = new google.maps.DistanceMatrixService();

      timeout = setTimeout(() => {
        if (start?.address && start?.coordinates && dest.coordinates && dest.address) {
          service
            .getDistanceMatrix({
              origins: [start?.coordinates, dest.coordinates],
              destinations: [start?.address, dest.address],
              travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((result) => {
              setDuration(result?.rows?.[0]?.elements?.[1]?.duration?.value);
              durationRef.current = start?.address;
            })
            .catch((e) => {
              Log.error('Failed to get distance matrix', e);
            });
        }
      }, 200);

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }

    return () => {};
  }, [start?.address, dest.address, shouldCalculateDuration]);

  return duration;
}
