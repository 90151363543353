export function createCachedFunction(revalidate: number) {
  const start = new Map();
  const cache = new Map();

  return async (fn: Function, key: string) => {
    const shouldRefresh = Date.now() - (start.get(key) ?? 0) > revalidate;

    if (!cache.has(key) || shouldRefresh) {
      start.set(key, Date.now());
      const result = await fn();
      cache.set(key, result);
    }

    return cache.get(key);
  };
}
