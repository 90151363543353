import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/navigation';
import key from 'weak-key';
import {
  Container,
  Col,
  Row,
  ExpressiveTile,
  Tiles,
  Tlea1Props,
  CarouselSSRWrapper,
} from '@geberit/gdds';
import ReactHtmlParser from 'react-html-parser';

// styles
import styles from './tiles.module.scss';

// types
import type { HeroTilesProps } from './hero-tiles.types';
import type { JSX } from 'react';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { buildSize, gridSizes } from 'utils/gridSize';
import { classNameBuilder } from 'utils/classNameBuilder';
import { handlePopup } from 'utils/openInPopup';
import { useGdds } from 'utils/hooks/use-gdds';
import { sliderClick } from 'components/ContentElements/slider/tracking-actions';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useG4aTracking } from 'utils/tracking/track';
import { useNord } from 'utils/hooks/use-nord';

export function HeroTiles({
  items = [],
  slidingInterval = 6,
  showArrows = true,
}: Readonly<HeroTilesProps>) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const isGdds = useGdds();
  const track = useTracking();
  const { trackClick } = useG4aTracking();
  const isNord = useNord();

  const renderColOrSlider = (children: JSX.Element[]) => {
    if (children.length > 1) {
      return (
        <Col size={[4, 8, 12]} className={`${styles.heroTile__carousel}`}>
          <CarouselSSRWrapper
            autoSnap
            responsiveLayout={{
              small: { slides: 1, slidesToMove: 1 },
              medium: { slides: 1, slidesToMove: 1 },
              large: { slides: 1, slidesToMove: 1 },
            }}
            supportMouse
            hideButtons
            hideButtonsTablet
            slideMargin={showArrows ? 16 : 0}
            autoSliding
            loop
            slidingInterval={slidingInterval}
            {...(!showArrows && { customButtonRight: true, customButtonLeft: true })}
          >
            {children}
          </CarouselSSRWrapper>
        </Col>
      );
    }

    return <Col size={[4, 8, 12]}>{children}</Col>;
  };

  const trackCTAClick = (click_url = '', text = '') => {
    track.trackEvent(sliderClick(text ?? ''));
    trackClick({
      click_intent: 'cta',
      click_element: 'hero slider button - header',
      click_text: text ?? '',
      click_url,
    });
  };

  const handleLinkOnClick = (item: HeroTileItemType, link?: Link) => () => {
    if (!isNord) trackCTAClick(link?.target, link?.text);
    if (link?.window === '_blank') {
      window.open(link?.target);
    } else if (link?.window === '_popup') {
      const windowId = key(item);
      handlePopup(link, windowId);
    } else if (link?.type === 'external_link') {
      window.location.href = link?.target;
    } else if (link?.type === 'mail_link') {
      window.open(link?.target, '_self');
    } else if (link?.target) router.push(link?.target);
  };

  const buildTile = (item: HeroTileItemType): JSX.Element => {
    const { imageAspectRatios, image } = getImage(item);
    const backgroundType = item.textbox?.color === 'black' ? 'black' : 'light';
    const link = item.textbox?.link;

    const aspectRatios = { ...imageAspectRatios, medium: '16:9', large: '16:9' };

    return (
      <div key={key(item)} className={classNameBuilder(styles.herotile, styles[backgroundType])}>
        <Tiles gap="0" type="expressive">
          <ExpressiveTile
            type="tlea1"
            imageAspectRatios={aspectRatios}
            backgroundType={backgroundType}
            image={image}
            imageTitle={item.pictureAlt}
            altText={item.pictureAlt}
            title={ReactHtmlParser(item.textbox?.headline)}
            subTitle={ReactHtmlParser(item.textbox?.subline)}
            headlineVariant="h2"
            button={
              link && {
                text: link?.text ?? '',
                onClick: handleLinkOnClick(item, link),
              }
            }
          />
        </Tiles>
      </div>
    );
  };

  function getImage(item: HeroTileItemType) {
    const image = (isMobile && item.imageObjectMobile?.url) || item.imageObject?.url || item.image;
    const { smallAspectRatio, mediumAspectRatio } = getAspectRatio(item);

    const imageAspectRatios = {
      small: smallAspectRatio,
      medium: mediumAspectRatio,
      large: mediumAspectRatio,
      xlarge: mediumAspectRatio,
    } as Tlea1Props['imageAspectRatios'];

    return { imageAspectRatios, image };
  }

  function getAspectRatio(item: HeroTileItemType) {
    const ratioRegEx = new RegExp(/^\d+_\d+$/);

    const smallAspectRatio = ratioRegEx.test(item.imageObjectMobile?.aspectRatio)
      ? item.imageObjectMobile?.aspectRatio?.replace('_', ':')
      : undefined;
    const mediumAspectRatio = ratioRegEx.test(item.imageObjectMobile?.aspectRatio)
      ? item.imageObject?.aspectRatio?.replace('_', ':')
      : undefined;

    return { smallAspectRatio, mediumAspectRatio };
  }

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Container maxContentWidth={buildSize(isGdds ? gridSizes.gddsFullGrid : gridSizes.countryGrid)}>
      <Row>{renderColOrSlider(items?.map(buildTile))}</Row>
    </Container>
  );
}

export function mapToHeroTileItems(heroTile: {
  items: SliderItemProps[];
  slideDuration?: number;
}): HeroTileItemType[] {
  return (
    heroTile?.items.map((item) => ({
      image: item.image,
      imageObject: item.imageObject,
      imageObjectMobile: item.imageObjectMobile,
      previewId: item.previewId,
      pictureAlt: item.pictureAlt,
      type: 'hero_tile_item_nordics',
      textbox: {
        headline: item.headline,
        subline: item.subheadline,
        link: item.link,
        color: item.textColor,
      },
    })) ?? []
  );
}
