import { Form } from 'formik';
import { Toggle } from '@geberit/gdds';

// styles
import styles from './cancellation.module.scss';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { ConfirmationButton } from './ConfirmationButton';
import { Loader } from 'components/Loader/Loader';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useCancelCourse, useCourseParticipants } from 'utils/hooks/useCampus';
import { Log } from 'services/log';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface ParticipantsFormProps {
  setFieldValue: (field: string, value: any) => void;
  values: { names: string[] };
  errors: { names: string };
  isMobile: boolean;
  reloadDetails: () => void;
  courseId: number;
}

function ParticipantsForm({
  isMobile,
  setFieldValue,
  values,
  errors,
  courseId,
  reloadDetails,
}: Readonly<ParticipantsFormProps>) {
  const { cancelMulti } = useCancelCourse();
  const { participants, isLoading } = useCourseParticipants(courseId);
  const translate = useTranslationFunction();

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  const handleParticipants = (value) => {
    if (values.names.includes(value)) {
      const newVals = values.names.filter((val) => val !== value);
      setFieldValue('names', newVals);
    } else {
      setFieldValue('names', [...values.names, value]);
    }
  };

  const handleSubmit = (isMulti) => async (e) => {
    e.preventDefault();

    try {
      if (isMulti) {
        await cancelMulti({ courseId, personIds: participants.map((p) => p.id) });
      } else if (values?.names?.length > 0) {
        await cancelMulti({ courseId, personIds: values.names });
      }
    } catch (err) {
      Log.error('Campus cancellation error', err);
    }

    // cancellation succeeded
    sessionStorage.setItem(
      'cancelledParticipants',
      JSON.stringify(
        isMulti
          ? participants
          : participants.filter((item) => values?.names?.some((el) => el === item.id)),
      ),
    );
    sessionStorage.setItem('allParticipants', JSON.stringify(participants));
    reloadDetails();
    window.location.hash = 'cancel-confirm';
  };

  return (
    <Form className={styles.cancellationForm}>
      <fieldset className={styles.participantsList}>
        <legend>
          <Headline
            title={translate('campus_headline_participants')}
            className={styles.headline}
            tag={Formats.h3}
            isFlexItem
          />
        </legend>
        <div className={styles.participantsListItem}>
          <Toggle
            name="names"
            values={values}
            errors={errors}
            onChange={(value) => handleParticipants(value)}
            type="checkbox"
            alignment={isMobile ? 'vertical' : 'horizontal'}
            items={participants
              .sort((a, b) => a.firstname.localeCompare(b.firstname))
              .map((item, i) => ({
                id: `${i}-${item.id}`,
                value: item.id,
                text: `${item.firstname} ${item.lastname}`.trim(),
              }))}
          />
        </div>
      </fieldset>

      <ConfirmationButton values={values} handleSubmit={handleSubmit} participants={participants} />
    </Form>
  );
}

export default ParticipantsForm;
