import React, { useEffect, useRef, useState, type JSX } from 'react';

export default function Image({
  fallbackSrc,
  fallbackBackgroundColor,
  ...imgProps
}: JSX.IntrinsicElements['img'] & { fallbackSrc?: string; fallbackBackgroundColor?: string }) {
  const [isFallbackSrc, setIsFallbackSrc] = useState(!imgProps.src);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!imgRef.current) {
      return () => {};
    }

    const errorHandler = () => {
      if (fallbackSrc) {
        setIsFallbackSrc(true);
        imgRef.current?.removeEventListener('error', errorHandler);
        imgRef.current?.setAttribute('src', fallbackSrc);
      }
    };

    imgRef.current.addEventListener('error', errorHandler);
    return () => {
      imgRef.current?.removeEventListener('error', errorHandler);
    };
  }, [fallbackSrc]);

  useEffect(() => {
    if (imgRef.current) {
      if (isFallbackSrc) {
        imgRef.current.style.padding = '1.5rem';
        imgRef.current.style.objectFit = 'contain';

        if (fallbackBackgroundColor) {
          imgRef.current.style.backgroundColor = fallbackBackgroundColor;
        }
      }
    }
  }, [isFallbackSrc]);

  return <img {...imgProps} src={imgProps.src || fallbackSrc} ref={imgRef} />;
}
