import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { shoppingBasketItemsSelector } from 'utils/selectors/shoppingBasketSelector';
import { queue } from '../job-queue';
import { QUEUE_TRACK_VIEW_CART, useTracking } from 'utils/tracking/track';
import { roundValueTwoDigits } from '../round-value';

export function useTrackViewCart() {
  const items = useSelector(shoppingBasketItemsSelector);
  const { trackWebshop } = useTracking();
  const translate = useTranslationFunction();

  useEffect(() => {
    if (queue.has(QUEUE_TRACK_VIEW_CART)) {
      if (items?.every((item) => Boolean(item.sku))) {
        const cartItems = items || [];
        const value = cartItems.reduce(
          (acc, cur) => acc + cur.quantity * parseFloat(cur.pricePerItem),
          0,
        );
        trackWebshop('view_cart', {
          value: roundValueTwoDigits(value),
          items: cartItems.map((item, index) => ({
            item_id: item.sku,
            item_name: item.description,
            quantity: item.quantity,
            price: parseFloat(item.pricePerItem),
            index,
          })),
        });
        queue.process(QUEUE_TRACK_VIEW_CART);
      }
    }
  }, [items, trackWebshop, translate]);
}
