// styles
import styles from './courseTeaser.module.scss';

// types
import type { CourseDate, CourseDateList, CourseMetaItem } from '../types';

// components
import CourseTeaserItem from './CourseTeaserItem';
import Tooltip from 'components/Tooltip/Tooltip';
import { Translation } from 'components/Translation/Translation';

// utils
import { isEmpty } from 'utils/is-empty';

interface CourseInformationItemsProps {
  targetGroup: string;
  courseDate?: CourseDateList;
  availableSeats?: string | number;
  seats?: string | number;
  duration?: string;
  costs?: string | number;
  costsIncluded?: CourseMetaItem[];
  language?: string;
  isEmployee?: boolean;
  isCiam?: boolean;
}

export function CourseInformationItems({
  targetGroup,
  courseDate,
  availableSeats,
  seats,
  duration,
  costs,
  costsIncluded,
  language,
  isEmployee = false,
  isCiam = false,
}: Readonly<CourseInformationItemsProps>) {
  const capacityClass =
    Number(availableSeats) / Number(seats) <= 0.2 ? styles.capacityOrange : styles.capacityGreen;
  const availabilityClass = availableSeats === 0 ? undefined : capacityClass;

  return (
    <div className={styles.courseInformationItems}>
      {targetGroup && (
        <CourseTeaserItem icon="target-group" title="campus_targetgroup" text={targetGroup} />
      )}
      {!isEmpty(courseDate) && (
        <CourseTeaserItem
          icon="course-date"
          title="campus_date"
          text={
            <span className={styles.itemContent}>
              {courseDate?.filter(Boolean).map((item: CourseDate) => (
                <span key={`${item.day}-${item.time}`}>
                  {item.day}
                  <br />
                  {item.time}
                </span>
              ))}
            </span>
          }
        />
      )}
      {isEmpty(courseDate) && duration && (
        <CourseTeaserItem icon="duration" title="campus_duration" text={duration} />
      )}
      {language && <CourseTeaserItem icon="language" title="campus_language" text={language} />}
      {(availableSeats || seats) && isCiam && (
        <CourseTeaserItem
          icon="available-seats"
          title="campus_availableseats"
          text={
            <>
              {availableSeats && <span className={availabilityClass}>{availableSeats}</span>}/
              {seats && <span>{seats}</span>}
            </>
          }
        />
      )}
      {/* due to in the current live projects there are no onsite courses (also not planned atm) you can ignore the next block */}
      {costs && !isEmployee && (
        <CourseTeaserItem
          icon="campus-cost"
          title="campus_cost_per_person"
          text={
            typeof costsIncluded !== 'undefined' && costsIncluded.length > 0 ? (
              <Tooltip
                trigger="click"
                position="bottom"
                portalClass="--white"
                content={
                  <div className="tooltip-text">
                    <b>
                      <Translation id="campus_costs_include" />
                    </b>
                    <ul>
                      {costsIncluded.map((item) => (
                        <li key={item.id}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                }
              >
                <span className="cost-per-person">{costs}</span>
              </Tooltip>
            ) : (
              <span className="cost-per-person">{costs}</span>
            )
          }
        />
      )}
    </div>
  );
}
