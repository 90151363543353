import { Divider } from '@geberit/gdds';

// styles
import pressStyles from './press-portal.module.scss';

// types
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';
import type { TilesHeadline } from 'components/ContentElementsGdds/PressPortal/types/press-portal.types';

// components
import { Headline } from '../headline/headline';

// utils
import { useXy } from 'utils/hooks/use-xy';

export function TilesHeadline({ withDivider = true, translation }: TilesHeadline) {
  const isXy = useXy();

  return (
    <>
      {withDivider && <Divider />}
      <Headline
        title={translation}
        tag={Formats.h2}
        className={pressStyles.tilesHeadline}
        {...(!isXy && { variant: Formats.h3 })}
      />
    </>
  );
}
