import { useState } from 'react';
import striptags from 'striptags';

// types
import type { CartOrdersProps } from '../../types';

// Components
import { BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { FormController } from 'components/ContentElements/Form/form-controller';

export default function CartOrders(props: Readonly<CartOrdersProps>) {
  const {
    orders,
    updateAmount,
    updateOrder,
    form,
    getSessionStorage,
    onFinished,
  } = props;
  const [isSuccess, setIsSuccess] = useState(false);

  const onInputChange = (e, order) => {
    const {
      currentTarget: { value },
    } = e;
    let amount = value;

    if (value < 0) {
      amount = 0;
    } else if (value > 100) {
      amount = 100;
    }

    updateAmount({
      ...order,
      amount: parseInt(amount, 10) || '',
    });
  };

  const onInputBlur = (order) => {
    if (!order.amount) {
      updateAmount({
        ...order,
        amount: 1,
      });
    }
  };

  const renderTable = () => {
    const tableData = orders.map((order) => (
      <tr key={order.id}>
        <td>
          <InnerHtml as="span" content={order.title} />
        </td>
        <td>
          <input
            type="number"
            value={order.amount}
            onChange={(e) => onInputChange(e, order)}
            onBlur={() => onInputBlur(order)}
          />
        </td>
        <td>
          <Button
            type={BUTTON_TYPES.SEAMLESS}
            symbol="close"
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => {
              updateOrder(order);
            }}
          >
            <Translation id="web20_downloadcenter_delete" />
          </Button>
        </td>
      </tr>
    ));

    return (
      <>
        <div className="cell medium-12 c-cart-orders__headline">
          <h3 className="h4">
            <Translation id="web20_downloadcenter_your_order" />
          </h3>
        </div>
        <table className="c-cart-orders__table">
          <tbody>{tableData}</tbody>
          <thead>
            <tr>
              <th>
                <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
              </th>
              <th>
                <Translation id="web20_downloadcenter_cartmodal_cell_amount" />
              </th>
              <th />
            </tr>
          </thead>
        </table>
      </>
    );
  };

  const orderInformation = orders
    .map((order) => `${order.amount}x ${striptags(order.title)}`)
    .join('\r\n');

  const onSuccess = () => {
    setIsSuccess(true);
    if (typeof onFinished === 'function') onFinished();
  };

  return (
    <div className="c-downloadorder">
      {!isSuccess && renderTable()}
      {!isSuccess && (
        <div className="cell medium-12 c-cart-orders__headline">
          <h3 className="h4">
            <Translation id="web20_downloadcenter_your_data" />
          </h3>
        </div>
      )}
      <FormController
        {...form}
        formType="downloadCenter"
        orderInformation={orderInformation}
        getSessionStorage={getSessionStorage}
        afterSubmit={onSuccess}
        isModal
      />
    </div>
  );
}
