import { Button } from '@geberit/gdds';

// styles
import styles from './coursedownloads.module.scss';
import coursebookedstyles from '../CourseBookedContent/coursebookedcontent.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import {
  useCourseCertificate,
  useCourseDownload,
  useCourseUserProgress,
} from 'utils/hooks/useCampus';
import { useTranslationFunction } from 'utils/hooks/use-translations';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

interface CourseDownloadsProps {
  courseId: number;
  participantId: number;
  language: string;
  details: any;
}

export function CourseDownloads({
  courseId,
  participantId,
  language,
  details,
}: Readonly<CourseDownloadsProps>) {
  const [downloadLink, isLoading] = useCourseDownload(courseId, language);
  const { progress } = useCourseUserProgress({ userId: participantId, courseId });

  const getCertificate = useCourseCertificate(
    courseId,
    participantId,
    details?.certificateAvailable && progress === 100,
  );
  const translate = useTranslationFunction();

  if (isLoading) {
    return (
      <div className={coursebookedstyles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  if (!downloadLink && !details?.certificateAvailable) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Headline
        title={translate('campus_headline_downloads')}
        tag={Formats.h3}
        className={styles.headline}
        isFlexItem
      />
      <Translation id="campus_text_downloads" />
      <div className={styles.buttons}>
        {downloadLink && (
          <Button
            download
            stylingType="flatHighlight"
            buttonType="button"
            symbol="Download"
            iconAlignment="left"
            onClick={() => {
              window.open(downloadLink, '_blank');
            }}
          >
            {translate('campus_course_material')}
          </Button>
        )}
        {details?.certificateAvailable && (
          <Button
            download
            stylingType="flatHighlight"
            buttonType="button"
            symbol="Download"
            disabled={progress !== 100}
            iconAlignment="left"
            onClick={(e) => {
              e?.preventDefault();
              getCertificate();
            }}
          >
            {translate('campus_course_certificate')}
          </Button>
        )}
      </div>
    </div>
  );
}
