import dynamic from 'next/dynamic';

const CategoryTiles = dynamic(() =>
  import('./CategoryTiles/CategoryTiles').then((module) => module.CategoryTiles),
);

const ProductTiles = dynamic(() =>
  import('./ProductTiles/ProductTiles').then((module) => module.ProductTiles),
);

const Accordion = dynamic(() =>
  import('./Accordion/Accordion').then((module) => module.AccordionContainer),
);

const FAQAccordion = dynamic(() =>
  import('./Accordion/faq-accordion').then((module) => module.AccordionContainer),
);

const Awards = dynamic(() => import('./awards/awards').then((module) => module.Awards));

const CTAButton = dynamic(() => import('./CTA/CTAButton').then((module) => module.CTAButton));

const Picto = dynamic(() => import('./picto/picto').then((module) => module.Picto));

const Form = dynamic(() =>
  import('./Form/form-controller').then((module) => module.FormController),
);

const ImageGallery = dynamic(() =>
  import('./image-gallery/image-gallery').then((module) => module.ImageGallery),
);

const InteractiveDescription = dynamic(() =>
  import('./interactive-description/interactive-description').then(
    (module) => module.InteractiveDescription,
  ),
);

const Locator = dynamic(() => import('./Locator/Locator').then((module) => module.Locator));

const ShowroomLocatorTeaser = dynamic(() =>
  import('./showroom-locator-teaser/showroom-locator-teaser').then(
    (module) => module.ShowroomLocatorTeaser,
  ),
);

const ProductComparison = dynamic(() =>
  import('./ProductComparison/ProductComparison').then((module) => module.ProductComparison),
);

const LandingpageIntro = dynamic(() =>
  import('./LandingpageIntro/LandingpageIntro').then((module) => module.LandingpageIntro),
);

const LandingpageText = dynamic(() =>
  import('./LandingpageText/LandingpageText').then((module) => module.LandingpageText),
);

const Table = dynamic(() => import('./table/table').then((module) => module.Table));

const Tabs = dynamic(() => import('./tabs/tabs').then((module) => module.TabsContainer));

const TextImage = dynamic(() =>
  import('./text-image/text-image').then((module) => module.TextImage),
);

const CrmForms = dynamic(() =>
  import('../ContentElementsGdds/crm-forms/crm-forms').then((module) => module.CrmForms),
);

const TextVideo = dynamic(() =>
  import('./text-video/text-video').then((module) => module.TextVideo),
);

const Tiles = dynamic(() => import('./Tiles/Tiles').then((module) => module.Tiles));

const ToolIntegration = dynamic(() =>
  import('./ToolIntegration/ToolIntegration').then((module) => module.ToolIntegration),
);

const VideoContent = dynamic(() =>
  import('./VideoContent/VideoContent').then((module) => module.VideoContent),
);

const ZipCodeSearch = dynamic(() =>
  import('./zip-code-search/zip-code-search').then((module) => module.ZipCodeSearch),
);

const LeadModule = dynamic(() =>
  import('./LeadModule/LeadModule').then((module) => module.LeadModule),
);

const CiamVerification = dynamic(() =>
  import('./Ciam/Verification').then((module) => module.Verification),
);

const CiamProfile = dynamic(() => import('./Ciam/Profile').then((module) => module.Profile));

const CiamReset = dynamic(() => import('./Ciam/Reset').then((module) => module.Reset));

const CountrySwitch = dynamic(() =>
  import('./CountrySwitch/CountrySwitch').then((module) => module.CountrySwitch),
);

const TeaserTiles = dynamic(() => import('./teaser/tiles').then((module) => module.Tiles));

export const Teaser = dynamic(() => import('./teaser/tiles').then((module) => module.Tiles));

const Oxomi = dynamic(() => import('./Oxomi/OxomiSection').then((module) => module.MemoizedOxomi));

const OnlineCatalogAssets = dynamic(() =>
  import('./OnlineCatalogAssets/OnlineCatalogAssets').then((module) => module.OnlineCatalogAssets),
);

const Usercentrics = dynamic(() =>
  import('../ContentElementsGdds/usercentrics/usercentrics').then((module) => module.Usercentrics),
);

const HeroImageGdds = dynamic(() =>
  import('../ContentElementsGdds/hero-image/hero-image-gdds').then(
    (module) => module.HeroImageGdds,
  ),
);

const CountrySelector = dynamic(() =>
  import('./country-selector/country-selector').then((module) => module.CountrySelector),
);

export const contentElements = {
  accordion: { component: Accordion, isContainer: true },
  faq_accordion: { component: FAQAccordion },
  awards: { component: Awards },
  category_list: { component: CategoryTiles },
  hero_picture_slider: { component: HeroImageGdds },
  image_gallery: { component: ImageGallery },
  interactive_description: { component: InteractiveDescription },
  locator: { component: Locator },
  showroom_locator_teaser: { component: ShowroomLocatorTeaser },
  product_comparison: { component: ProductComparison },
  product_tiles: { component: ProductTiles },
  table: { component: Table },
  tabs: { component: Tabs, isContainer: true },
  text_image_teaser: { component: TextImage },
  text_image: { component: TextImage },
  crm_forms: { component: CrmForms },
  text_video: { component: TextVideo },
  tool_integration: { component: ToolIntegration },
  tiles: { component: Tiles },
  video_content: { component: VideoContent },
  zip_code_search: { component: ZipCodeSearch },
  landingpage_text: { component: LandingpageText },
  landingpage_intro: { component: LandingpageIntro },
  call_to_action_button: { component: CTAButton },
  form: { component: Form },
  lead_module: { component: LeadModule },
  ciam_verification: { component: CiamVerification },
  ciam_pw: { component: CiamReset },
  picto: { component: Picto },
  country_switch: { component: CountrySwitch },
  profile_page_embedded: { component: CiamProfile },
  teaser_tiles: { component: TeaserTiles },
  oxomi: { component: Oxomi },
  online_catalog_assets: { component: OnlineCatalogAssets },
  usercentrics: { component: Usercentrics },
  country_selector: { component: CountrySelector },
};
