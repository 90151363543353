import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from './intro-text.module.scss';

// types
import type { IntroTextProps } from './types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { getClassName } from './utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { useThemeName } from 'utils/hooks/use-theme';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useGdds } from 'utils/hooks/use-gdds';
import { useXy } from 'utils/hooks/use-xy';

export function IntroText({
  className,
  title,
  subtitle,
  intro,
  text,
  contentIndex,
  contentLength,
  containerClassName,
  spacing,
  border,
  backgroundColor,
  titlePreviewId = '#st_title',
  subtitlePreviewId = '#st_subtitle',
  introPreviewId = '#st_content',
  textPreviewId = '#st_text',
  disableInEdit = false,
  isGdds,
  isFlexItem = false,
}: Readonly<IntroTextProps>) {
  const hasHeadline = Boolean(title || subtitle);
  const themeName = useThemeName();
  const isDefault = themeName === 'DEFAULT';
  const isNordics = useNord();
  const isGddsTheme = useGdds();
  const isXy = useXy();
  const sectionId = useSectionId(title, '', false, contentIndex);
  const colSize = [4, 8, isNordics ? 8 : 12];

  let maxContentWidth;
  if (isGddsTheme) {
    maxContentWidth = buildSize(gridSizes.gddsFullGrid);
  } else if (!isGdds) {
    maxContentWidth = '80rem';
  }

  return (
    <div
      className={getClassName({
        hasHeadline,
        contentLength: contentLength ?? 0,
        contentIndex: contentIndex ?? 0,
        spacing,
        backgroundColor,
        isNordics: isNordics || isXy,
        isDefault,
        className,
      })}
      id={sectionId}
    >
      <Container maxContentWidth={maxContentWidth} className={containerClassName}>
        <Row className={styles.row}>
          <Col size={colSize}>
            <Headline
              title={title}
              subtitle={subtitle}
              text={text}
              intro={intro}
              tag={Formats.h1}
              textClassName={classNameBuilder(styles.text, intro && styles.withMargin)}
              introTextClassName={styles.intro}
              className={classNameBuilder(!intro && !text && styles.noMarginBottom)}
              {...(!disableInEdit && {
                titlePreviewId,
                subtitlePreviewId,
                introPreviewId,
                textPreviewId,
              })}
              {...((!isXy || isFlexItem) && { isFlexItem: true })}
            />
          </Col>
        </Row>
      </Container>
      {border !== 'disable-border' && (
        <Container maxContentWidth="78rem">
          <Row>
            <Col size={colSize} className={styles.border}>
              <Divider alignment="horizontal" />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
