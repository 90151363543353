import { useContext } from 'react';
import { useSelector, useStore } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/navigation';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Button as GddsButton } from '@geberit/gdds';

// styles
import styles from './shoppingbasket.module.scss';

// components
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from '../Button/Button';
import { Translation } from '../Translation/Translation';

// utils and actions
import {
  captchaKeySelector,
  globalsServerSelector,
  webshopBlockedCheckoutPageUrlSelector,
  webshopCheckoutPageUrlSelector,
  webshopCheckoutUrlSelector,
  webshopEnabledSelector,
  webshopLandingpageUrlSelector,
  webshopShippingInfoSelector,
} from 'utils/selectors/globalsSelectors';
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { Log } from 'services/log';
import { checkCartCaptcha } from './actions';
import { SessionContext } from '../Session/SessionContext';
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { useTracking } from 'utils/tracking/track';
import { useXy } from 'utils/hooks/use-xy';

interface ShoppingBasketFooterProps {
  items: any[] | null;
}

function ShoppingBasketFooter({ items = null }: Readonly<ShoppingBasketFooterProps>) {
  const webshopUrl = useSelector(webshopLandingpageUrlSelector);
  const state = useStore();
  const captchaKey = useSelector(captchaKeySelector) as string;
  const checkoutIntroUrl = useSelector(webshopCheckoutPageUrlSelector) as string;
  const language = useSelector(currentLanguageSelector);
  const showShippingInfo = useSelector(webshopShippingInfoSelector);
  const server = useSelector(globalsServerSelector);
  const router = useRouter();
  const checkoutUrl = useSelector(webshopCheckoutUrlSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const webshopDowntimeLoading = useSelector(webshopMaintenanceLoadingSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const {
    state: { ciam },
  } = useContext(SessionContext);
  const blockedUrl = useSelector(webshopBlockedCheckoutPageUrlSelector);
  const { trackWebshop } = useTracking();
  const isXy = useXy();

  const trackBeginCheckout = () => {
    trackWebshop('begin_checkout', {
      value: price,
      items:
        items?.map((item, index) => ({
          item_id: item.sku,
          item_name: item.description,
          quantity: item.quantity,
          price: parseFloat(item.pricePerItem),
          index,
        })) ?? [],
    });
  };
  const handleCaptchaClick = async () => {
    trackBeginCheckout();
    try {
      if (!isEmpty(ciam) && webshopEnabled && !webshopDowntime && !webshopDowntimeLoading) {
        const isB2C = ciam.role === 'B2C';

        if (isB2C) {
          if (checkoutUrl) {
            router.push(checkoutUrl);
          }
        } else if (blockedUrl) {
          router.push(blockedUrl);
        }
      }
      const result = await checkCartCaptcha(captchaKey, language, state);
      if (result === true || (server?.appStage && ['dev', 'qa'].includes(server?.appStage))) {
        router.push(checkoutIntroUrl);
      }
    } catch (e) {
      Log.error('Failed to check captcha', e);
    }
  };

  const price = items
    ? items.reduce((acc, cur) => acc + cur.quantity * parseFloat(cur.pricePerItem), 0).toFixed(2)
    : '0.00';

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
      <div className="my-list__footer">
        <div className="grid-x my-list__footer-gap">
          <div className="cell auto">
            <div>
              <Translation id="web20_webshop_basket_price_info" />
            </div>
            {showShippingInfo && (
              <div>
                <Translation id="web20_webshop_shipping_info" />
              </div>
            )}
          </div>
          <div className="cell small-2">
            <Translation id="web20_webshop_basket_price" />
          </div>
          <div className={`cell small-1 ${styles.footerPrice}`}>
            {price} <Translation id="web20_webshop_basket_currency" />
          </div>
        </div>
      </div>
      <div className={styles.footerBelow}>
        {isXy ? (
          <GddsButton
            stylingType="secondary"
            symbol="arrow_left"
            onClick={() => router.push(webshopUrl ?? '')}
          >
            <Translation id="web20_webshop_basket_continue" />
          </GddsButton>
        ) : (
          <Button
            type={BUTTON_TYPES.DEFAULT}
            symbol="arrow-link-light-left"
            color={BUTTON_COLORS.BLACK}
            background={BUTTON_BACKGROUND.WHITE}
            onClick={() => router.push(webshopUrl ?? '')}
            className={styles.editButton}
          >
            <Translation id="web20_webshop_basket_continue" />
          </Button>
        )}
        {items &&
          items.length > 0 &&
          (isXy ? (
            <GddsButton stylingType="primary" symbol="arrow_right" onClick={handleCaptchaClick}>
              <Translation id="web20_webshop_basket_tocheckout" />
            </GddsButton>
          ) : (
            <Button
              type={BUTTON_TYPES.DEFAULT}
              color={BUTTON_COLORS.WHITE}
              background={BUTTON_BACKGROUND.PRIMARY}
              symbol="arrow-link-bold-right"
              onClick={handleCaptchaClick}
            >
              <Translation id="web20_webshop_basket_tocheckout" />
            </Button>
          ))}
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default ShoppingBasketFooter;
