import { useState, useEffect } from 'react';
import { Button } from '@geberit/gdds';
import isEmpty from 'lodash.isempty';

// styles
import styles from './confirmationButton.module.scss';

// types
import { Person } from '../types';

// components
import { Translation } from 'components/Translation/Translation';
import { Lightbox } from 'components/lightbox/lightbox';
import { LightboxHeader } from 'components/lightbox/lightbox-header';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { isNotEmpty } from 'utils/is-not-empty';
import { localeSortBy } from 'utils/locale-sort-by';

const sortByName = localeSortBy('firstname');
const mapParticipantById = (participants: Person[]) => (id: string) =>
  participants?.find((i) => i.id === id);

interface ConfirmationButtonProps {
  values: {
    names: string[];
  };
  handleSubmit: (all: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  participants: Person[];
}

export function ConfirmationButton({
  values,
  handleSubmit,
  participants,
}: Readonly<ConfirmationButtonProps>) {
  const headline = useTranslationByKey('campus_cancel_confirmation_headline');
  const [modalOpen, setModalOpen] = useState(false);
  const [persons, setPersons] = useState<Person[]>([]);
  const listStyles = persons.length > 5 ? styles.listTwoCols : '';

  useEffect(() => {
    if (values?.names) {
      setPersons(
        (values.names.map(mapParticipantById(participants)).filter(isNotEmpty) as Person[]).sort(
          sortByName,
        ),
      );
    }
  }, [participants, values]);

  // Open Lightbox with Modal
  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const cancelModal = () => {
    setPersons(
      (values.names.map(mapParticipantById(participants)).filter(isNotEmpty) as Person[]).sort(
        sortByName,
      ),
    );
    toggleModal();
  };

  const toggleModalAll = () => {
    setPersons(participants.filter(isNotEmpty).sort(sortByName));
    setModalOpen((prev) => !prev);
  };

  const handleCancelSelected = (e) => {
    toggleModal();
    handleSubmit(persons.length === participants.length)(e);
  };

  return (
    <div className="grid-container">
      <div className={styles.formButtons}>
        <Button stylingType="primary" onClick={toggleModal} disabled={isEmpty(values.names)}>
          <Translation id="campus_cancel_selection" />
        </Button>

        {modalOpen && (
          <Lightbox onCloseRequest={cancelModal}>
            <div className={`c-lightbox__container ${styles.campusLightbox}`}>
              <LightboxHeader handleClose={toggleModal} title={headline} />
              <div className={styles.campusParticipantsList}>
                <div className={styles.scrollWrapper}>
                  <ul className={listStyles}>
                    {persons.map((personItem) => (
                      <li key={personItem.id}>
                        {personItem?.firstname} {personItem?.lastname}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className={styles.buttonWrapper}>
                <div className={styles.modalButtons}>
                  <Button stylingType="primary" onClick={handleCancelSelected}>
                    <Translation id="campus_confirm" />
                  </Button>
                  <Button onClick={cancelModal}>
                    <Translation id="campus_cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </Lightbox>
        )}
        <Button onClick={toggleModalAll}>
          <Translation id="campus_cancel_entire" />
        </Button>
      </div>
    </div>
  );
}
