import React from 'react';
import { Translation } from '../../Translation/Translation';
import { Icon } from '../../Icon/Icon';

// styles
import styles from './courseTeaser.module.scss';

interface CourseTeaserItemProps {
  icon: string;
  title: string;
  text: string;
}

export default function CourseTeaserItem({ icon, title, text }: Readonly<CourseTeaserItemProps>) {
  return (
    <div className={styles.item}>
      <span className={styles.icon}>
        <Icon symbol={icon} />
      </span>
      <span>
        <b>
          <Translation id={title} />
        </b>
        <span>{text}</span>
      </span>
    </div>
  );
}
