// styles
import styles from './range-indicator.module.scss';

type RangeIndicatorProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  max?: string;
};

export function RangeIndicator(props: Readonly<RangeIndicatorProps>) {
  const { value, handleChange, hidden, max = '100' } = props;

  return (
    <div className={styles.rangeIndicatorContainer}>
      <input
        type="range"
        min="0"
        max={max}
        step="1"
        value={value}
        onChange={(e) => handleChange(e)}
        hidden={hidden}
      />
    </div>
  );
}
