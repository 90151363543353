'use client';

// types
import type { DownloadcenterProps } from 'features/download-center/components/types';

// components
import Downloadcenter from 'features/download-center/components/group/downloadcenter';
import Layout from 'scenes/Group/Layout';

export interface DownloadCenterGroupProps extends DownloadcenterProps {
  metaData: MetaData;
}

export default function DownloadCenterGroup({
  metaData,
  breadcrumbs,
  page,
  contentAreas,
}: Readonly<DownloadCenterGroupProps>) {
  return (
    <Layout metaData={metaData}>
      <Downloadcenter
        page={page}
        contentAreas={contentAreas}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  );
}
