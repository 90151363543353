import { useState } from 'react';

// components
import { BUTTON_COLORS, BUTTON_BACKGROUND, Button } from 'components/Button/Button';

// styles
import styles from './courseList.module.scss';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

interface MoreButtonProps {
  text: string;
  children: React.ReactNode;
}

export default function MoreButton({ text, children = null }: Readonly<MoreButtonProps>) {
  const [menuStyle, setMenuStyle] = useState({
    right: 0,
    display: 'none',
  });

  const toggleMenu = (e) => {
    const right = e.target.parentNode.parentNode.offsetWidth - e.target.offsetLeft;

    setMenuStyle({
      right,
      display: menuStyle.display === 'block' ? 'none' : 'block',
    });
  };
  const closeMenu = () => {
    setTimeout(() => {
      setMenuStyle({
        right: 0,
        display: 'none',
      });
    }, 500);
  };

  return (
    <div className={styles.moreWrapper}>
      <Button
        background={BUTTON_BACKGROUND.WHITE}
        color={BUTTON_COLORS.BLACK}
        className={classNameBuilder('more', menuStyle.display === 'block' && 'open')}
        onClick={toggleMenu}
        onBlur={closeMenu}
      >
        {text}
      </Button>
      <div
        className={classNameBuilder('more-menu', menuStyle.display === 'block' && 'open')}
        style={menuStyle}
      >
        {children}
      </div>
    </div>
  );
}
