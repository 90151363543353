import { useEffect } from 'react';

// styles
import styles from './campusvideo.module.scss';

// utils
import {
  useCampusAuthGate,
  useCampusMediaStateStarted,
  useCampusMediaStatePassed,
} from 'utils/hooks/useCampus';
import { emptyStringFallback } from 'utils/is-empty';

interface CampusVideoProps {
  courseId: number;
  mediaId: number;
  content: any;
}

export function CampusVideo({ courseId, mediaId, content }: Readonly<CampusVideoProps>) {
  const { employeeToken, ciamToken } = useCampusAuthGate(courseId);
  const { setStarted } = useCampusMediaStateStarted(courseId);
  const { setPassed } = useCampusMediaStatePassed(courseId);

  const accessToken = emptyStringFallback(ciamToken, employeeToken);

  useEffect(() => {
    if (accessToken) {
      // for videos we need to set the media as started and afterwards with a delay to passed to set it to done
      setStarted({ mediaId, accessToken });
      const timeoutPassed = setTimeout(() => setPassed({ mediaId, accessToken }), 500);

      return () => {
        clearTimeout(timeoutPassed);
      };
    }
    return () => {};
  }, [accessToken, mediaId, setStarted, setPassed]);

  return (
    <div className={styles.videoBg}>
      <iframe
        title={String(courseId)}
        src={content?.source}
        width="100%"
        height="100%"
        allowFullScreen={true}
      />
    </div>
  );
}
