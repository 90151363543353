import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useStore } from 'react-redux';

// types
import { FetchMaterialResult, MaterialRequestConfig, Tabs } from '../types/press-portal.types';
import { ContactPeople, Material, Media } from '../types/mynewsdesk.types';

// constants
import { MATERIAL_TYPES } from '../constants';

// utils
import getEndpoint from 'utils/endpoints';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

function useViewMaterial<T extends Media | Material>({
  id,
  type,
  locale,
}: MaterialRequestConfig): FetchMaterialResult<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [material, setMaterial] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const lang = useCurrentLanguage();
  const state = useStore();

  useEffect(() => {
    if (!id || !type) return;

    const fetchMaterial = async () => {
      setLoading(true);
      try {
        const url = `${getEndpoint('newsDesk', state)}/view`;
        const data = await axios
          .get<{ item: T[] }>(url, {
            params: {
              item_id: id,
              type_of_media: type,
              locale: locale.toLowerCase(),
              lang,
            },
          })
          .then((response) => response.data);
        const relatedMaterialsPromises = data.item[0].related_items.map((item) =>
          axios
            .get<{ item: (Media | Material | ContactPeople)[] }>(url, {
              params: {
                item_id: item.item_id,
                type_of_media: item.type_of_media,
                locale: locale.toLowerCase(),
                lang,
              },
            })
            .then((response) => response.data),
        );

        const media = data.item[0] as Material;
        const relatedMaerialsArray = await Promise.all(relatedMaterialsPromises);
        const relatedItems = relatedMaerialsArray.flatMap((e) => e.item);
        const relatedNews = relatedItems.filter((e) =>
          MATERIAL_TYPES[Tabs.News].map((e) => e.value).includes(e.type_of_media),
        );
        const relatedMaerials = relatedItems.filter((e) =>
          MATERIAL_TYPES[Tabs.MediaLibrary].map((e) => e.value).includes(e.type_of_media),
        );
        media.related_materials = relatedMaerials as Media[];
        media.related_news = relatedNews as Material[];
        media.contact_people = media.contact_people?.length
          ? media.contact_people
          : (relatedItems.filter((i) => i.type_of_media === 'contact_person') as ContactPeople[]);
        setError(null);
        setMaterial(media as T);
      } catch (err: unknown) {
        const error = err as Error | AxiosError;
        if (axios.isAxiosError(error)) {
          setError(`api error: ${error.message}`);
        } else {
          setError(`internal error: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMaterial();
  }, [id, type]);

  return { loading, error, material };
}

export { useViewMaterial as useFetchMaterial };
