import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

// styles
import styles from './hero-image.module.scss';

// types
import type { HeroImageGddsProps, ModifiedItem } from './hero-image-gdds.types';

// components
import FullscreenIframe from '../fullscreen-iframe/fullscreen-iframe';
import { HeroImageVideoPlayer } from './components/hero-image-video-player';
import { HeroImageThemed } from './components/hero-image-themed';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTrackingGA4 } from 'utils/tracking/track';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { useIsMobile } from 'components/App/SizeProvider';
import { useContent } from 'components/Page/page-hooks';
import { getModifiedItems, getVideoSlide } from './hero-image-gdds.utils';

function HeroImageGdds(props: Readonly<HeroImageGddsProps & BaseSection>) {
  const {
    items,
    isSeoConformCarousel,
    contentBoxPosition,
    isSection,
    disableAnimation,
    disableSliderControls,
    hideScrollIndicator,
    wideLayout,
  } = props;

  const isItemListAvailable = typeof items !== 'undefined' && items.length > 0;

  const isMobile = useIsMobile();
  const [modifiedItems, setModifiedItems] = useState<ModifiedItem[]>([]);
  const [iframeShown, setIframeShown] = useState<string | null>(null);
  const language = useCurrentLanguage();
  const translate = useTranslationFunction();
  const page = useContent();
  const track = useTracking();
  const { trackClick } = useTrackingGA4();
  const router = useRouter();

  useEffect(() => {
    if (isItemListAvailable) {
      const newItems = getModifiedItems(router, {
        items,
        isMobile,
        hideScrollIndicator,
        track,
        trackClick,
        translate,
        setIframeShown,
        isSection,
      });

      setModifiedItems(newItems);
    } else {
      setModifiedItems([]);
    }
  }, [
    language,
    isMobile,
    hideScrollIndicator,
    iframeShown,
    isSection,
    items,
    translate,
    track,
    trackClick,
    isItemListAvailable,
    router,
  ]);

  if (!isItemListAvailable || isEmpty(modifiedItems)) return null;

  const video = getVideoSlide(items);

  const isNordicsContentPage = page?.type === 'nordics_contentpage';
  const isHomepage = page?.type === 'nordics_homepage' || page?.type === 'kolo_homepage';

  return (
    <div
      className={classNameBuilder(
        styles.heroimage,
        isNordicsContentPage && styles.contentpage,
        isHomepage && styles.homepage,
        'hero-image-gdds',
        isSection && styles.isSection,
      )}
    >
      {video ? (
        <HeroImageVideoPlayer
          disableAnimation={disableAnimation}
          video={video}
          wideLayout={wideLayout}
          isSection={isSection}
        />
      ) : (
        <HeroImageThemed
          items={modifiedItems}
          isSeoConformCarousel={isSeoConformCarousel}
          contentBoxPosition={contentBoxPosition}
          hideScrollIndicator={hideScrollIndicator}
          disableAnimation={disableAnimation}
          disableSliderControls={disableSliderControls}
          wideLayout={wideLayout}
          isSection={isSection}
        />
      )}

      {iframeShown && <FullscreenIframe src={iframeShown} onClose={() => setIframeShown(null)} />}
    </div>
  );
}

export { HeroImageGdds };
