'use client';

// styles
import { HiddenH1 } from './home.styles';

// types
import type { HomeProps } from './home.types';

// components
import Footer from 'components/Footer/Footer';
import { HeroTiles, mapToHeroTileItems } from 'components/ContentElementsGdds/Tiles/hero-tiles';
import { ContentArea } from 'components/ContentArea/ContentArea';
import SearchBarHome from 'components/SearchBar/SearchBarHome';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';

// utils
import { isEmpty } from 'utils/is-empty';
import { getHeroImageItems } from './Home.utils';
import { useContent } from 'components/Page/page-hooks';

export function Home() {
  const page = useContent<{}, HomeProps>();

  if (isEmpty(page?.contentAreas)) {
    return null;
  }

  const {
    metaData,
    page: { slider, heroTile },
    contentAreas,
  } = page;

  const sliderItems = getHeroImageItems({ items: slider?.items });
  const heroTileItems: HeroTileItemType[] = mapToHeroTileItems(heroTile);

  return (
    <>
      <main>
        {metaData.title && <HiddenH1>{metaData.title}</HiddenH1>}
        <HeroImageGdds items={sliderItems} />
        <SearchBarHome />
        <HeroTiles
          items={heroTileItems}
          showArrows={heroTile?.showArrows ?? true}
          slidingInterval={(heroTile?.slideDuration ?? 6000) / 1000}
        />
        <ContentArea content={contentAreas?.tile} contentType="tile" from="homepage" />
      </main>
      <Footer />
    </>
  );
}
