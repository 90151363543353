import { getServerVars } from 'utils/get-server-vars';
import { Log } from './log';
import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

type IServiceName =
  | 'navigationservice'
  | 'keyvalueservice'
  | 'technicalservice'
  | 'contentservice'
  | 'productservice'
  | 'maintenanceservice'
  | 'eventservice'
  | 'jobservice'
  | 'insightsservice'
  | 'elasticsearchservice'
  | 'campusservice'
  | 'videoservice'
  | 'schemaservice'
  | 'downloadsservice'
  | 'formservice'
  | 'mediareleaseservice';

class ServiceError extends Error {
  readonly status: number;
  readonly service: string;
  readonly pathname: string;
  readonly data: string;

  constructor(status, service, pathname, data) {
    super(`Service[${service}] http-status ${status}`);
    this.status = status;
    this.service = service;
    this.pathname = pathname;
    this.data = data;
  }
}

export function fetchService(name: IServiceName, headersList: ReadonlyHeaders) {
  if (typeof window !== 'undefined') {
    console.error("Dont' call services in the browser");
  }

  let appStage = 'dev';
  if (process.env.APP_STAGE?.startsWith('prod')) {
    appStage = 'prod';
  } else if (process.env.APP_STAGE?.startsWith('qa')) {
    appStage = 'qa';
  }

  const [url, filterHeaders] = (function () {
    const { isPrelive, isPreview } = getServerVars(headersList);

    if (name === 'elasticsearchservice') {
      return [
        `https://api-${isPrelive ? 'prelive-' : ''}${name}.${appStage}.platform.web.geberit.com`,
        (header) => header === 'x-geb-api-req',
      ];
    }

    const previewPrefix = isPreview ? '-preview' : '';
    const prelivePrefix = isPrelive ? '-prelive' : previewPrefix;
    return [
      process.env.IS_LOCAL === '1' || name === 'campusservice'
        ? `https://api${prelivePrefix}-${name}.${appStage}.platform.web.geberit.com`
        : `http://${name}:8080`,
      () => true,
    ];
  })();

  let headers: Record<string, string> = {
    'caas-database': headersList.get('x-caas-project-db') ?? '',
    'Content-Type': headersList?.get('content-type') ?? '',
  };

  if (process.env.IS_LOCAL === '1' || name === 'campusservice') {
    headers['x-geb-api-req'] = '1';
    if (process.env.CAAS_PROJECT_DB) {
      headers['caas-database'] = process.env.CAAS_PROJECT_DB;
    }
  }
  headers = Object.keys(headers)
    .filter(filterHeaders)
    .reduce((acc, key) => {
      acc[key] = headers[key];
      return acc;
    }, {});

  const raw = async (
    endpoint: string,
    options: { method: 'GET' } | { method: 'POST' | 'PATCH' | 'PUT'; body: BodyInit | null } = {
      method: 'GET',
    },
    revalidate?: false | number,
  ) => {
    if (
      name === 'productservice' &&
      (endpoint.includes('customer/') || endpoint.includes('cart/session'))
    ) {
      // proxy complete request with authorization
      headers['uidsignature'] = headersList?.get('uidsignature') ?? '';
      headers['signaturetimestamp'] = headersList?.get('signaturetimestamp') ?? '';
    }

    const requestInit: RequestInit & { duplex?: 'half' } = {
      ...options,
      headers,
      redirect: 'manual',
      duplex:
        options?.method === 'POST' || options?.method === 'PATCH' || options?.method === 'PUT'
          ? 'half'
          : undefined,
    };

    if (name === 'formservice') {
      requestInit.headers = {
        ...requestInit.headers,
        referer: headersList?.get('referer') ?? '',
        uploadToBlobStorage: headersList?.get('uploadtoblobstorage') ?? 'false',
      };
    }
    if (name === 'elasticsearchservice') {
      requestInit.headers = {
        ...requestInit.headers,
        'x-geb-api-req': '1',
      };
    }

    if (typeof revalidate !== 'undefined' && requestInit.next) {
      requestInit.next.revalidate = revalidate;
    }

    const proxyResp = await fetch(`${url}${endpoint}`, requestInit).catch((e) => {
      return {
        ok: false,
        headers: new Map(),
        text: async () => e.toString(),
        arrayBuffer: async () => new ArrayBuffer(0),
        status: 500,
      };
    });
    Log.info(
      `fetchService[${name}]\n`,
      `URL:     ${url}${endpoint}\n`,
      `METHOD:  ${options.method}\n`,
      `HEADERS: ${JSON.stringify(requestInit.headers)}\n`,
      `STATUS:  ${proxyResp.status}\n`,
      `HOST:  ${headersList.get('host')}\n`,
      `REFERER:  ${headersList.get('referer')}\n`,
      '---',
    );

    return proxyResp;
  };

  const json = async (
    endpoint: string,
    options: { method: 'GET' } | { method: 'POST' | 'PATCH' | 'PUT'; body: BodyInit | null } = {
      method: 'GET',
    },
    revalidate?: false | 0 | number,
  ) => {
    const proxyResp = await raw(endpoint, options, revalidate);

    if (!isOk(proxyResp)) {
      const message = await proxyResp.text();
      Log.error(`Service[${name}] http-status ${proxyResp.status}`, endpoint, message);
      throw new ServiceError(proxyResp.status, name, endpoint, message);
    }

    if (proxyResp.headers.get('content-length') !== '0') {
      return proxyResp.json();
    }

    return {};
  };

  return { raw, json };
}

function isOk(
  resp:
    | Response
    | {
        ok: boolean;
        text: () => Promise<string>;
        status?: number;
      },
): resp is Response {
  return resp.ok;
}
