import { useRef } from 'react';
import key from 'weak-key';

// components
import FilterOption from './FilterOption';
import { Dropdown } from 'components/Dropdown/Dropdown';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

type FilterProps = {
  activeItems: string[] | null;
  filterKey: string;
  options: any[];
  handleFilterChange: any;
  isCheckbox?: boolean;
};

function Filter({
  options,
  handleFilterChange,
  activeItems = null,
  filterKey = 'invalid or empty string',
  isCheckbox = false,
}: Readonly<FilterProps>) {
  const dropdownRef = useRef<any>(null);
  const translate = useTranslationFunction();
  const filterTranslation = translate(`web20_downloadcenter_${filterKey}`);

  if (!options[0].isAllFilter && filterKey !== 'sorting') {
    options.unshift({
      value: 'web20_downloadcenter_all_filter',
      count: null,
      isAllFilter: true,
    });
  }

  const findSortValue = (items) =>
    activeItems && items.find((option) => option?.id === activeItems[0]);

  const dropdownName =
    activeItems && filterKey === 'sorting' ? findSortValue(options)?.value : filterTranslation;

  const isAllFilterSelected = (option) => {
    if (option.isAllFilter === undefined) {
      return activeItems?.some((sort) => sort === option.value);
    }

    return !activeItems || activeItems.length === 0;
  };

  const placeholder =
    typeof dropdownName === 'string' && dropdownName.includes('web20')
      ? translate(dropdownName)
      : dropdownName;

  return (
    <div className="form-item">
      <Dropdown placeholder={placeholder} label={placeholder} ref={dropdownRef}>
        <div className={`form-items ${!isCheckbox ? 'form-items--radio-list' : ''}`}>
          {options && (
            <ul>
              {options.map((option, index) => (
                <FilterOption
                  key={key(option)}
                  option={option}
                  index={index}
                  isCheckbox={isCheckbox}
                  filterKey={filterKey}
                  checked={isAllFilterSelected(option)}
                  handleFilterChange={handleFilterChange}
                  isAllFilter={option.isAllFilter !== undefined}
                />
              ))}
            </ul>
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default Filter;
