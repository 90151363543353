import { Dropdown as GddsDropdown } from '@geberit/gdds';
import { useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import key from 'weak-key';

// types
import type { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';
import type { PreconDropdownContainerField } from './precon-dropdown-container';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { isEmpty } from 'utils/is-empty';
import { PRE_CON_LABEL_MAPPING } from 'components/ContentElements/Form/utils/mappings';
import { FormContext } from 'components/ContentElements/Form/form-context';
import { useGdds } from 'utils/hooks/use-gdds';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';

interface PreConDropdownProps {
  options: DropdownModelOption[];
  iteration: number;
  disabled: boolean;
  field: PreconDropdownContainerField;
  defaultDropdownValue: string[];
}

export default function PreConDropdown(props: Readonly<PreConDropdownProps>) {
  const { options, iteration, disabled, field, defaultDropdownValue } = props;
  const { handleBlur, handleChange, setFieldValue, values, errors, touched } =
    useFormikContext<Record<string, string>>();
  const { preConNames } = useContext(FormContext);
  const isGdds = useGdds();

  const name = preConNames[iteration];
  const currLabelName = PRE_CON_LABEL_MAPPING[iteration];
  const defaultValue = defaultDropdownValue[iteration];
  const currentValue = values[name];
  const hasFirstParentSelected = Boolean(values[preConNames[0]]);
  const hasSelectedParent = Boolean(currentValue);
  let iterateOptions: DropdownModelOption | undefined = options[0];
  const sortedOptions = options;
  sortedOptions.sort((a, b) => a.sort - b.sort);

  // -2 since art id is not visible
  const lastPreConValue = preConNames[preConNames.length - 2];

  useEffect(() => {
    if (iterateOptions && currentValue) {
      setFieldValue(preConNames[preConNames.length - 1], iterateOptions.artId);
    }

    // reset child values if parent got changed
    if (currentValue) {
      const preConValueClone = preConNames.slice(0, -1);
      const currentIndex = preConNames.indexOf(name);
      const children = preConValueClone.splice(currentIndex + 1, preConNames.length);

      children.forEach((fieldName) => {
        setFieldValue(fieldName, '');
      });
    }
  }, [iterateOptions, preConNames, setFieldValue, currentValue, name]);

  // if this field has a precondition and this is not enabled, don't throw an error
  if (!iterateOptions.children && iteration === 1 && errors[lastPreConValue]) {
    delete errors[lastPreConValue];
  }

  if (hasSelectedParent) {
    iterateOptions = options.find((option) => option.name === currentValue);
  }

  const fieldErrors = (touched[name] && (errors as Record<string, string>)) || {};

  return (
    <>
      <div className="cell small-12 medium-6 dependency-dropdown">
        {!isGdds && <h3 className="h4">{field[currLabelName]}</h3>}
        <div
          className={classNameBuilder(
            'form-item form-item--dropdown',
            disabled && 'form-item--disabled',
            !isEmpty(errors[name]) && touched[name] && 'form-item--error',
          )}
        >
          <div className={!isGdds ? 'dropdown__trigger' : ''}>
            {isGdds ? (
              <GddsDropdown
                name={name}
                errors={fieldErrors}
                placeholder={defaultValue}
                onChange={(fi: SelectOption & DropdownModelOption) => {
                  setFieldValue(name, fi.name);
                }}
                onBlur={handleBlur}
                label={field[currLabelName]}
                items={[{ text: defaultValue, value: '', id: '' }].concat(
                  sortedOptions?.map((option) => ({
                    ...option,
                    id: String(option.id),
                    text: decodingContent(option.name),
                    value: option.name,
                  })),
                )}
                values={values}
                outlined
                required={field.required}
              />
            ) : (
              <select
                id={name}
                name={name}
                value={currentValue}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                data-testid={name}
              >
                <option value="">{defaultValue || ''}</option>
                {!disabled &&
                  !isEmpty(sortedOptions) &&
                  sortedOptions.map((option) => (
                    <option value={option.name} key={key({ option })}>
                      {decodingContent(option.name)}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        {!isGdds && (
          <InnerHtml
            as="p"
            className="form-item__error-message"
            content={
              (errors[name] &&
                touched[name] &&
                !isEmpty(field.rules) &&
                errors[name] !== 'keepErrorMessage' &&
                errors[name]) ||
              ''
            }
          />
        )}
      </div>
      {iterateOptions?.children &&
        ((!hasFirstParentSelected && iteration < 1) || hasFirstParentSelected) && (
          <PreConDropdown
            {...props}
            options={iterateOptions.children}
            iteration={iteration + 1}
            disabled={!hasSelectedParent}
          />
        )}
    </>
  );
}
