import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import type { AnyAction } from 'redux';

// styles
import styles from './overview-container.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from 'components/Button/Button';
import { Loader } from 'components/Loader/Loader';
import { AddressBox } from '../../Components/AddressBox/AddressBox';
import ErrorMessageBox from '../../Components/ErrorMessageBox/ErrorMessageBox';
import LinkWrapper from '../../Components/LinkWrapper/LinkWrapper';

// utils
import {
  activeStepSelector,
  deliveryAddressSelector,
  invoiceAddressSelector,
  isPaymentDataFetchingSelector,
  isSimulatedOrderDataFetchingSelector,
  paymentDataSelector,
  paymentErrorSelector,
  simulatedOrderDataSelector,
  simulatedOrderErrorSelector,
} from '../../selectors';
import { WEBSHOP_CAMA_ID, WEBSHOP_ONLINE_PAYLIMIT } from '../../constants';
import { changeActiveStepAction, placeOrderAction } from '../../actions';
import { mapSapAndCiamCodesToSalutations } from '../../util/helperFunctions';
import { useWebshopCartItem } from 'utils/hooks/useWebshopCartItem';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useVoucher } from 'utils/hooks/useVoucher';
import { classNameBuilder } from 'utils/classNameBuilder';
import OrderContents from 'components/Checkout/Containers/OrderContents/OrderContents';
import { shoppingBasketItemsSelector } from 'utils/selectors/shoppingBasketSelector';

interface OverviewContainerProps {
  isLogged: boolean;
  locale: string;
  sessionId: string;
  ciamId: string;
}

export function OverviewContainer({
  isLogged,
  locale,
  sessionId,
  ciamId,
}: Readonly<OverviewContainerProps>) {
  const activeStep = useSelector(activeStepSelector);
  const invoiceAddress = useSelector(invoiceAddressSelector);
  const deliveryAddress = useSelector(deliveryAddressSelector);
  const simulatedOrder = useSelector(simulatedOrderDataSelector);
  const isSimulatedOrderDataFetching = useSelector(isSimulatedOrderDataFetchingSelector);
  const simulatedOrderError = useSelector(simulatedOrderErrorSelector);
  const isFetchingPaymentData = useSelector(isPaymentDataFetchingSelector);
  const paymentData = useSelector(paymentDataSelector);
  const setPaymentError = useSelector(paymentErrorSelector);
  const translate = useTranslationFunction();
  const dispatch = useDispatch();
  const basketItems = useSelector(shoppingBasketItemsSelector);

  const [productCheckLoading, hasProduct, overAmount] = useWebshopCartItem(
    WEBSHOP_CAMA_ID,
    WEBSHOP_ONLINE_PAYLIMIT,
  );
  const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
  const [paymentOption, setPaymentOption] = useState<'INVOICE' | 'PSP'>(
    locale.includes('CH') ? 'INVOICE' : 'PSP',
  );
  const salutationTranslationKeyInvoiceAddress = mapSapAndCiamCodesToSalutations(
    invoiceAddress?.salutation,
  );
  const salutationTranslationKeyDeliveryAddress = mapSapAndCiamCodesToSalutations(
    deliveryAddress?.salutation,
  );

  const voucher = useVoucher(invoiceAddress, deliveryAddress);

  useEffect(() => {
    if (!isLogged || (!productCheckLoading && (hasProduct || overAmount))) {
      setPaymentOption('PSP');
    }
  }, [productCheckLoading, hasProduct, overAmount, isLogged]);

  useEffect(() => {
    window.onpopstate = (event) => {
      if (activeStep === 1) {
        event.preventDefault();
        history.go(0);
        dispatch(changeActiveStepAction(activeStep - 1));
        history.go(1);
      }
    };
  }, []);

  const handlePlaceOrder = () => {
    const { sapCustomerNumber, ...userWithoutEmail } = invoiceAddress;
    const id = isLogged ? ciamId : sessionId;
    const body = {
      addresses: {
        invoiceAddress: userWithoutEmail,
        deliveryAddress,
      },
      redirectUrls: {
        success: `${window.location.href}?success=1`,
        abort: `${window.location.href}?error=770`,
        fail: `${window.location.href}?error=760`,
      },
      order: {
        voucherCode: voucher.appliedVoucher,
      },
    };

    const handleTracking = (orderData) => {
      localStorage.setItem(
        orderData.orderNumber,
        JSON.stringify({ basketItems, voucherCode: voucher.appliedVoucher }),
      );
    };

    dispatch(
      placeOrderAction(
        locale,
        id,
        body,
        isLogged,
        paymentOption,
        handleTracking,
      ) as unknown as AnyAction,
    );
  };

  // wrapped in a use effect because window is not defined on server side
  useEffect(() => {
    if (!isEmpty(paymentData)) {
      if (paymentData.paymentType === 'INVOICE') {
        window.location.replace(
          `${window.location.pathname}?success=1&locale=${locale}&orderId=${paymentData.orderNumber}`,
        );
      }
      if (paymentData.paymentType === 'PSP' && paymentData.paymentRedirect) {
        window.location.replace(paymentData.paymentRedirect);
      }
    }
  }, [locale, paymentData]);

  const renderPaymentOptions = () => {
    if (locale) {
      if (!hasProduct && !overAmount && locale.includes('CH') && isLogged) {
        return (
          <>
            <h5 className={styles.bottomMargin}>
              <Translation id="web20_checkout_overview_payment_options_heading" />
            </h5>
            <div className={classNameBuilder('grid-y', styles.bottomMargin)}>
              <label htmlFor="PSP" className={styles.label}>
                <input
                  className={styles.inputRadio}
                  type="radio"
                  name="allgemeine_objektangaben"
                  value="PSP"
                  onClick={() => setPaymentOption('PSP')}
                  id="PSP"
                  defaultChecked={paymentOption === 'PSP'}
                />
                <span>
                  <Translation id="web20_checkout_overview_payment_option_1_label_part_1" />{' '}
                  <span className="bold">
                    <Translation id="web20_checkout_overview_payment_option_1_long" />
                  </span>{' '}
                  <Translation id="web20_checkout_overview_payment_option_1_label_part_2" />
                </span>
              </label>
              <label htmlFor="INVOICE" className={styles.label}>
                <input
                  type="radio"
                  className={styles.inputRadio}
                  value="INVOICE"
                  name="allgemeine_objektangaben"
                  id="INVOICE"
                  onClick={() => setPaymentOption('INVOICE')}
                  defaultChecked
                />
                <span>
                  <Translation id="web20_checkout_overview_payment_option_2_label_part_1" />{' '}
                  <span className="bold">
                    <Translation id="web20_checkout_overview_payment_option_2_long" />
                  </span>
                </span>
              </label>
            </div>
          </>
        );
      }
      return (
        <p className={styles.singlePaymentLabel}>
          <Translation id="web20_checkout_overview_payment_songle_option_1" />{' '}
          <span className="bold">
            <Translation id="web20_checkout_overview_payment_option_1_short" />
          </span>{' '}
        </p>
      );
    }
    return null;
  };

  if (productCheckLoading || isSimulatedOrderDataFetching || isFetchingPaymentData) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size="medium" />
      </div>
    );
  }

  const AddressSection = (
    <div className="grid-x">
      <div className="cell small-12 medium-5 large-5">
        <AddressBox
          country={translate(`countries_${invoiceAddress?.country?.toLowerCase()}`)}
          address={invoiceAddress}
          salutation={translate(salutationTranslationKeyInvoiceAddress)}
          title={translate(
            isLogged
              ? 'web20_checkout_address_logged_user_invoice_form_heading'
              : 'web20_checkout_address_invoice_form_heading',
          )}
        />
        <Button
          type={BUTTON_TYPES.DEFAULT}
          symbol="arrow-link-light-left"
          color={BUTTON_COLORS.BLACK}
          background={BUTTON_BACKGROUND.WHITE}
          onClick={() => dispatch(changeActiveStepAction(activeStep - 1))}
          className={styles.editButton}
        >
          {translate('web20_menu_back')}
        </Button>
      </div>
      <div className="cell small-12 medium-5 large-5">
        <AddressBox
          country={translate(`countries_${deliveryAddress?.country?.toLowerCase()}`)}
          address={deliveryAddress}
          salutation={translate(salutationTranslationKeyDeliveryAddress)}
          title={translate('web20_checkout_address_delivery_form_heading')}
          isDelivery
        />
      </div>
    </div>
  );

  return (
    <div>
      <h1 className={styles.h1}>
        <Translation id="web20_checkout_overview_heading_part_1" />
        <span>
          <Translation id="web20_checkout_overview_heading_part_2" />
        </span>
      </h1>

      {AddressSection}
      <hr className={styles.bottomMargin} />

      {simulatedOrderError || setPaymentError ? (
        <ErrorMessageBox>
          {simulatedOrderError ? (
            <>
              740 -
              <Translation id="web20_checkout_overview_error" />
            </>
          ) : (
            <>
              750 -
              <Translation id="web20_checkout_overview_placing_order_error" />
            </>
          )}{' '}
          <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
        </ErrorMessageBox>
      ) : (
        <>
          <h3 className={classNameBuilder(styles.bottomMargin, styles.h3)}>
            <Translation id="web20_checkout_overview_basket_heading" />
          </h3>
          <div className="grid-y grid-padding-y">
            <OrderContents voucher={voucher} orderData={simulatedOrder} />

            <div className="cell grid-x grid-margin-x">
              <div className="cell grid-y large-8 medium-6 small-8" />
              <div
                className={classNameBuilder(
                  'cell large-4 medium-6 small-12 grid-y',
                  styles.termsAndConditions,
                )}
              >
                {renderPaymentOptions()}
                <div className={classNameBuilder('cell grid-x', styles.bottomMargin)}>
                  <label htmlFor="terms_and_conditions" className={styles.label}>
                    <input
                      type="checkbox"
                      id="terms_and_conditions"
                      name="interest"
                      className={classNameBuilder(styles.termsCheckbox, styles.inputCheckbox)}
                      checked={agreeToTermsAndConditions}
                      onChange={() => setAgreeToTermsAndConditions((prev) => !prev)}
                    />
                    <span>
                      {' '}
                      <Translation id="web20_checkout_overview_payment_terms_and_conditions_label_part_1" />{' '}
                      <LinkWrapper
                        translationLink={translate('web20_checkout_link_terms_and_conditions')}
                      />{' '}
                      <Translation id="web20_checkout_overview_payment_terms_and_conditions_label_part_3" />
                    </span>
                  </label>
                </div>
                <Button
                  disabled={!agreeToTermsAndConditions}
                  className="form-item--button"
                  type={BUTTON_TYPES.DEFAULT}
                  symbol="arrow-link-light-right"
                  color={BUTTON_COLORS.WHITE}
                  background={BUTTON_BACKGROUND.PRIMARY}
                  onClick={handlePlaceOrder}
                >
                  {/* Confirm and proceed to payment */}
                  {translate('web20_checkout_overview_button_next')}
                </Button>
              </div>
            </div>
          </div>
          <div className={classNameBuilder(styles.buttonsWrapper, styles.bottomMargin)}></div>
        </>
      )}
    </div>
  );
}
