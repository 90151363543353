// types
import type { FieldProps } from 'components/ContentElements/Form/field';
import type { IFieldsetsMap, FieldsGroup } from 'components/ContentElements/Form/nord/types';

export class FieldsetsMap implements IFieldsetsMap {
  id: string | undefined;
  colorVariant: string | undefined;
  tmpFields: FieldProps[] = [];
  legend: string;
  readonly fieldsetsGroup: FieldsGroup[] = [];
  private _open: boolean;
  type: 'fieldset' | 'container';

  constructor() {
    this.reset();
  }

  private reset() {
    this.open = false;
    this.legend = '';
    this.colorVariant = '';
    this.id = '';
  }

  beginFieldset(field: FieldProps): void {
    // Handle fields, if any exist, before starting a fieldset.
    if (this.tmpFields.length) {
      this.finish();
    }

    this.open = true;
    this.legend = field.label;
    this.type = 'fieldset';
    this.colorVariant = field.colorVariant;
    this.id = field.id;
  }

  addField(field: FieldProps): void {
    this.tmpFields.push(field);
  }

  finishFieldset(): void {
    if (!this.open) return;

    if (this.tmpFields.length > 0) {
      this.fieldsetsGroup.push({
        fields: this.tmpFields,
        legend: this.legend,
        id: this.id,
        type: this.type,
        colorVariant: this.colorVariant,
      });
    }
    this.reset();
  }

  finish(): void {
    if (this.tmpFields.length > 0) {
      this.fieldsetsGroup.push({
        fields: this.tmpFields,
        legend: undefined,
        colorVariant: 'none',
        type: 'container',
      });
    }
  }

  get open() {
    return this._open;
  }

  set open(isOpen) {
    this.tmpFields = [];
    this._open = isOpen;
  }
}
