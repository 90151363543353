import { ChangeEvent, type JSX } from 'react';
import Link from 'next/link';
import { Button as GddsButton } from '@geberit/gdds';

// types
import type { BasketItem } from './reducer.types';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Image } from 'components/image/image';
import { Translation } from 'components/Translation/Translation';
import Counter from 'components/Counter/Counter';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTracking } from 'utils/tracking/track';
import { useXy } from 'utils/hooks/use-xy';

interface ShoppingBasketEntryProps {
  count: number;
  countInput: string | number;
  index: number;
  decreaseItem: () => void;
  deleteItem: () => void;
  increaseItem: () => void;
  onInputBlur: () => void;
  setItem: (e: ChangeEvent<HTMLInputElement>) => void;
  article?: BasketItem;
}

function ShoppingBasketEntry({
  deleteItem,
  decreaseItem,
  increaseItem,
  onInputBlur,
  setItem,
  count,
  article,
  countInput,
  index,
}: Readonly<ShoppingBasketEntryProps>) {
  const isDesktop = useIsDesktop();
  const currentLang = useCurrentLanguage();
  const { trackWebshop } = useTracking();
  const { sku, key, description, imageUri, pricePerItem } = article || {};
  const productKey = key ? key.split('_') : null;
  const rowClass = (rowCount: number) => (rowCount > 0 ? '' : 'is--disabled');

  const isXy = useXy();

  /**
   * Add a wrapper if it's mobile || tablet view
   *
   * @param {Node} component
   * @param {String} classes
   */
  const wrapDivMobile = (component: JSX.Element, classes: string) =>
    !isDesktop ? <div className={classes}>{component}</div> : component;

  const imageUriMedium = imageUri?.replace('ProductThumbnail', 'ProductMedium');

  return (
    <div className={`grid-x my-list__row ${rowClass(count)}`}>
      <div className="my-list__image small-6 large-2">
        <Image
          format="1_1"
          src={isDesktop ? imageUri : imageUriMedium}
          alt={key ?? ''}
          title={key ?? ''}
          aspectRatio="1_1"
        />
      </div>

      <div className="my-list__article-number small-12 large-2">
        {key ? (
          <Link
            onClick={handleTracking(index)}
            href={`${productKey?.[0]}_${productKey?.[1]}-${currentLang}`}
          >
            {sku}
          </Link>
        ) : (
          sku
        )}
      </div>

      <div className="my-list__product small-12 large-3">
        <span>{decodingContent(description)}</span>
      </div>

      <div className="my-list__product small-12 large-2">
        <span>
          {pricePerItem && parseFloat(pricePerItem).toFixed(2)}{' '}
          <Translation id="web20_webshop_basket_currency" />
        </span>
      </div>

      {wrapDivMobile(
        <>
          <div className="my-list__action-amount small-6 large-2">
            <Counter
              article={article}
              countInput={countInput}
              decreaseItem={decreaseItem}
              increaseItem={increaseItem}
              onInputBlur={onInputBlur}
              setItem={setItem}
            />
          </div>

          <div className="my-list__action-delete small-12 large-1">
            {isXy
              ? (
                <GddsButton
                  isIcon={isDesktop}
                  stylingType={isDesktop ? "icon" : "iconFlat"}
                  symbol="Trash"
                  onClick={deleteItem}
                  className="my-list__action-delete-gdds-button"
                />
              )
              : (
                <Button
                  symbol="trashcan"
                  {...(!isDesktop ? { background: BUTTON_BACKGROUND.GREY_BLUE } : {})}
                  color={isDesktop ? BUTTON_COLORS.BLACK : BUTTON_COLORS.WHITE}
                  type={isDesktop ? BUTTON_TYPES.PLAIN : undefined}
                  onClick={deleteItem}
                />
              )
            }
          </div>
        </>,
        'my-list__actions small-6',
      )}
    </div>
  );

  function handleTracking(index: number) {
    return () => {
      const price = parseFloat(pricePerItem ?? '0');
      trackWebshop('view_item', {
        value: price,
        items: [
          {
            item_id: sku ?? '',
            item_name: description ?? '',
            quantity: 1,
            price: price,
            index,
          },
        ],
      });
    };
  }
}

export default ShoppingBasketEntry;
