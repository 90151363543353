import { useEffect, useRef, useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useStore } from 'react-redux';

// types
import { ListResult, Material, Media, MediaType, SearchResult } from '../types/mynewsdesk.types';
import {
  ExcutionResult,
  FetchMaterialsResult,
  MaterialsGenerator,
  MaterialsRequestConfig,
  Reducer,
} from '../types/press-portal.types';

// utils
import { sortByDate } from '../utils';
import {
  listGenerator,
  listResultsReducder,
  searchGenerator,
  searchResultsReducder,
} from './helpers';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

function useFetchMaterials<T extends Material | Media>({
  mediaTypes = [],
  mediaType,
  search,
  limit,
  locale,
}: MaterialsRequestConfig): FetchMaterialsResult<T> {
  const buffer = useRef(new Set<T>());
  const state = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadable, setLoadable] = useState<boolean>(false);
  const [materials, setMaterials] = useState<T[] | null>([]);
  const generators = useRef<MaterialsGenerator<ListResult | SearchResult>>([]);
  const iteration = useRef<number>(0);
  const [error, setError] = useState<string | null>(null);
  const lang = useCurrentLanguage();
  const mediaTypesString = mediaTypes.join();

  const consumeGenerators = useCallback(async (reducer: Reducer) => {
    const currentLimit = typeof limit === 'function' ? limit(iteration.current) : limit;
    const resultsPromises = generators.current.map((generator) => generator.next());
    const results = await Promise.all(resultsPromises);
    const result: ExcutionResult = results.reduce(reducer, { done: true, items: [] });
    const allMaterials = [...result.items, ...Array.from(buffer.current)].sort(sortByDate);
    const newMaterials = allMaterials.splice(0, currentLimit);

    // remove consumed materials from the list, if any
    newMaterials.forEach((m) => {
      if (buffer.current.has(m as T)) {
        buffer.current.delete(m as T);
      }
    });

    // put remaining materials into the buffer for later processing
    allMaterials.forEach((m) => {
      buffer.current?.add(m as T);
    });

    iteration.current += 1;
    setMaterials((mat) => (mat ? [...mat, ...(newMaterials as T[])] : (newMaterials as T[])));
    setLoadable(!result.done);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildGenerators = useCallback(
    () => {
      const currentLimit = typeof limit === 'function' ? limit(iteration.current) : limit;

      if (search && typeof search === 'string') {
        generators.current = (mediaType ? [mediaType] : mediaTypes).map(
          (type_of_media: MediaType) =>
            searchGenerator({
              limit: currentLimit,
              query: search,
              type_of_media,
              lang,
              pressroom: locale,
              state,
            }),
        );
      } else {
        generators.current = (mediaType ? [mediaType] : mediaTypes).map(
          (type_of_media: MediaType) =>
            listGenerator({
              limit: currentLimit,
              type_of_media,
              lang,
              locale: locale?.toLowerCase(),
              pressroom: locale,
              state,
            }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, mediaType, mediaTypes, search, lang, state],
  );

  const handleGenerators = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    setError(null);
    try {
      if (search) {
        await consumeGenerators(searchResultsReducder);
      } else {
        await consumeGenerators(listResultsReducder);
      }
    } catch (err: unknown) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        setError(`api error: ${error.message}`);
      } else {
        setError(`internal error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumeGenerators, search, lang]);

  useEffect(() => {
    if (!mediaType && (!mediaTypes || mediaTypes.length === 0)) return;

    buffer.current.clear();
    iteration.current = 0;
    setMaterials(null);
    buildGenerators();
    handleGenerators();
  }, [
    mediaType,
    search,
    lang,
    mediaTypesString,
    locale,
    mediaTypes,
    buildGenerators,
    handleGenerators,
  ]);

  return {
    loading,
    error,
    materials,
    loadMore: handleGenerators,
    loadable: loadable || buffer.current.size > 0,
    total: 0,
  };
}

export { useFetchMaterials };
