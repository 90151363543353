'use client';

// styles
import styles from './content.module.scss';

// types
import type {
  ContentElementPayload,
  ShowroomLocatorTeaserPayload,
} from 'components/ContentElements/content-elements-payload';
import type { HeroImageGddsProps } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';

// components
import Layout from './layout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { ContentArea } from 'components/ContentArea/ContentArea';
import { AnchorBar } from 'components/ContentElementsGdds/anchorbar/anchorbar';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';

// utils
import { hasContentAreas, useContent } from 'components/Page/page-hooks';
import { isEmpty } from 'utils/is-empty';

type LandingProps = {
  hero: HeroImageGddsProps;
  collapseAnchor?: boolean;
  pageAnchorTitle?: string;
  slider: HeroImageGddsProps;
};

export function Landing() {
  const pageContent = useContent<{}, LandingProps>();

  if (!hasContentAreas(pageContent)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: {
      slider,
      intro,
      text,
      headline,
      subHeadline,
      hero,
      showAnchor,
      collapseAnchor,
      pageAnchorTitle,
    },
    contentAreas: { content },
  } = pageContent;

  return (
    <Layout metaData={metaData}>
      {!isEmpty(hero) && <HeroImageGdds {...hero} isSeoConformCarousel />}
      <Breadcrumb items={breadcrumbs} className={styles.breadcrumbs} />
      <HeroImageGdds {...slider} />
      {(headline || subHeadline) && (
        <IntroText
          className={styles.intro}
          title={headline}
          subtitle={subHeadline}
          text={text}
          intro={intro}
          border="disable-border"
          disableInEdit
        />
      )}
      {showAnchor && (
        <AnchorBar
          content={content as Exclude<ContentElementPayload, ShowroomLocatorTeaserPayload>[]}
          collapseAnchor={collapseAnchor}
          pageAnchorTitle={pageAnchorTitle}
        />
      )}
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}
