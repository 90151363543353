import { InnerHtml } from '@geberit/gdds';
import key from 'weak-key';
import striptags from 'striptags';

// styles
import styles from './courseDescription.module.scss';

// components
import { Image } from 'components/image/image';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useCampusEnv } from 'utils/hooks/useCampus';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

interface CourseDescriptionProps {
  description?: string | null;
  tutors?: any[] | null;
  isBookedPage?: boolean;
  courseContent?: any[] | null;
  benefits?: any[] | null;
}

function CourseDescription({
  description = null,
  tutors = null,
  courseContent = null,
  benefits = null,
  isBookedPage = false,
}: Readonly<CourseDescriptionProps>) {
  const { CAMPUS_BASE_URL } = useCampusEnv();
  const translate = useTranslationFunction();

  return (
    <div
      className={classNameBuilder(
        styles.courseDescriptionWrapper,
        isBookedPage && styles.courseDescriptionWrapperNoPad,
      )}
    >
      {description && (
        <InnerHtml as="p" content={striptags(description, ['a', 'b', 'strong', 'i', 'u'])} />
      )}

      {courseContent && (
        <ul>
          {courseContent.map((item) => (
            <InnerHtml key={key(item)} as="li" content={item.content} />
          ))}
        </ul>
      )}
      {!isEmpty(benefits) && (
        <>
          <Headline title={translate('campus_headline_benefits')} tag={Formats.h3} isFlexItem />
          <ul>{benefits?.map((item) => <li key={key(item)}>{item.content}</li>)}</ul>
        </>
      )}

      {!isEmpty(tutors) && (
        <>
          <Headline title={translate('campus_headline_tutors')} tag={Formats.h3} isFlexItem />
          <div className={styles.tutorWrapper}>
            {tutors?.map((tutor) => (
              <div className={styles.tutors} key={key(tutor)}>
                <div className={styles.tutorsImage}>
                  {!isEmpty(tutor.picture) ? (
                    <Image src={`${CAMPUS_BASE_URL}${tutor.picture}`} title="" alt="" />
                  ) : (
                    <Image src="/images/dummy-profile.png" title="" alt="" />
                  )}
                </div>
                <div className={styles.tutorsInner}>
                  <span className={styles.tutorsRole}>{tutor.tutorRole}</span>
                  <span className={styles.tutorsName}>
                    {tutor.firstname} {tutor.lastname}
                  </span>
                  {tutor.tutorsPosition && (
                    <span className={styles.tutorsPosition}>{tutor.tutorsPosition}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default CourseDescription;
