// types
import type { VideocenterChannelProps } from 'components/ContentElementsGdds/video-channel/videocenter-channel';

// components
import VideocenterChannel from './VideocenterChannel';
import GDDSVideocenterChannel from 'components/ContentElementsGdds/video-channel/videocenter-channel';

// utils
import { useNord } from 'utils/hooks/use-nord';
import { useXy } from 'utils/hooks/use-xy';

function VideocenterChannelWrapper(props: Readonly<VideocenterChannelProps>) {
  const isNord = useNord();
  const isXy = useXy();

  if (isXy || isNord) {
    return <GDDSVideocenterChannel {...props} />;
  }

  return <VideocenterChannel {...props} />;
}

export default VideocenterChannelWrapper;
