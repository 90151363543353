import { useState } from 'react';
import { Formik } from 'formik';
import { useRouter } from 'next/navigation';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

// types
import type { FormControllerProps } from './nord/types';

// components
import { FormSwitch } from './form-switch';
import { validation as ValidationMapping } from './utils/validation';
import { FormGcaptchaProvider } from './form-gcaptcha-provider';
import { FormContextProvider } from './form-context-provider';

// utils
import {
  INVALID_FIELD_TYPES,
  PRE_CON_INIT_VALUE_MAPPING,
  PRE_CON_VARIABLE_NAMES,
  TRACKING_SUBMIT_NAME,
} from './utils/mappings';
import { getInitValues } from './utils/initialValues';
import { getResponse } from './actions';
import { captchaKeySelector } from 'utils/selectors/globalsSelectors';
import { sha256 } from 'utils/sha256';
import { useTracking } from 'utils/hooks/useTracking';
import { formAction, formModuleAction } from './trackingActions';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useGetEndpoint } from 'utils/hooks/use-endpoint';
import { Log } from 'services/log';
import { useSectionId } from 'utils/hooks/use-section-id';
import { isEmpty } from 'utils/is-empty';
import { normalizeFilename } from 'utils/normalize-filename';

const FILE_UPLOAD_SIZE = 25;

export function FormController({
  pageId,
  formId,
  fieldsets,
  target,
  orderInformation,
  formType,
  preconditions,
  handleSubmit,
  getSessionStorage,
  uploadToBlobStorage,
  afterSubmit,
  contentIndex,
  oxomiSubmit,
  processors,
  onFormStatusChange,
  isModal,
  border,
  title,
  subtitle,
  text,
  hasOxomiButton = false,
  oxomiButtonClick,
}: Readonly<FormControllerProps>) {
  const track = useTracking();
  const router = useRouter();
  const translate = useTranslationFunction();
  const captchaKey = useSelector(captchaKeySelector);

  const [filesErrorMessage, setFilesErrorMessage] = useState<string | undefined>();
  const [files, setFiles] = useState<Record<string, Field[]>>({});
  const [fileSize, setFileSize] = useState<number | undefined>();
  const fields = fieldsets?.fields;
  const { getEndpoint } = useGetEndpoint();
  const [preConNames, setPreConNames] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const sectionId = useSectionId('', '', false, contentIndex);

  const hasFileUpload = fields?.some((field) => field.type === 'file');
  const captchaField = fields?.find((field) => field.type === 'form_captcha');

  /**
   * Handle submit if there is still a file upload error
   *
   * @param values
   * @param param1
   */
  const onFileError = async (_, { setSubmitting }) => {
    const uploadFields = document.querySelectorAll('.fileUpload');
    if (uploadFields) {
      const firstUploadField = uploadFields[0].firstChild as HTMLElement;
      if (firstUploadField?.className) firstUploadField.className += ' form-item--error';
    }
    setUploadProgress(0);
    setSubmitting(false);
  };

  /**
   * Handle required file upload error message
   * default yup can't be used because we can't set the value on our own
   *
   * @param message
   */
  const handleFileValidation = (message) => {
    setFilesErrorMessage(message);
  };

  /**
   * Add a file to state
   */
  const processAddFile = (targetFile: File, targetName, targetType, maxFileCount) => {
    const hasFileKey = !isEmpty(files) && Object.keys(files).find((key) => key === targetName);

    const sizeInMB = getFileSize(targetFile);
    const currentFileSize = fileSize ? fileSize + sizeInMB : sizeInMB;
    const validFileSize = currentFileSize < FILE_UPLOAD_SIZE;
    const validType = !INVALID_FIELD_TYPES.includes(targetFile.type);

    if (!validFileSize || !validType) {
      handleFileValidation(
        !validFileSize
          ? translate('web20_forms_invalid_filesize')
          : translate('web20_forms_invalid_filetype'),
      );
    } else if (
      validFileSize &&
      validType &&
      ((targetType === 'file' && !hasFileKey) ||
        (hasFileKey && files[targetName].length < maxFileCount))
    ) {
      const setNewFiles = hasFileKey ? [...files[targetName], targetFile] : [targetFile];

      setFilesErrorMessage(undefined);
      setFileSize(currentFileSize);
      setFiles({
        ...files,
        [targetName]: setNewFiles,
      });
    }
  };
  const addFile = (
    e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
    maxFileCount: number,
  ) => {
    const { currentTarget } = e;
    const { name: targetName, files: targetFiles, type: targetType } = currentTarget;

    if (targetFiles?.[0]) {
      const file = targetFiles[0];
      const normalizedFile = new File([file], normalizeFilename(file.name), {
        type: file.type,
      });
      processAddFile(normalizedFile, targetName, targetType, maxFileCount);
    }
  };

  /**
   * Delete a file from state
   */
  const deleteFile = (fieldName, errorMessage, index) => {
    const newFiles = files[fieldName].filter((file, i) => i !== index);

    setFiles({
      ...files,
      [fieldName]: newFiles,
    });

    setFileSize(
      newFiles.length > 0 ? newFiles.reduce((sum, file) => sum + getFileSize(file), 0) : undefined,
    );

    if (isEmpty(newFiles)) handleFileValidation(errorMessage);
  };

  const getFileSize = (file) => parseInt(file.size, 10) / 1000000;

  /**
   * generate new validation object to fit yup object requirement
   */
  const createValidationObject = () => {
    // filter and delete empty rules
    const rules = fields?.filter((field) => !isEmpty(field.rules));
    const preConDropdownField = fields?.find((field) => field.type === 'dropdown_models');

    if (isEmpty(rules) && isEmpty(preConDropdownField)) {
      return {};
    }

    // create yup validation object
    let schema = {};
    let dropdownNames: string[] = [];

    // Form validation
    rules
      ?.filter((field) => field.type !== 'dropdown_models')
      .forEach((field) => {
        const validationRule = ValidationMapping(field.rules);
        if (validationRule) {
          schema[validationRule.name] = validationRule.rule;
        }
      });

    if (preConDropdownField && !isEmpty(preConDropdownField)) {
      let preConSchema = {};
      const errorMessage =
        (!isEmpty(preConDropdownField?.rules) && preConDropdownField?.rules[0]?.errorMessage) ||
        'keepErrorMessage';

      PRE_CON_VARIABLE_NAMES.forEach((key, i) => {
        const name = preConDropdownField[key] || PRE_CON_INIT_VALUE_MAPPING[i];
        dropdownNames.push(name);

        preConSchema = {
          ...preConSchema,
          [name]: yup
            .string()
            .ensure()
            .test('required', errorMessage, (value) => {
              return !isEmpty(value);
            }),
        };
      });

      schema = {
        ...schema,
        ...preConSchema,
      };
    }

    if (isEmpty(preConNames) && !isEmpty(dropdownNames)) {
      setPreConNames(dropdownNames);
    }
    return schema;
  };

  /**
   * Set alert and scroll to top when an error appears after submitting form
   */
  const handleSubmitError = (setStatus) => {
    if (window.location) {
      track.trackEvent(formAction('Submit error', window.location.href));
    }

    setStatus({ messageIcon: 'alert' });
    window.scrollTo(0, 0);
  };

  const trackAfterSubmit = (messageFormType) => {
    if (!messageFormType) {
      // check whether form is crm module
      const crmModule = processors.some(
        (processor) =>
          processor.processorClass && processor.processorClass.indexOf('CrmProcessor') > -1,
      );

      track.trackEvent(
        formAction(`Submit success${crmModule ? ' - CRM Form' : ''}`, window.location.href),
      );
    }
  };

  const afterFormSubmit =
    ({ newValues, isDownloadCenter, setStatus, setSubmitting }) =>
    async (response) => {
      if (isEmpty(response) || !isEmpty(response.errors)) {
        // show error message
        setUploadProgress(0);
        handleSubmitError(setStatus);
        setSubmitting(false);
        return;
      }

      const messageFormType = formType === 'leadmodule' || (formType === 'oxomi' && !target);
      trackAfterSubmit(messageFormType);

      const capiEvent: { event: string; user_em?: string } = {
        event: 'lead_form_submit',
      };

      const emailField = fields?.find((field) => field.fieldType === 'form_field_email');
      if (emailField && newValues[emailField.name]) {
        capiEvent.user_em = await sha256(newValues[emailField.name]);
      }

      track.trackEvent(capiEvent);

      if (isDownloadCenter) {
        // clear selected items after submit
        window.sessionStorage.setItem('orders', JSON.stringify([]));
        setStatus({ messageIcon: 'success' });
        getSessionStorage();
        setSubmitting(false);
      } else if (messageFormType) {
        track.trackEvent(formModuleAction(formId, TRACKING_SUBMIT_NAME[formType]));
        setStatus({ messageIcon: 'success' });
        if (oxomiSubmit) oxomiSubmit();
        setSubmitting(false);
      } else {
        // redirect to new page after success
        router.push(target ?? '');
      }
    };

  /**
   * Handle Submit
   */
  const onSubmit = async (values, { setStatus, setSubmitting }) => {
    let newValues = { ...values };

    if (typeof handleSubmit === 'function') {
      return handleSubmit(values);
    }

    const isDownloadCenter = formType === 'downloadCenter';
    const hasOrderInfo = isDownloadCenter || formType === 'oxomi';

    if (hasOrderInfo) {
      // Update order Information
      newValues = {
        ...values,
        'order-information': orderInformation,
      };
    }

    const deleteForRequest: string[] = [];

    if (fields?.some((x) => x.fieldType === 'form_field_checkbox_crm')) {
      newValues.checkboxes = [];
    }

    fields?.forEach(({ fieldType, name, checkedValue }) => {
      if (fieldType === 'form_field_checkbox_group') {
        newValues[name].forEach((n) => {
          newValues[n] = checkedValue;
        });
      }
      if (fieldType === 'form_field_checkbox_crm') {
        deleteForRequest.push(name);
      }
      if (
        fieldType === 'form_field_checkbox_crm' &&
        typeof values[name] !== 'undefined' &&
        values[name].length > 0
      ) {
        newValues.checkboxes.push(name);
      }
    });

    const captchaField = fields?.find((field) => field.type === 'form_captcha');
    if (captchaField && captchaKey && typeof window.grecaptcha?.execute === 'function') {
      const newToken = await window.grecaptcha.execute(captchaKey);
      newValues['g-recaptcha-response'] = newToken;
    }

    // This will omit the field from the API request
    // As requested by https://geberit.visualstudio.com/Web%20Platform/_workitems/edit/479102
    // Identifier is hardcoded in the FS template form_consent_output
    delete newValues['virtual_consent_form_field_checkbox_group'];

    getResponse({
      pageId,
      formId,
      values: newValues,
      files,
      deleteForRequest,
      uploadToBlobStorage,
      setUploadProgress,
      getEndpoint,
    })
      .then(afterFormSubmit({ newValues, isDownloadCenter, setStatus, setSubmitting }))
      .catch((err) => {
        setUploadProgress(0);
        setSubmitting(false);
        handleSubmitError(setStatus);
        Log.error('Form submission failed!', err);
      });
  };

  if (isEmpty(fields)) {
    return null;
  }

  // Create yup validation schema
  const validationSchema = yup.object().shape(createValidationObject());

  // Create initial values
  const initialValues = getInitValues(fields);

  return (
    <FormGcaptchaProvider fieldsets={fieldsets} captchaKey={captchaKey}>
      <Formik
        initialValues={initialValues}
        onSubmit={!filesErrorMessage ? onSubmit : onFileError}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <FormContextProvider
            formType={formType}
            preConNames={preConNames}
            onStatusChange={onFormStatusChange}
            status={formikProps.status}
            setStatus={formikProps.setStatus}
            uploadProgress={uploadProgress}
            files={files}
            filesErrorMessage={filesErrorMessage}
            addFile={addFile}
            deleteFile={deleteFile}
            preconditions={preconditions?.conditions ?? []}
            hasOxomiButton={hasOxomiButton}
            oxomiButtonClick={oxomiButtonClick}
            hasFileUpload={hasFileUpload}
            captchaField={captchaField}
            setFilesErrorMessage={setFilesErrorMessage}
          >
            <FormSwitch
              fields={fields}
              afterSubmit={afterSubmit}
              sectionId={sectionId}
              hasInvalidFile={!isEmpty(filesErrorMessage)}
              isModal={isModal}
              border={border}
              title={title}
              subtitle={subtitle}
              text={text}
            />
          </FormContextProvider>
        )}
      </Formik>
    </FormGcaptchaProvider>
  );
}
