import React, { useState } from 'react';

// styles
import styles from './coursebookedcontent.module.scss';

// components
import { Loader } from 'components/Loader/Loader';
import CourseDescription from '../CourseDescription/CourseDescription';
import { CourseModule } from './CourseModule';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useSelfUser } from 'utils/hooks/useCampus';
import { useTranslationFunction } from 'utils/hooks/use-translations';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

const STATE_MAP = {
  STARTED: 1,
  WAITING: 0,
  PASSED: 100,
};

interface CourseBookedContentProps {
  courseId: string | number;
  language: string;
  data: any;
  children: React.ReactNode;
  description?: string;
  tutors?: any[] | null;
  courseContents?: any[] | null;
  courseBenefits?: any[] | null;
  participantId?: string;
  subitems?: any[] | null;
  subitemsLoading?: boolean;
  progress?: number;
}

export default function CourseBookedContent({
  courseId,
  language,
  data,
  description = '',
  tutors = null,
  courseContents = null,
  courseBenefits = null,
  subitems = null,
  subitemsLoading = true,
  participantId,
  progress,
  children,
}: Readonly<CourseBookedContentProps>) {
  const { selfData } = useSelfUser(courseId);
  const isSelfBooked = String(selfData?.id) === String(participantId);
  const [tab, setTab] = useState(0);
  const translate = useTranslationFunction();
  const myCourse = translate('campus_headline_mycourse');
  const descriptionLabel = translate('campus_course_description');

  if (!description && !subitems?.length) return null;

  return (
    <div className={styles.wrapper}>
      {isSelfBooked && (
        <div className={styles.tabWrapper}>
          <button
            type="button"
            className={tab === 0 ? styles.tabActive : ''}
            onClick={() => setTab(0)}
          >
            {myCourse}
          </button>
          <button
            type="button"
            className={tab === 1 ? styles.tabActive : ''}
            onClick={() => setTab(1)}
          >
            {descriptionLabel}
          </button>
        </div>
      )}
      {tab === 0 && isSelfBooked ? (
        <div className="information">
          <Headline
            title={myCourse}
            tag={Formats.h2}
            className={styles.headlineMyCourse}
            isFlexItem
          />
          {!subitemsLoading && subitems?.length > 0 && (
            <Headline
              title={translate('campus_headline_status')}
              tag={Formats.h4}
              className={styles.headlineStatus}
              isFlexItem
            />
          )}
          {subitemsLoading ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            subitems && (
              <ul className={styles.moduleList}>
                {subitems.map((item, i) => (
                  <CourseModule
                    key={item.media?.id}
                    courseId={courseId}
                    subItemId={item.media?.id}
                    progress={progress === 100 ? 100 : STATE_MAP[item.status] || 0}
                    forceStartable={
                      (item.status === 'WAITING' && i === 0) ||
                      (i > 0 && subitems[i - 1].status === 'PASSED')
                    }
                    title={item.media.name}
                    language={language}
                    courseData={data}
                  />
                ))}
              </ul>
            )
          )}
          {children}
        </div>
      ) : (
        <div className="description">
          {description && (
            <>
              <Headline
                title={descriptionLabel}
                tag={Formats.h2}
                className={styles.headlineStatus}
                isFlexItem
              />
              <CourseDescription
                tutors={tutors}
                description={description}
                isBookedPage
                courseContent={courseContents}
                benefits={courseBenefits}
              />
              {children}
            </>
          )}
        </div>
      )}
    </div>
  );
}
