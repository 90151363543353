import { useSelector } from 'react-redux';

// Styles
import styles from './OrderContents.module.scss';

// Components
import CartTable from 'components/Checkout/Components/CartTable/CartTable';
import { Voucher, VoucherProps } from 'components/Checkout/Components/Voucher/Voucher';
import { Translation } from 'components/Translation/Translation';
import { transformPrice } from 'components/Checkout/util/helperFunctions';
import { SimulatedOrderData } from 'components/Checkout/action.types';

// Utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { webshopShippingInfoSelector } from 'utils/selectors/globalsSelectors';

interface OrderContentsProps {
  voucher: VoucherProps['voucher'];
  isSummaryPage?: boolean;
  orderData: Partial<SimulatedOrderData>;
}

const OrderContents: React.FC<OrderContentsProps> = ({ voucher, isSummaryPage, orderData }) => {
  const showShippingInfo = useSelector(webshopShippingInfoSelector);
  const translate = useTranslationFunction();

  return (
    <div className={classNameBuilder('cell grid-x grid-margin-x')}>
      <div className="cell large-8 medium-12 small-12">
        <CartTable data={orderData?.lineItemsList ?? []} />
      </div>

      <div
        className={classNameBuilder('cell large-4 medium-12 small-12 grid-y', styles.cartFooter)}
      >
        <Voucher voucher={voucher} displayOnly={!!isSummaryPage} />
        <div
          className={classNameBuilder(
            'cell grid-x',
            styles.cartFooterText,
            styles.voucherSeparatorBox,
          )}
        >
          <div className="cell">
            <div className={styles.voucherSeparator}></div>
          </div>
        </div>

        <div className={classNameBuilder('cell grid-x', styles.cartFooterText)}>
          <div className={classNameBuilder('cell auto', styles.textAlignRight)}>
            <Translation
              id="web20_checkout_overview_basket_footer_total_price"
              placeholder={{
                number: orderData?.lineItemsList ? String(orderData.quantityAllItems || 0) : 'all',
              }}
            />
          </div>
          <div className={classNameBuilder('cell large-4 medium-3 small-4', styles.textAlignRight)}>
            {transformPrice(Number(orderData.subtotalPriceVatExcluding))}{' '}
            <Translation id="web20_checkout_country_default_currency" />
          </div>
        </div>

        {/* Discount Amount */}
        {(voucher.areItemsDiscounted ||
          (isSummaryPage && Number(orderData?.priceVoucher) < 0 && orderData.voucherValid)) && (
          // priceVoucher is negative (e.g. -1.28000000)
          <div
            className={classNameBuilder(
              'cell grid-x',
              styles.cartFooterText,
              styles.voucherCartLine,
            )}
          >
            <div className={classNameBuilder('cell auto', styles.textAlignRight)}>
              <Translation id="web20_checkout_overview_basket_footer_total_discount" />
            </div>
            <div
              className={classNameBuilder('cell large-4 medium-3 small-4', styles.textAlignRight)}
            >
              {transformPrice(orderData.priceVoucher)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
            </div>
          </div>
        )}

        <div className={classNameBuilder('cell grid-x', styles.cartFooterText)}>
          <div className={classNameBuilder('cell auto', styles.textAlignRight)}>
            <div>
              <Translation id="web20_checkout_overview_basket_footer_postage" />
            </div>
            {showShippingInfo && (
              <div>
                <Translation id="web20_webshop_shipping_info" />
              </div>
            )}
          </div>
          <div className={classNameBuilder('cell large-4 medium-3 small-4', styles.textAlignRight)}>
            {transformPrice(orderData.postalCostVatIncluding)}{' '}
            <Translation id="web20_checkout_country_default_currency" />
          </div>
        </div>
        <div className={classNameBuilder('cell grid-x', styles.cartFooterText)}>
          <div className={classNameBuilder('cell auto', styles.textAlignRight)}>
            <Translation
              id="web20_checkout_overview_basket_footer_vat"
              placeholder={{
                percents: translate('web20_checkout_country_based_vat_percentige_amount'),
              }}
            />
          </div>
          <div className={classNameBuilder('cell large-4 medium-3 small-4', styles.textAlignRight)}>
            {transformPrice(orderData.vatAmount)}{' '}
            <Translation id="web20_checkout_country_default_currency" />
          </div>
        </div>
        {transformPrice(orderData.roundingDifference) !== '0.00' && (
          <div className={classNameBuilder('cell grid-x', styles.cartFooterText)}>
            <div className={classNameBuilder('cell auto', styles.textAlignRight)}>
              <Translation id="web20_checkout_overview_basket_footer_rounding_discount" />:
            </div>
            <div
              className={classNameBuilder('cell large-4 medium-3 small-4', styles.textAlignRight)}
            >
              {transformPrice(orderData.roundingDifference)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
            </div>
          </div>
        )}
        <div className={classNameBuilder('cell grid-x', styles.cartFooterText)}>
          <div className="cell">
            <hr className={styles.cartOverviewDevider} />
          </div>
        </div>
        <div className="cell grid-x">
          <div className={classNameBuilder('cell auto', styles.textAlignRight, 'bold')}>
            <Translation id="web20_checkout_address_basket_table_headings_price" />:
          </div>
          <div
            className={classNameBuilder(
              'cell large-4 medium-3 small-4',
              styles.textAlignRight,
              'bold',
            )}
          >
            {transformPrice(orderData.totalPriceVatIncluding)}{' '}
            <Translation id="web20_checkout_country_default_currency" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderContents;
