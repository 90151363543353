import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import PreConDropdown from './precon-dropdown';

// utils
import { fetchPreConData } from 'components/ContentElements/Form/actions';
import { isEmpty } from 'utils/is-empty';
import { preConDropdownSelector } from 'utils/selectors/formSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

export type PreconDropdownContainerField = {
  label: string;
  disabled?: boolean;
  labelColor?: string;
  labelPanel?: string;
  required?: boolean;
  rules: Rule[];
};

interface PreconDropdownContainerProps {
  field: PreconDropdownContainerField;
}

export default function PreconDropdownContainer({ field }: Readonly<PreconDropdownContainerProps>) {
  const preConData = useSelector(preConDropdownSelector);
  const translate = useTranslationFunction();
  const dispatch = useDispatch();

  const defaultDropdownValue = [
    translate('web20_form_default_dropdown_product_model'),
    translate('web20_form_default_dropdown_color_model'),
    translate('web20_form_default_dropdown_panel_model'),
  ];

  useEffect(() => {
    dispatch(fetchPreConData());
  }, [dispatch]);

  if (isEmpty(preConData)) return null;

  return (
    <div className="grid-x grid-margin-x">
      <PreConDropdown
        options={preConData}
        iteration={0}
        disabled={field.disabled ?? false}
        field={field}
        defaultDropdownValue={defaultDropdownValue}
      />
    </div>
  );
}
