// types
'use client';

// components
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import RelatedLinks from 'components/RelatedLinks/RelatedLinks';
import SocialShare from 'components/SocialShare/SocialShare';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import HeroImageC from 'components/ContentElementsGdds/hero-image/hero-image-c';

// utils
import { TOC_DATA_CONTENTPAGE } from 'components/TableOfContents/tocDataAdapter';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { isEmpty } from 'utils/is-empty';
import { contentExists, useContent } from 'components/Page/page-hooks';
import { useShowSocialShare } from 'utils/hooks/use-show-social-share';

export function Frameless() {
  const content = useContent<{}, { heroTileItems: SliderItemProps[] }>();
  const showSocialShare = useShowSocialShare();

  if (!contentExists(content) || !content.page) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: { subHeadline, headline, heroTileItems, showAnchor },
    contentAreas,
    relatedLinks,
  } = content;
  const hasLocatorSection =
    !isEmpty(contentAreas.content) && contentAreas.content[0].type === 'locator';

  return (
    <main>
      {heroTileItems?.some((item) => item.type === 'hero_c_item') ? (
        <HeroImageC {...(heroTileItems[0] as SliderItemProps)} />
      ) : (
        <HeroImageGdds items={heroTileItems} />
      )}
      <Breadcrumb items={breadcrumbs} />
      <PageHeadline Format={TitleFormats.h1} title={headline} subtitle={subHeadline} pageHeadline />

      {showAnchor && !hasLocatorSection && (
        <TableOfContents
          type={TOC_DATA_CONTENTPAGE}
          headline={headline}
          content={contentAreas.content}
        />
      )}

      <ContentArea content={contentAreas.content} contentType="content" />
      {showSocialShare ? (
        <section className="grid-container">
          <SocialShare contentType="page" metaData={metaData} useInContentPage />
        </section>
      ) : null}
      <RelatedLinks
        title={relatedLinks.title}
        anchor={relatedLinks.anchor}
        categories={relatedLinks.categories}
      />
    </main>
  );
}
