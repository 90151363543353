import { Datepicker as GDDSDatePicker, TimePicker as GDDSTimePicker } from '@geberit/gdds';
import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash.isempty';
import { isMatch, parse } from 'date-fns';

// styles
import styles from 'components/ContentElements/Form/form.module.scss';

// types
import type { DateOrTimePickerField } from './date-or-time-picker';

// utils
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useLocale } from 'utils/use-locale';

interface DateOrTimePickerGddsProps {
  field: DateOrTimePickerField;
}

export default function DateOrTimePickerGdds({
  field: { name, placeholder, label, rules, type, readonly, disabled },
}: Readonly<DateOrTimePickerGddsProps>) {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [timeValidationError, setTimeValidationError] = useState<string | null>(null);
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<Record<string, string>>();
  const dateformat = useDateFormat();
  const lang = useCurrentLanguage();
  const translate = useTranslationFunction();

  const mappedLocale = useLocale(lang);
  const required =
    !isEmpty(rules) && rules.some((attribute) => attribute.formValidator.includes('NotEmpty'));
  const hasErrors = !isEmpty(errors[name]) && touched?.[name];

  const cachedTime = useMemo(
    () => (values[name] ? new Date(`2020-01-01 ${values[name]}`) : undefined),
    [name, values],
  );

  const isTimeStringValid = (value, format = '24') => {
    const nextValue = value.replace(/:/g, '');
    let timeFormat = 'hhmm';

    if (format === '24') {
      timeFormat = 'HHmm';
    }

    return isMatch(nextValue, timeFormat) || isMatch(nextValue, `${timeFormat}ss`);
  };

  return (
    <div className={hasErrors ? `form-item--error ${styles.hasError}` : ''}>
      {type !== 'datepicker' ? (
        <div className={styles.gddsTimepicker}>
          <GDDSTimePicker
            required={required}
            error={timeValidationError || (touched?.[name] && errors[name]) || undefined}
            nowLabel={translate('web20_datepicker_now') || 'Now'}
            label={label}
            disabled={disabled}
            readonly={readonly}
            submitButtonLabel={translate('web20_datepicker_ok') || 'ok'}
            name={name}
            onReset={() => {
              setTimeValidationError(null);
              setFieldValue(name, '');
            }}
            format="24"
            placeholder={placeholder}
            time={cachedTime}
            onValueChange={({ timeString }) => {
              if (isTimeStringValid(timeString)) {
                setFieldTouched(name, true);
                setFieldValue(name, timeString);
                setTimeValidationError(null);
              } else if (timeString) {
                setTimeValidationError(translate('campus_form_invalid'));
              }
            }}
          />
        </div>
      ) : (
        <div className={styles.gddsDatepicker}>
          <GDDSDatePicker
            dateFormat={dateformat}
            required={required}
            dateError={touched?.[name] ? (errors[name] as string) : undefined}
            name={name}
            label={label}
            disabled={disabled}
            readonly={readonly}
            selectedDates={selectedTime ? [selectedTime] : undefined}
            showOutsideDays
            weekStartsOn={0}
            appointments={[]}
            monthNames={
              mappedLocale.localize
                ? Array(12)
                    .fill(undefined)
                    .map((_, index) => mappedLocale.localize?.month(index))
                : undefined
            }
            weekdayNamesShort={
              mappedLocale?.localize
                ? Array(7)
                    .fill(undefined)
                    .map((_, index) => mappedLocale.localize?.day(index, { width: 'short' }))
                : undefined
            }
            placeholder={placeholder}
            onDateChange={(value) => {
              setFieldTouched(name, true);
              setFieldValue(name, value);
              setSelectedTime(value ? parse(value, dateformat, new Date()) : null);
            }}
          />
        </div>
      )}
    </div>
  );
}
