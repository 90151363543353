import React from 'react';

// styles
import { StyledContentBox } from './ContentBox.styles';

// types
import type { ContentBoxProps } from './ContentBox.types';

// components
import { TextBoxButton } from '../TextBoxButton';

// utils
import { exhaustiveGuard } from 'utils/exhaustiveGuard';
import { useThemeName } from 'utils/hooks/use-theme';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';

function ContentBox(props: ContentBoxProps) {
  const { heading, subheading } = props;
  // this should be used for conditionally rendering the contentbox;
  // edge case: if the headline has only new line tags, then this will
  // evaluate to empty string and the condition for contentbox will work;
  const _textOnlyHeading = decodingContent(heading, true);

  const themeName = useThemeName();

  if (!_textOnlyHeading && !subheading) return null;

  switch (themeName) {
    case 'DEFAULT':
      return (
        <StyledContentBox.Default {...props} className="content-box content-box--xy">
          <Content {...props} />
        </StyledContentBox.Default>
      );
    case 'GDDS':
    case 'XY':
      return (
        <StyledContentBox.Gdds {...props} className="content-box content-box--group">
          <Content {...props} />
        </StyledContentBox.Gdds>
      );
    case 'NORD':
      return (
        <StyledContentBox.Nord {...props} className="content-box content-box--nord">
          <Content {...props} />
        </StyledContentBox.Nord>
      );
    case 'KOLO':
      return (
        <StyledContentBox.Kolo {...props} className="content-box content-box--kolo">
          <Content {...props} />
        </StyledContentBox.Kolo>
      );
    case 'TWYFORD':
      return (
        <StyledContentBox.Twyford {...props} className="content-box content-box--twyford">
          <Content {...props} />
        </StyledContentBox.Twyford>
      );
    default:
      return exhaustiveGuard(themeName);
  }
}

function Content(props: ContentBoxProps) {
  const { button, color, hasPreviewId, heading, subheading, emphasize, tag } = props;
  const Tag = tag ? tag : props.isSection ? 'h2' : 'span';

  return (
    <>
      <Tag>
        {heading && (
          <span
            className={classNameBuilder(
              'content-box__heading',
              emphasize === 'title' && 'content-box__heading--emphasized',
            )}
            data-preview-id={hasPreviewId ? '#st_headline' : undefined}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}

        {!isEmpty(heading) && !isEmpty(subheading) && ' '}
        {subheading && (
          <span
            className={classNameBuilder(
              'content-box__subheading',
              emphasize === 'subtitle' && 'content-box__subheading--emphasized',
            )}
            data-preview-id={hasPreviewId ? '#st_subline' : undefined}
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        )}
      </Tag>

      {button && <TextBoxButton color={color} button={button} className="content-box__button" />}
    </>
  );
}

export { ContentBox };
