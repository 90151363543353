'use client';

import { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from '@geberit/gdds';
import { usePathname } from 'next/navigation';
import isEqual from 'lodash.isequal';

// styles
import styles from './insightsDetail.module.scss';

// types
import type { ItemProps } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';

// components
import Layout from './Layout';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import GDDSSocialShare from 'components/ContentElementsGdds/social-share/gdds-social-share';

// utils
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';
import { validateDate } from 'utils/validateDate';
import { decodingContent } from 'utils/decodingContent';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useThemeName } from 'utils/hooks/use-theme';
import { useLocale } from 'utils/use-locale';
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

type InsightsHeroItem = Pick<ItemProps, 'previewId' | 'headline'> &
  Partial<Omit<ItemProps, 'previewId' | 'headline'>>;

export function InsightsDetail() {
  const pageContent = useContent<
    {},
    {
      heroImage: string;
      heroImageObject: ImageObjectMobile;
      heroImageObjectMobile: ImageObjectMobile;
      slider: HeroImageGddsProps;
    }
  >();

  const metaData = pageContent?.metaData;
  const breadcrumbs = pageContent?.breadcrumbs;
  const content = pageContent?.contentAreas;

  const headline = pageContent?.page?.headline;
  const subline = pageContent?.page?.subline;
  const emphasize = pageContent?.page?.emphasize;
  const topic = pageContent?.page?.topic;
  const date = pageContent?.page?.date;

  const heroImage = pageContent?.page?.heroImage;
  const heroImageObject = pageContent?.page?.heroImageObject;
  const heroImageObjectMobile = pageContent?.page?.heroImageObjectMobile;
  const pageRefPreviewId = pageContent?.pageRefPreviewId;

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const lang = useCurrentLanguage();
  const locale = useLocale(lang);
  const isPreview = useIsPreview();
  const [currentImageObject, setCurrentImageObject] = useState<{
    heroImage?: string;
    imageObject?: ImageObjectMobile;
  }>({ heroImage: undefined, imageObject: undefined });
  const themeName = useThemeName();
  const imageObject = isMobile ? heroImageObjectMobile : heroImageObject;
  const pathname = usePathname();

  useEffect(() => {
    if (isPreview && pageRefPreviewId && 'TPP_SNAP' in window) {
      TPP_SNAP.setPreviewElement(pageRefPreviewId);
    }
  }, [isPreview, pageRefPreviewId]);

  useLayoutEffect(() => {
    setCurrentImageObject({ heroImage: undefined, imageObject: undefined });
  }, [pathname]);

  useEffect(() => {
    if (!isEqual({ imageObject, heroImage }, currentImageObject)) {
      setCurrentImageObject({ heroImage, imageObject });
    }
  }, [imageObject, currentImageObject, heroImage]);

  const shouldDisplayHeroImage =
    (pageContent?.page?.heroType === 'picture' && !!heroImage) ||
    (pageContent?.page?.heroType === 'bgvideo' && !!pageContent?.page?.video);

  const heroImageItems: InsightsHeroItem[] = [
    {
      previewId: pageContent.previewId,
      headline: '',
      ...(pageContent?.page?.heroType && { heroType: pageContent?.page?.heroType }),
      ...(heroImage && { image: heroImage }),
      ...(heroImageObject && { imageObject: heroImageObject }),
      ...(heroImageObjectMobile && { imageObjectMobile: heroImageObjectMobile as ImageObject }),
      ...(pageContent?.page?.loopType && { loopType: pageContent?.page?.loopType }),
      ...(pageContent?.page?.imageAltText && { pictureAlt: pageContent?.page?.imageAltText }),
      ...(pageContent?.page?.heroType === 'bgvideo' &&
        pageContent?.page?.video && {
          video: pageContent?.page?.video,
          videoType: 'bgvideo',
        }),
    },
  ];

  const isTypeA =
    heroImageItems.length === 1 || !!heroImageItems.find((item) => item.heroType === 'bgvideo');

  if (!hasContentAreas(pageContent)) {
    return null;
  }

  return (
    <Layout className={styles.insightsDetails} metaData={metaData}>
      {/* hero slider */}
      {shouldDisplayHeroImage && (
        <HeroImageGdds
          wideLayout
          hideScrollIndicator
          disableAnimation={isTypeA}
          disableSliderControls={!isTypeA}
          items={heroImageItems}
        />
      )}

      {/* breadcrumb and social icons */}
      {!isDesktop && <Breadcrumb items={breadcrumbs} />}
      <Container maxContentWidth="78rem">
        <Row>
          {isDesktop && (
            <Col size={[4, 8, 7]}>
              <Breadcrumb items={breadcrumbs} />
            </Col>
          )}
          <Col size={[4, 8, 5]}>
            <GDDSSocialShare
              metaData={metaData}
              hasCopyLink
              types={['facebook', 'twitter', 'linkedin', 'service-mail']}
            />
          </Col>
        </Row>
      </Container>

      {/* topic and headline */}
      <Container maxContentWidth="52.5rem">
        {topic && (
          <Row>
            <Col className={'topic'}>
              {`${decodingContent(topic)} - ${validateDate(
                new Date(date ?? ''),
                'MMMM yyyy',
                locale,
              )}`}
            </Col>
          </Row>
        )}
        {(headline || subline) && (
          <Row>
            <Col>
              <Headline
                format={Formats.h1}
                title={headline}
                subtitle={subline}
                titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h3)}
                subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h3)}
                className={'headline'}
              />
            </Col>
          </Row>
        )}
      </Container>

      {/* sections */}
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}
