import { useEffect, useState } from 'react';
import { HeroImage } from '@geberit/gdds';
import { useInView } from 'react-intersection-observer';

// styled
import { StyledHeroImageThemedWrapper, Title } from './hero-image-themed.styles';

// styles
import styles from './hero-image-themed.module.scss';

// types
import type { HeroImageThemedProps } from './hero-image-themed.types';
import type { ThemeType } from '@geberit/gdds';

// components
import { HeroImageThemedDefault } from './hero-image-themed-default';

// utils
import { useThemeName } from 'utils/hooks/use-theme';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getIdsOfEmptyContentBoxes } from './hero-image-themed.utils';
import { useContent } from 'components/Page/page-hooks';
import { useTheme } from 'styled-components';

const DELAY_DEFAULT = 6;
const DELAY_INFINITY = 99999;

function HeroImageThemed(props: HeroImageThemedProps) {
  const {
    contentBoxPosition,
    isSeoConformCarousel,
    items,
    isSection,
    hideScrollIndicator,
    disableAnimation,
    disableSliderControls,
    wideLayout,
    previewId,
    autoplay = true,
    className,
    titleTag,
  } = props;
  const { ref: refSlider, inView } = useInView({
    threshold: 0,
  });

  const { page } = useContent();
  const theme = useTheme() as ThemeType;
  const themeName = useThemeName();

  const [navHeight, setNavHeight] = useState<number>(0);

  const [isForgroundVideoOpen, setIsForgroundVideoOpen] = useState<boolean>(false);

  const emptyContentBoxIds = getIdsOfEmptyContentBoxes({ items });

  useEffect(() => {
    const headerHeight = document.getElementById('page-header')?.offsetHeight;
    setNavHeight(headerHeight ?? 0);
  }, []);

  const isNordicsContentPage = page?.type === 'nordics_contentpage';

  useEffect(() => {
    if (isForgroundVideoOpen) return;
    window?.swiper?.autoplay?.run();
  }, [isForgroundVideoOpen]);

  const renderTitle = (item) => {
    const index = items.indexOf(item);
    const { emphasize, title, subtitle } = item;

    if (title?.length === 0 && subtitle?.length === 0) {
      return null;
    }

    let tag;
    if (titleTag) {
      tag = titleTag;
    } else {
      tag =
        title?.length > 30 || subtitle?.length > 30 || (isSeoConformCarousel && index !== 0)
          ? 'h2'
          : 'h1';

      if (isSeoConformCarousel && index === 0) {
        tag = 'h1';
      }
    }

    return (
      <Title
        as={tag}
        fontWeight={emphasize === 'title' ? theme.fontWeights.bold : theme.fontWeights.light}
        className={classNameBuilder('gdds-headline', styles.heroImageThemedH1)}
      >
        <span>{title?.slice(0, 50)}&nbsp;</span>
        <Title
          fontWeight={emphasize === 'subtitle' ? theme.fontWeights.bold : theme.fontWeights.light}
        >
          {subtitle?.slice(0, 50)}
        </Title>
      </Title>
    );
  };

  if (themeName === 'DEFAULT') {
    return (
      <div ref={refSlider}>
        <HeroImageThemedDefault
          {...props}
          emptyContentBoxIds={emptyContentBoxIds}
          isNordicsContentPage={isNordicsContentPage}
          navHeight={navHeight}
          isForgroundVideoOpen={isForgroundVideoOpen}
          setIsForgroundVideoOpen={setIsForgroundVideoOpen}
          sliderDelay={isForgroundVideoOpen || !inView ? DELAY_INFINITY : DELAY_DEFAULT}
          isSection={isSection}
        />
      </div>
    );
  }

  return (
    <StyledHeroImageThemedWrapper
      className={classNameBuilder(
        'hero-image-themed',
        hideScrollIndicator && 'hide-scrollbar-indicator',
        disableAnimation && 'disable-animation',
        disableSliderControls && 'disable-slider-controls',
        wideLayout && 'wide-layout',
        className,
      )}
      emptyContentBoxIds={emptyContentBoxIds}
      isSlider={items.length > 1}
      ref={refSlider}
    >
      <HeroImage
        type={items.length > 1 ? 'b' : 'a'}
        items={items}
        previewId={previewId ? previewId : isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
        previewIdExtractor={
          previewId || isNordicsContentPage
            ? (_, index) => {
                return `#${index}`;
              }
            : undefined
        }
        navigationHeight={navHeight}
        isSeoConformCarousel={isSeoConformCarousel}
        contentBoxPosition={contentBoxPosition}
        slidingInterval={autoplay ? undefined : DELAY_INFINITY}
        renderTitle={renderTitle}
      />
    </StyledHeroImageThemedWrapper>
  );
}

export { HeroImageThemed };
