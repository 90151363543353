'use client';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// components
import Footer from 'components/Footer/Footer';
import VideoGallery from 'components/VideoGallery/VideoGallery';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import IntroText from 'components/ContentElements/IntroText/IntroText';
import VideocenterOverviewNordics from 'scenes/Nordics/videocenter-overview';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import HeroImageC from 'components/ContentElementsGdds/hero-image/hero-image-c';

// utils
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { setChannels } from 'components/VideoGallery/actions';
import { videoCenterChannelsSelector } from 'utils/selectors/videCenterSelector';
import { contentExists, useContent } from 'components/Page/page-hooks';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { useXy } from 'utils/hooks/use-xy';
import { isEmpty } from 'utils/is-empty';

type VideocenterOverviewProps = {
  channels: any[];
  isNord: boolean;
};

export function VideocenterOverviewScene({
  channels: serverSideChannels,
  isNord,
}: Readonly<VideocenterOverviewProps>) {
  const dispatch = useDispatch();
  const content = useContent<{}, { slider: ISlider; hero: ISlider }>();
  const isCC = useIsCC();
  const isXy = useXy();

  useEffect(() => {
    if (isCC && serverSideChannels) {
      dispatch(setChannels(serverSideChannels));
    }
  }, [dispatch, isCC, serverSideChannels]);

  const channels = useSelector(videoCenterChannelsSelector);

  if (!contentExists(content) || isEmpty(content.page)) {
    return null;
  }

  const {
    breadcrumbs,
    page: { slider, headline, subHeadline, text, showAnchor },
  } = content;

  if (isXy || isNord) {
    return (
      <VideocenterOverviewNordics
        {...content}
        channels={(isCC ? channels : serverSideChannels) ?? []}
      />
    );
  }

  return (
    <>
      <main>
        {slider?.items.some((item) => item.type === 'hero_c_item') ? (
          <HeroImageC {...(slider.items[0] as SliderItemProps)} />
        ) : (
          <HeroImageGdds {...{ ...slider, items: slider.items }} />
        )}

        <Breadcrumb items={breadcrumbs} />
        <PageHeadline
          Format={TitleFormats.h1}
          title={headline}
          subtitle={subHeadline}
          pageHeadline
          hasIntroText
        />
        <IntroText text={text} grid />
        <VideoGallery
          showAnchor={showAnchor}
          pageHeadline={headline}
          channels={(isCC ? channels : serverSideChannels) ?? []}
        />
      </main>
      <Footer />
    </>
  );
}
