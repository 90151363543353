// types
import type { GroupItem, ProductItem } from '../ContentElements/ProductComparison/types';
import type {
  AttributeList,
  ProductComparisonArticle,
  ProductComparisonAttribute,
  UseCaseMap,
} from './types';

export function getAttributesList(groupItems: GroupItem[], productItems: ProductItem[]) {
  return groupItems.reduce((attributes: ProductComparisonAttribute[], groupItem) => {
    const { groupName, pictureObject, picture, priority } = groupItem;

    if (productItems.some((article) => article.useCases.includes(groupName))) {
      attributes.push({
        name: groupName,
        pictureObject,
        picture,
        priority,
      });
    }

    return attributes;
  }, []);
}

export function getArticleList(productItems: ProductItem[]) {
  return productItems.reduce((articles: ProductComparisonArticle[], article) => {
    const { useCases, pictureObject, pictureAlt, link, picture } = article;

    const mapUseCases = useCases?.reduce((useCases: UseCaseMap, item) => {
      useCases.push({
        value: item,
      });

      return useCases;
    }, []);

    articles.push({
      attributeValues: mapUseCases,
      pictureObject,
      link,
      pictureAlt,
      picture,
    });

    return articles;
  }, []);
}

function getLengthProductComparison(attributeLength: number, numberOfVisibleCols: number) {
  const defaultLength = 5;

  if (numberOfVisibleCols === 0 || null) {
    return Math.min(attributeLength, defaultLength);
  }

  return Math.min(attributeLength, numberOfVisibleCols);
}

export function getArticlesLength(
  isOrderHistory: boolean,
  numberOfVisibleCols: number,
  attributeList?: AttributeList,
) {
  // order history
  if (isOrderHistory) return 0;

  // product comparison
  return getLengthProductComparison(attributeList?.length ?? 0, numberOfVisibleCols);
}
