import isEmpty from 'lodash.isempty';
import { validate as validateEmail } from 'email-validator';

// utils
import { validatePhone } from '../../util/validations';
import { mapSapCiamSalutationsToSapCodes } from '../../util/helperFunctions';
import { InvoiceAddress } from 'components/Checkout/action.types';

type PersonalData = Pick<InvoiceAddress, 'firstName' | 'lastName' | 'company'> &
  Partial<Pick<InvoiceAddress, 'salutation'>>;

type AddressData = Pick<InvoiceAddress, 'zip' | 'city' | 'street' | 'street2'> &
  Partial<Pick<InvoiceAddress, 'country'>>;

type ContactData = Pick<InvoiceAddress, 'email' | 'phone' | 'mobile'>;

type DeliveryData = {
  deliveryCheckbox: boolean;
  deliverySalutation?: string;
  deliveryFirstName: string;
  deliveryLastName: string;
  deliveryCompany: string;
  deliveryZipCode: string;
  deliveryStreetAndHouse: string;
  deliveryStreet2?: string;
  deliveryCity: string;
  deliveryCountry?: string;
};

type AddressFormValues = PersonalData & AddressData & ContactData & DeliveryData;

function getInitialPersonalData(isLoggedIn, invoiceAddress, userData): PersonalData {
  if (isLoggedIn) {
    return {
      salutation: mapSapCiamSalutationsToSapCodes(userData.salutation) ?? '',
      firstName: userData.firstName ?? '',
      lastName: userData.lastName ?? '',
      company: userData.company ?? '',
    };
  }

  return {
    salutation: invoiceAddress.salutation ?? '',
    firstName: invoiceAddress.firstName ?? '',
    lastName: invoiceAddress.lastName ?? '',
    company: invoiceAddress.company ?? '',
  };
}

function getInitialContactData(isLoggedIn, invoiceAddress, userData): ContactData {
  if (isLoggedIn) {
    return {
      email: userData.email ?? '',
      phone: userData.phoneNumber ?? '',
      mobile: userData.mobileNumber ?? '',
    };
  } else {
    return {
      email: invoiceAddress.email ?? '',
      phone: invoiceAddress.phone ?? '',
      mobile: invoiceAddress.mobile ?? '',
    };
  }
}

function getInitialAddressData(
  isLoggedIn,
  invoiceAddress,
  userData,
  countryAbbreviation,
): AddressData {
  const userCountryIsEqualToWebsiteCountry =
    isLoggedIn && !isEmpty(userData)
      ? countryAbbreviation === userData.country.toLowerCase()
      : false;

  if (userCountryIsEqualToWebsiteCountry) {
    return {
      street: userData.address ?? '',
      zip: userData.zipCode ?? '',
      city: userData.city ?? '',
      street2: invoiceAddress.street2 ?? '',
      country: userData.country || countryAbbreviation.toUpperCase(),
    };
  } else {
    return {
      street: invoiceAddress.street ?? '',
      zip: invoiceAddress.zip ?? '',
      city: invoiceAddress.city ?? '',
      street2: invoiceAddress.street2 ?? '',
      country: invoiceAddress.country || countryAbbreviation.toUpperCase(),
    };
  }
}

function getInitialDeliveryData(deliveryAddress, countryAbbreviation): DeliveryData {
  return {
    deliveryCheckbox: deliveryAddress.deliveryCheckbox || false,
    deliverySalutation: deliveryAddress.salutation ?? '',
    deliveryFirstName: deliveryAddress.firstName ?? '',
    deliveryLastName: deliveryAddress.lastName ?? '',
    deliveryCompany: deliveryAddress.company ?? '',
    deliveryStreetAndHouse: deliveryAddress.street ?? '',
    deliveryStreet2: deliveryAddress.street2 ?? '',
    deliveryZipCode: deliveryAddress.zip ?? '',
    deliveryCity: deliveryAddress.city ?? '',
    deliveryCountry: deliveryAddress.country || countryAbbreviation.toUpperCase(),
  };
}

export function getInitialValues({
  isLoggedIn,
  invoiceAddress,
  userData,
  deliveryAddress,
  countryAbbreviation,
}): AddressFormValues {
  return {
    ...getInitialPersonalData(isLoggedIn, invoiceAddress, userData),
    ...getInitialContactData(isLoggedIn, invoiceAddress, userData),
    ...getInitialAddressData(isLoggedIn, invoiceAddress, userData, countryAbbreviation),
    ...getInitialDeliveryData(deliveryAddress, countryAbbreviation),
  };
}

function validatePersonalData(
  errors,
  { firstName, lastName, company }: PersonalData,
  translations,
) {
  if (firstName.length > 35 || firstName.length === 0)
    errors.firstName = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_first_name_label}`;
  if (lastName.length > 35 || lastName.length === 0)
    errors.lastName = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_last_name_label}`;
  if (company.length > 35)
    errors.company = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_company_label}`;
}

function validateAddressData(errors, { zip, city, street, street2 }: AddressData, translations) {
  if (zip.length > 10 || zip.length === 0)
    errors.zip = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_zip_code_label}`;
  if (city.length > 35 || city.length === 0)
    errors.city = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_city_label}`;
  if (street.length > 35 || street.length === 0)
    errors.street = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_street_and_house_label}`;
  if (street2 && street2.length > 35)
    errors.street2 = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_addition}`;
}

function validateContactData(errors, { email, phone, mobile }: ContactData, translations) {
  if (email.length > 241 || email.length === 0 || !validateEmail(email)) {
    errors.email = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_email_label}`;
  }
  if (!validatePhone(phone)) {
    errors.phone = `${translations.web20_checkout_address_error_message_2}`;
  }

  if (!validatePhone(mobile)) {
    errors.mobile = `${translations.web20_checkout_address_error_message_2}`;
  }
}

function validatedeliveryData(
  errors,
  {
    deliveryFirstName,
    deliveryLastName,
    deliveryZipCode,
    deliveryStreetAndHouse,
    deliveryCompany,
    deliveryCity,
  }: DeliveryData,
  translations,
) {
  if (deliveryFirstName.length > 35 || deliveryFirstName.length === 0)
    errors.deliveryFirstName = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_first_name_label}`;
  if (deliveryLastName.length > 35 || deliveryLastName.length === 0)
    errors.deliveryLastName = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_last_name_label}`;
  if (deliveryZipCode.length > 10 || deliveryZipCode.length === 0)
    errors.deliveryZipCode = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_zip_code_label}`;
  if (deliveryStreetAndHouse.length > 35 || deliveryStreetAndHouse.length === 0)
    errors.deliveryStreetAndHouse = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_street_and_house_label}`;
  if (deliveryCompany.length > 35)
    errors.deliveryCompany = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_company_label}`;
  if (deliveryCity.length > 35 || deliveryCity.length === 0)
    errors.deliveryCity = `${translations.web20_checkout_address_error_message_1} ${translations.web20_checkout_address_city_label}`;
}

export function validate(values: AddressFormValues, translations) {
  const errors = {};
  validatePersonalData(errors, values, translations);
  validateAddressData(errors, values, translations);
  validateContactData(errors, values, translations);
  if (values.deliveryCheckbox) {
    validatedeliveryData(errors, values, translations);
  }
  return errors;
}

export function safeJsonParse<T>(str: string, fallback?: T) {
  try {
    const jsonValue: T = JSON.parse(str);

    return jsonValue;
  } catch {
    return fallback;
  }
}
