// types
import type { InputFieldField } from './input-field';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';

export function getUploadClassNames(isFileUpload, files, name, maxFileCount) {
  const hideFileUpload =
    isFileUpload && !isEmpty(files) && !isEmpty(files[name]) && files[name].length === maxFileCount;
  const hideUploadClassName = hideFileUpload ? 'hide' : '';

  return classNameBuilder(
    isFileUpload &&
      `${hideUploadClassName} button button--background-primary form-item__file-upload`,
  );
}

export function getErrorMessage(errors, touched, name, rules, type, filesErrorMessage) {
  let errorMessage = (
    errors[name] && touched[name] && !isEmpty(rules) && errors[name] !== 'keepErrorMessage'
      ? errors[name]
      : ''
  ) as string;

  if (filesErrorMessage && type === 'file') {
    errorMessage = errorMessage ? `${errorMessage} ${filesErrorMessage}` : filesErrorMessage;
  }

  return errorMessage;
}

export function useGetPlaceholder({ placeholder }: InputFieldField) {
  // 0 number get ignored so make sure everything is a string
  const placeholderString = placeholder?.toString() || '';

  return placeholderString;
}
