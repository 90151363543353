import { Button } from '@geberit/gdds';
import { useMemo, useContext } from 'react';
import { useFormikContext } from 'formik';

// components
import { ButtonWrapperNord } from './button-wrapper-nord.styles';
import Captcha from '../captcha/captcha';
import { type ButtonFieldProps, ButtonField } from '../button-field/button-field';
import { Message } from 'components/Message/Message';

// utils
import { FormContext } from 'components/ContentElements/Form/form-context';
import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';
import { useIsMobile } from 'components/App/SizeProvider';

export type SubmitFormField = {
  buttons: Button[];
};

interface SubmitFormProps {
  field: SubmitFormField;
}

export function SubmitForm({ field: { buttons } }: Readonly<SubmitFormProps & ButtonFieldProps>) {
  const isGdds = useGdds();
  const isNordics = useNord();
  const isMobile = useIsMobile();

  const { isSubmitting, handleReset } = useFormikContext<Record<string, string>>();
  const { submitMessages, status, formType, captchaField } = useContext(FormContext);

  const captchaFieldMemo = useMemo(() => {
    if (!captchaField || typeof window === 'undefined' || !window['__grecaptcha_cfg']) return null;

    return <Captcha fieldName={captchaField.fieldName} />;
  }, [captchaField]);

  if (isGdds && !isNordics) {
    return (
      <div>
        {captchaFieldMemo}
        {buttons.map((button) => (
          <Button
            key={button.id}
            stylingType={button.type === 'submit' ? 'primary' : 'secondary'}
            size="big"
            buttonType={button.type === 'submit' ? button.type : 'reset'}
            onClick={button.type !== 'submit' ? handleReset : undefined}
            symbol={button.type === 'submit' ? 'ArrowRight' : undefined}
            stretched={isMobile}
          >
            {button.label}
          </Button>
        ))}
      </div>
    );
  }
  if (isNordics) {
    return (
      <ButtonWrapperNord>
        {captchaFieldMemo}
        {buttons.map((button) => (
          <Button
            key={button.id}
            stylingType={button.type === 'submit' ? 'primary' : 'secondary'}
            size="standard"
            buttonType={button.type === 'submit' ? button.type : 'reset'}
            onClick={button.type !== 'submit' ? handleReset : undefined}
            symbol={button.type === 'submit' ? 'ArrowRight' : undefined}
          >
            {button.label}
          </Button>
        ))}
      </ButtonWrapperNord>
    );
  }

  return (
    <>
      {captchaFieldMemo}
      {status?.messageIcon && formType === 'leadmodule' && (
        <Message
          type={status.validationError ? 'alert' : (status.messageIcon as string)}
          content={status.validationError ? submitMessages.error : submitMessages.validate}
        />
      )}
      <ButtonField disabled={isSubmitting} buttons={buttons} />
    </>
  );
}
