// types
import ProductDetailAccordion from "./ProductDetailAccordion";
import { ProductDetailGddsAccordion } from "./ProductDetailGddsAccordion";
import type { ProductDetailAccordionProps } from "./types";

// utils
import { useXy } from "utils/hooks/use-xy";

function ThemedDetailAccordion(props: Readonly<ProductDetailAccordionProps>) {
  const isXy = useXy();

  return isXy ? <ProductDetailGddsAccordion {...props} /> : <ProductDetailAccordion {...props} />;
}

export default ThemedDetailAccordion;
