import { accordionClick } from '../components/ContentElements/Accordion/trackingActions';
import { ACCORDION_ID_TO_STATE_NAME } from '../components/TableOfContents/tocDataAdapter';

/**
 * Track Accordion on Change
 *
 * @param {Object} track
 * @param {Function} setNewAccordionItems Update Component State
 * @param {Array} openAccs Currently open accordions
 * @param {String} trackingType
 * @param {Array} uuid AccodionItem id
 * @param {Object} uuid All available translations from store
 */
export function trackingOnChange(
  track,
  setNewAccordionItems,
  openAccs,
  trackingType,
  uuid,
  translations,
) {
  let currentChange;
  // check which tracking category should be used, depending on type
  const trackingCategory = trackingType || 'Clicks';

  if (uuid.length > openAccs.length) {
    // a new accordionItem got opened so get the new value
    currentChange = uuid.find((accordionUuid) => !openAccs.includes(accordionUuid));
  } else {
    // a accordiontItem got closed, so get the lost value
    currentChange = openAccs.find((accordionUuid) => !uuid.includes(accordionUuid));
  }

  if (translations) {
    // find regarding translation for pdp accordion
    let translation;
    Object.values(ACCORDION_ID_TO_STATE_NAME).forEach((name, i) => {
      if (currentChange === name) {
        translation = Object.keys(ACCORDION_ID_TO_STATE_NAME)[i];
      }
    });
    currentChange = translation && translations[translation];
  }

  setNewAccordionItems(uuid);

  if (currentChange) track.trackEvent(accordionClick(trackingCategory, currentChange));
}
