import { Icon, Typo } from '@geberit/gdds';

// styles
import { StyledElement, StyledFlyoutText } from './flyout-item.styles';

// types
import type { JSX } from 'react';

interface FyloutItemProps {
  mainText: JSX.Element[];
  focused: boolean;
  secondary_text: string;
  iconName: string;
  onSelectOption?: () => void;
}

export function FyloutItem({
  onSelectOption,
  iconName,
  mainText,
  secondary_text,
  focused,
}: Readonly<FyloutItemProps>) {
  return (
    <StyledElement
      focused={focused}
      onClick={() => {
        if (typeof onSelectOption === 'function') {
          onSelectOption();
        }
      }}
    >
      <Icon symbol={iconName} />
      <StyledFlyoutText variant="p2">
        {mainText}
        <Typo variant="label1">{secondary_text}</Typo>
      </StyledFlyoutText>
    </StyledElement>
  );
}
