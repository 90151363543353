import { Col, Container, Button as GddsButton, Row} from '@geberit/gdds';
import { useCallback } from 'react';

// styles
import styles from './checkoutIntro.module.scss';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { BUTTON_BACKGROUND, BUTTON_COLORS, Button } from 'components/Button/Button';

// utils
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { buildSize, gridSizes } from 'utils/gridSize';
import  { useGdds } from 'utils/hooks/use-gdds'

type CheckoutIntroViewProps = {
  onGuestClick: () => void;
  hasGuestButton: boolean;
}

export function CheckoutIntroView({
  onGuestClick,
  hasGuestButton,
}: Readonly<CheckoutIntroViewProps>) {
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const language = useCurrentLanguage();
  const { login } = useCiamLogin(gigyaWindow);
  const translate = useTranslationFunction();
  const isGdds = useGdds();

  const openLogin = useCallback(() => {
    const country = language.slice(language.length - 2);
    login(language, `${country}_Webshop`);
  }, [language, login]);

  const GDDSWebshop = () => (
    <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
      <Row justify="center">
        <Col size={[4, 8, 8]}>
          <Headline
            isFlexItem
            tag='h1'
            title={translate('web20_checkout_intro_title')}
            subtitle={translate('web20_checkout_intro_subtitle')}
            className={styles.checkoutIntroHeadline}
            intro={translate('web20_checkout_intro_text')}
            introTextClassName={styles.checkoutIntroText}
          />
          <div className={classNameBuilder(
            styles.checkoutButtons,
            styles.gddsCheckoutButtons
          )}>
            {hasGuestButton && (
              <GddsButton
                symbol="arrow_right"
                onClick={onGuestClick}
                className={styles.editButton}
              >
                {translate('web20_checkout_intro_button_guest')}
              </GddsButton>
            )}
            <GddsButton
              stylingType='primary'
              symbol="profile"
              onClick={openLogin}
            >
              {translate('web20_checkout_intro_button_login_register')}
            </GddsButton>
          </div>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      {isGdds ? (
        <GDDSWebshop />
      ):(
        <div className="grid-container">
          <div className={styles.checkoutIntro}>
            <div className="title--block c-page-headline">
              <h1>
                <b>
                  {translate('web20_checkout_intro_title')}
                </b>
                {translate('web20_checkout_intro_subtitle')}
              </h1>
            </div>
            <div className="grid-x grid-margin-x">
              <div className={`cell small-12 ${styles.checkoutIntroText}`}>
                {translate('web20_checkout_intro_text')}
              </div>
              <div className={`cell small-12 ${styles.checkoutButtons}`}>
                <Button
                  symbol="user"
                  background={BUTTON_BACKGROUND.PRIMARY}
                  color={BUTTON_COLORS.WHITE}
                  onClick={openLogin}
                >
                  {translate('web20_checkout_intro_button_login_register')}
                </Button>
                {hasGuestButton && (
                  <Button
                    symbol="arrow-link-light-right"
                    onClick={onGuestClick}
                    className={styles.editButton}
                  >
                    {translate('web20_checkout_intro_button_guest')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
