import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// utils
import { shoppingBasketItemsSelector } from 'utils/selectors/shoppingBasketSelector';
import { queue } from 'utils/job-queue';
import { QUEUE_TRACK_ADD_TO_CART, useTracking } from 'utils/tracking/track';
import { roundValueTwoDigits } from '../round-value';

export function useTrackAddToCart(productId: string, variantId, quantity) {
  const basketItems = useSelector(shoppingBasketItemsSelector);
  const { trackWebshop } = useTracking();

  useEffect(() => {
    if (queue.has(QUEUE_TRACK_ADD_TO_CART)) {
      const basketProduct = basketItems?.find(
        (entry) => entry.productId === productId && entry.variantId === variantId,
      );
      if (basketProduct?.sku) {
        const totalValue = quantity * parseFloat(basketProduct.pricePerItem);
        trackWebshop('add_to_cart', {
          value: roundValueTwoDigits(totalValue),
          items: [
            {
              item_id: basketProduct.sku,
              item_name: basketProduct.description,
              quantity: quantity,
              price: parseFloat(basketProduct.pricePerItem),
            },
          ],
        });
        queue.process(QUEUE_TRACK_ADD_TO_CART);
      }
    }
  }, [basketItems, trackWebshop]);
}
