import React, { useState, useCallback } from 'react';

// types
import type { CartProps } from '../../types';

// components
import CartDownloads from './cart-downloads';
import CartOrders from './cart-orders';
import { ICON_COLORS, Icon } from 'components/Icon/Icon';
import { BUTTON_TYPES, BUTTON_COLORS, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { Lightbox } from 'components/lightbox/lightbox';
import { LightboxHeader } from 'components/lightbox/lightbox-header';

// utils
import { clickZipCart } from 'features/download-center/tracking';
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useBrandName } from 'utils/hooks/use-brand-name';
import { decodingContent } from 'utils/decodingContent';

export default function Cart(props: Readonly<CartProps>) {
  const {
    updateDownload,
    downloadZip,
    updateOrder,
    updateAmount,
    getSessionStorage,
    downloadProgress,
    setDownloadProgress,
    orders = [],
    downloads = [],
    downloadForm,
  } = props;
  const track = useTracking();
  const { trackClick } = useTracking2();
  const [openDownloads, setOpenDownloads] = useState(false);
  const translate = useTranslationFunction();
  const brandName = useBrandName();

  const title = openDownloads
    ? translate('web20_downloadcenter_cart_headline_your_downloads')
    : translate('web20_downloadcenter_cart_headline_your_order');

  const [openOrders, setOpenOrders] = useState(false);
  const [customTitle, setCustomTitle] = useState<string | null>(null);
  const totalOrder = orders.length || 0;
  const totalDownload = downloads.length || 0;
  const [form] = downloadForm || [];

  const handleDownloadChange = useCallback(
    (product) => {
      updateDownload(product);
      // close on last download removed
      if (downloads.find((download) => download.id === product.id) && downloads.length === 1) {
        setOpenDownloads(false);
      }
    },
    [downloads],
  );

  const handleOpenDownloads = useCallback(() => {
    if (downloads.length > 0) {
      setOpenDownloads(true);
    }
  }, [downloads]);

  const handleOpenOrders = useCallback(() => {
    trackClick({ click_intent: 'download-center_order', click_element: 'download_center' });
    if (orders.length > 0) {
      setOpenOrders(true);
      setCustomTitle(null);
    }
  }, [orders]);

  const handleClose = useCallback(() => {
    setOpenDownloads(false);
    setOpenOrders(false);
    setDownloadProgress(0);
  }, []);

  const handleDownloadZip = useCallback(() => {
    downloadZip(downloads);

    const downloadTitles = downloads
      .map((download) => decodingContent(download?.meta?.title?.[0] ?? ''))
      .join(', ');
    track.trackEvent(clickZipCart(downloadTitles));
    const fileName = `${brandName?.toLowerCase()}-downloads.zip`;
    trackClick({
      click_intent: 'ZIP-download-center',
      file_name: fileName,
      file_extension: 'zip',
      event_label: downloadTitles,
      value: 5,
    });
  }, [downloads]);

  const handleFinished = useCallback(() => {
    setCustomTitle(' ');
  }, []);

  return (
    <div className="c-cart cell small-12 medium-12 large-3">
      <div className="c-cart__box">
        <h5>
          <Icon symbol="shoppingcart" color={ICON_COLORS.WHITE} />
          <Translation id="web20_downloadcenter_cart" />
        </h5>
        <Button
          onClick={handleOpenDownloads}
          type={BUTTON_TYPES.SEAMLESS}
          color={BUTTON_COLORS.PRIMARY}
        >
          <span suppressHydrationWarning>
            <Translation id="web20_downloadcenter_cartdownload_as_zip" /> ({totalDownload})
          </span>
        </Button>
        {!isEmpty(downloadForm) && (
          <Button
            onClick={handleOpenOrders}
            type={BUTTON_TYPES.SEAMLESS}
            color={BUTTON_COLORS.PRIMARY}
          >
            <span suppressHydrationWarning>
              <Translation id="web20_downloadcenter_order" /> ({totalOrder})
            </span>
          </Button>
        )}
      </div>
      {(openDownloads || openOrders) && (
        <Lightbox onCloseRequest={handleClose}>
          <div className="c-lightbox__container grid-container">
            <LightboxHeader handleClose={handleClose} title={customTitle || title} />
            {openDownloads && (
              <CartDownloads
                downloads={downloads}
                handleClose={handleClose}
                updateDownload={handleDownloadChange}
                downloadZipHandler={handleDownloadZip}
                downloadProgress={downloadProgress}
              />
            )}
            {openOrders && (
              <CartOrders
                orders={orders}
                handleClose={handleClose}
                updateOrder={updateOrder}
                updateAmount={updateAmount}
                form={form}
                getSessionStorage={getSessionStorage}
                onFinished={handleFinished}
              />
            )}
          </div>
        </Lightbox>
      )}
    </div>
  );
}
