import { useState } from 'react';
import key from 'weak-key';
import { AccordionItem, Button, Dialogue } from '@geberit/gdds';

// styles
import styles from './courseuserlist.module.scss';

// components
import { Loader } from 'components/Loader/Loader';
import { Translation } from 'components/Translation/Translation';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useCampusNotifyCode, useCourseParticipants } from 'utils/hooks/useCampus';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useIsDesktop } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

interface CourseUserListProps {
  courseId: number;
  courseTitle: string;
  isWebinar?: boolean;
}

export function CourseUserList({
  courseTitle,
  courseId,
  isWebinar = false,
}: Readonly<CourseUserListProps>) {
  const { participants, participantCount, isLoading } = useCourseParticipants(courseId);
  const [opened, setOpened] = useState<string | null>('initial');
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const notify = useCampusNotifyCode();
  const headline = useTranslationByKey('campus_headline_participants');
  const accessCodeDialogueHeader = useTranslationByKey('campus_send_access_dialogue_header');
  const accessCodeDialogueBody = useTranslationByKey('campus_send_access_dialogue_body');
  const handleSendAccessButton = (courseId, userId) => {
    notify({ courseId, userId });
    setDialogueOpen(true);
  };
  const isDesktop = useIsDesktop();

  if (isLoading) {
    return (
      <div className="grid-container">
        <div className={styles.loader}>
          <Loader />
        </div>
      </div>
    );
  }

  if (!participantCount) return null;

  return (
    <div>
      {dialogueOpen && (
        <Dialogue
          body={<div>{accessCodeDialogueBody}</div>}
          showCloseBtn
          type="success"
          size="l"
          headerLabel={accessCodeDialogueHeader}
          closeDialogue={() => setDialogueOpen(false)}
          primaryBtnLabel="Ok"
          onPrimaryBtnClick={() => setDialogueOpen(false)}
        />
      )}
      <Headline title={headline} tag={Formats.h2} className={styles.headline} isFlexItem />
      {isDesktop ? (
        <table className={styles.table}>
          <thead>
            <tr className={isWebinar ? styles.oneFifth : styles.oneFourth}>
              <th>
                <Translation id="campus_form_firstname" />
              </th>
              <th>
                <Translation id="campus_form_lastname" />
              </th>
              <th>
                <Translation id="campus_course_name" />
              </th>
              {isWebinar && (
                <th>
                  <Translation id="campus_expirationdate" />
                </th>
              )}
              <th>
                <Translation id="campus_send_access" />
              </th>
            </tr>
          </thead>
          <tbody>
            {participants.map((item) => (
              <tr key={key(item)}>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td>{courseTitle}</td>
                {isWebinar && <td>{item.expirationDate}</td>}
                <td>
                  {!item.participantIsCurrentUser && (
                    <Button
                      buttonType="button"
                      onClick={() => handleSendAccessButton(courseId, item.id)}
                    >
                      <Translation id="campus_send_access" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          {participants.map((item, i) => (
            <AccordionItem
              title={`${item.firstname} ${item.lastname}`}
              opened={`${i}-${item.id}` === opened || (opened === 'initial' && i === 0)}
              onTitleClick={() => {
                setOpened((prev) =>
                  prev === `${i}-${item.id}` || opened === 'initial' ? null : `${i}-${item.id}`,
                );
              }}
              iconAlignment="right"
              stylingType="withBackground"
              key={key(item)}
            >
              <div
                className={classNameBuilder(
                  styles.accordionBody,
                  isWebinar ? styles.oneThird : styles.twoThirds,
                )}
              >
                <div>
                  <strong>
                    <Translation id="campus_course_name" />
                  </strong>
                  {courseTitle}
                </div>
                {!item.participantIsCurrentUser && (
                  <Button
                    buttonType="button"
                    onClick={() => handleSendAccessButton(courseId, item.id)}
                  >
                    <Translation id="campus_send_access" />
                  </Button>
                )}
              </div>
            </AccordionItem>
          ))}
        </div>
      )}
    </div>
  );
}
