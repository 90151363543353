import { useState } from 'react';
import { useSelector } from 'react-redux';
import striptags from 'striptags';
import { Divider, Button, Icon } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// types
import type { Course, CourseDateList } from 'components/Campus/types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Translation } from 'components/Translation/Translation';
import { CourseTeaser } from 'components/Campus/CourseTeaser/CourseTeaser';
import { ParticipantsTable } from 'components/Campus/ParticipantsTable/ParticipantsTable';
import { Loader } from 'components/Loader/Loader';
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';

// utils
import { isEmpty } from 'utils/is-empty';
import { getMetatag } from 'components/Campus/metatags';
import { useCostsIncludedMetatags } from 'utils/hooks/useCostsIncludedMetatags';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useCampusEnv, useCampusTypes } from 'utils/hooks/useCampus';
import { campusDashboardUrlSelector } from 'utils/selectors/globalsSelectors';
import { Log } from 'services/log';

interface CampusBookingConfirmationProps {
  course: Course;
}

function CampusBookingConfirmation({ course }: Readonly<CampusBookingConfirmationProps>) {
  const { CAMPUS_META } = useCampusEnv();
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);
  const [buttonLoading, setButtonLoading] = useState(false);
  const translate = useTranslationFunction();
  const [type, icon] = useCampusTypes(course?.topics);

  const closeContent = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    setTimeout(() => {
      window.location.href = campusDashboardUrl;
    }, 5000);
  };

  const metaTags = course?.metaTags;
  const campusCostsIncluded = useCostsIncludedMetatags(metaTags);
  const languages = course?.contentLanguages.contentLanguage.map((item) => item.name);
  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags);
  const courseDates: CourseDateList = [];

  if (dateStart) {
    courseDates.push({
      day: dateStart.split(' ')[0],
      time: dateStart.split(' ')[1],
    });
  }
  if (dateEnd) {
    courseDates.push({
      day: dateEnd.split(' ')[0],
      time: dateEnd.split(' ')[1],
    });
  }

  let participants = [];
  let bookedComment = '';
  try {
    participants = JSON.parse(sessionStorage.getItem('bookedParticipants') as string);
    bookedComment = JSON.parse(sessionStorage.getItem('bookedComment') as string);
  } catch (e) {
    Log.error('Failed to parse booked participants', e);
    // do nothing
  }

  return (
    <div className={styles.bookingConfirmation}>
      {/* headline */}
      <IntroText
        title={translate('campus_headline_course_registration')}
        subtitle={translate('campus_subline_course_registration')}
        intro={''}
        text={''}
        border="disable-border"
        emphasize="title"
        containerClassName={styles.campusIntroTextContainer}
        disableInEdit
        isFlexItem
      />

      <div>
        {/* confirmation */}
        <div className={styles.confirmation}>
          <div className={styles.successIcon}>
            <span>
              <Icon symbol="Checkmark" width="1.15rem" color="#008819" />
            </span>
          </div>
          <b>
            <Translation
              id={type === 'event' ? 'campus_text_bold_booking' : 'campus_text_bold_confirmation'}
            />
          </b>
          <p>
            <Translation
              id={type === 'event' ? 'campus_text_booking' : 'campus_text_confirmation'}
            />
          </p>
          {buttonLoading ? (
            <span>
              <Loader size="small" color="black" />
            </span>
          ) : (
            <Button
              className={styles.backToCourseButton}
              stylingType="primary"
              onClick={closeContent}
            >
              <Translation id="campus_back_dashboard" />
            </Button>
          )}
        </div>
        <Divider />
        <div className={styles.bookingDetails}>
          <Headline
            title={translate('campus_headline_booking_details')}
            tag={Formats.h2}
            className={styles.gddsHeadlineH2}
            isFlexItem
          />
          {/* course details */}
          <Headline
            title={translate('campus_headline_course')}
            tag={Formats.h3}
            className={styles.gddsHeadlineH3}
            isFlexItem
          />
          <CourseTeaser
            icon={icon}
            courseId={course.id}
            courseType={course.learningForm} //
            courseHeadline={getMetatag(CAMPUS_META.NAME, metaTags)}
            courseDate={courseDates.length > 0 ? courseDates : undefined}
            availableSeats={course.freePlaces > 0 ? course.freePlaces : undefined}
            seats={course.freePlaces > 0 && getMetatag(CAMPUS_META.MAX_CAPACITY, metaTags)}
            location={getMetatag(CAMPUS_META.LOCATION, metaTags)}
            language={languages.join(', ')}
            costs={getMetatag(CAMPUS_META.PRICE, metaTags)}
            costsIncluded={campusCostsIncluded}
            targetGroup={getMetatag(CAMPUS_META.TARGETGROUP, metaTags)}
            expirationDate={!type.includes('event') ? course.endDate : undefined}
            courseDescription={getMetatag(CAMPUS_META.SUMMARY, metaTags)}
          />
          {/* participants */}
          <ParticipantsTable participants={participants} isEvent={type.includes('event')} />
          {/* course details */}
          {type.includes('event') &&
            getMetatag(CAMPUS_META.INFOBOX, metaTags) &&
            !isEmpty(bookedComment) && (
              <>
                <Headline
                  title={translate('campus_form_comment')}
                  tag={Formats.h3}
                  className={styles.gddsHeadlineH3}
                  isFlexItem
                />
                {striptags(bookedComment)}
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default CampusBookingConfirmation;
