import { useSelector } from 'react-redux';
import SlickSlider from 'react-slick/lib';
import { Button } from '@geberit/gdds';

// styles
import styles from './contactModule.module.scss';

// components
import { ContactModuleItem } from './ContactModuleItem';
import { Translation } from 'components/Translation/Translation';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { slickSettings } from './slickSettings';
import { campusContactSelector } from 'utils/selectors/globalsSelectors';
import { useLocationDetails } from 'utils/hooks/useCampus';
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

interface ContactModuleProps {
  gdds?: boolean;
  locationId?: number;
}

export function ContactModule({ gdds = false, locationId }: Readonly<ContactModuleProps>) {
  const {
    headline,
    copy,
    contacts: fsContacts,
    buttonTarget,
  } = useSelector(campusContactSelector) || {};
  const { data: courseLocation } = useLocationDetails(locationId);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const contacts = locationId && courseLocation ? [courseLocation] : fsContacts;
  if (!contacts) return null;

  const isSliderClass =
    (isMobile && contacts && contacts.length > 1) || (isTablet && contacts && contacts.length > 2)
      ? 'is-slider'
      : '';

  return (
    <div>
      <div
        className={classNameBuilder(
          styles.contactModule,
          gdds && 'contactModule--gdds',
          `item-count-${contacts.length}`,
          isSliderClass,
          locationId && styles.hasLocationId,
        )}
      >
        <Headline
          title={headline}
          tag={Formats.h2}
          text={copy}
          className={styles.contactModuleHeadlineComp}
          textClassName={styles.contactModuleHeadlineText}
          isFlexItem
        />
        <div className={styles.contactModuleItems}>
          {(isMobile && contacts.length > 1) || (isTablet && contacts.length > 2) ? (
            <SlickSlider {...slickSettings}>
              {contacts.map((item, index) => (
                <ContactModuleItem
                  {...item}
                  buttonTarget={contacts.length === 1 && buttonTarget}
                  key={`${item.name}-${index}`}
                />
              ))}
            </SlickSlider>
          ) : (
            contacts.map((item, index) => (
              <ContactModuleItem
                {...item}
                buttonTarget={contacts.length === 1 && buttonTarget}
                key={`${item.name}-${index}`}
                isDetail={locationId}
              />
            ))
          )}
        </div>
        {contacts.length > 1 && buttonTarget && (
          <div className={styles.contactModuleFurther}>
            <Button
              onClick={() => {
                window.open(buttonTarget, '_blank');
              }}
            >
              <Translation id="campus_more_details" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
