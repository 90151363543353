import { useState } from 'react';
import { HeroImage } from '@geberit/gdds';
import ReactHtmlParser from 'react-html-parser';

// types
import type { HeroImageThemedDefaultProps } from './hero-image-themed-default.types';

// components
import { ForegroundVideo } from '../foreground-video';

// styles
import styles from './hero-image-themed.module.scss';

// utils
import { StyledHeroImageThemed } from './hero-image-themed.styles';
import { normalizeItems } from './hero-image-themed.utils';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';

function HeroImageThemedDefault(props: HeroImageThemedDefaultProps) {
  const {
    contentBoxPosition,
    emptyContentBoxIds,
    isSeoConformCarousel,
    items,
    isForgroundVideoOpen,
    isNordicsContentPage,
    navHeight,
    setIsForgroundVideoOpen,
    sliderDelay,
    isSection,
  } = props;

  const [foregroundVideo, setForegroundVideo] = useState<VideoProp | undefined>(undefined);
  const videoPlayButtonLabel = useTranslationByKey('web20_video_play_button');

  const isMobile = useIsMobile();

  const handleForegroundVideoOpen = (video: VideoProp) => {
    setForegroundVideo(video);
    setIsForgroundVideoOpen(true);
  };

  const handleForegroundVideoClose = () => {
    setForegroundVideo(undefined);
    setIsForgroundVideoOpen(false);
  };

  const _items = normalizeItems({
    isMobile,
    items,
    onForegroundVideoClick: handleForegroundVideoOpen,
    videoPlayButtonLabel,
  });

  const _type = items.length === 1 ? 'a' : 'b';

  return (
    <StyledHeroImageThemed.Default
      emptyContentBoxIds={emptyContentBoxIds}
      isSlider={items.length > 1}
      className={classNameBuilder(
        'hero-image-themed hero-image-themed__default',
        isSection && 'is-section',
      )}
    >
      <HeroImage
        type={_type}
        items={_items}
        previewId={isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
        previewIdExtractor={
          isNordicsContentPage
            ? (_, index) => {
                return `#${index}`;
              }
            : undefined
        }
        navigationHeight={navHeight}
        isSeoConformCarousel={isSeoConformCarousel}
        contentBoxPosition={contentBoxPosition}
        slidingInterval={sliderDelay}
        renderTitle={({ headline, subtitle }) => {
          if (!headline && !subtitle) return <></>;

          const HeaderTag = isSection ? 'h2' : 'span';
          const parsedTitle = ReactHtmlParser(headline);
          const parsedSubtitle = ReactHtmlParser(subtitle ?? '');

          return (
            <HeaderTag
              className={classNameBuilder(styles.headline, styles[isSection ? 'h2' : 'h1'])}
            >
              {parsedSubtitle}
              {!isEmpty(parsedTitle) && !isEmpty(parsedSubtitle) && ' '}
              {!isEmpty(parsedTitle) && <span>{parsedTitle}</span>}
            </HeaderTag>
          );
        }}
      />
      {foregroundVideo && isForgroundVideoOpen && (
        <ForegroundVideo onClose={handleForegroundVideoClose} video={foregroundVideo} />
      )}
    </StyledHeroImageThemed.Default>
  );
}

export { HeroImageThemedDefault };
