import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import type { AnyAction } from 'redux';
import type { BasketItem } from 'components/ShoppingBasket/reducer.types';

// styles
import styles from './summaryContainer.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';
import LinkWrapper from 'components/Checkout/Components/LinkWrapper/LinkWrapper';
import ErrorMessageBox from 'components/Checkout/Components/ErrorMessageBox/ErrorMessageBox';
import { SummaryData } from './summary-data';

// utils
import {
  orderDataSelector,
  isOrderDataFetchingSelector,
  orderDataErrorSelector,
} from 'components/Checkout/selectors';
import { fetchOrderDataAction } from 'components/Checkout/actions';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { useTracking } from 'utils/tracking/track';
import { roundValueTwoDigits } from 'utils/round-value';
import { safeJsonParse } from 'components/Checkout/Components/AddressForm/utils';

export default function SummaryContainer() {
  const dispatch = useDispatch();
  const translate = useTranslationFunction();
  const orderData = useSelector(orderDataSelector);
  const isOrderDataFetching = useSelector(isOrderDataFetchingSelector);
  const orderError = useSelector(orderDataErrorSelector);
  const { trackWebshop } = useTracking();

  const urlParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : '',
  );
  const orderId = urlParams.get('orderId');
  const locale = urlParams.get('locale');

  useEffect(() => {
    if (orderId && locale) dispatch(fetchOrderDataAction(locale, orderId) as unknown as AnyAction);
  }, [dispatch, locale, orderId]);

  useEffect(() => {
    if (!isEmpty(orderData) && orderId) {
      const checkoutSummary = localStorage.getItem(orderId);
      if (checkoutSummary) {
        const parsedCheckoutSummary = safeJsonParse(checkoutSummary, {
          voucherCode: '',
          basketItems: [] as BasketItem[],
        });
        trackWebshop('purchase', {
          transaction_id: orderId,
          value: roundValueTwoDigits(orderData.totalPriceVatIncluding),
          tax: roundValueTwoDigits(orderData.vatAmount),
          coupon: parsedCheckoutSummary?.voucherCode ?? '',
          shipping: roundValueTwoDigits(orderData.postalCostVatIncluding),
          items: (orderData.lineItemsList ?? []).map((item, index) => ({
            item_id: item.sku,
            item_name: item.description,
            quantity: item.quantity,
            price: roundValueTwoDigits(
              parsedCheckoutSummary?.basketItems?.find((basketItem) => basketItem.sku === item.sku)
                ?.pricePerItem,
            ),
            discount: roundValueTwoDigits(item.priceVoucherPcs),
            index,
          })),
        });
        localStorage.removeItem(orderId);
      }
    }
  }, [orderData, orderId]);

  if (isOrderDataFetching || (isEmpty(orderData) && !orderError)) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size="medium" />
      </div>
    );
  }

  let content;
  if (orderError || !orderId || !locale) {
    content = (
      <ErrorMessageBox>
        {'730 - '}
        <Translation id="web20_checkout_summary_error" />{' '}
        <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
      </ErrorMessageBox>
    );
  } else if (orderData) {
    content = <SummaryData orderId={orderId} orderData={orderData} />;
  } else {
    content = null;
  }

  return (
    <div>
      <h1 className={styles.h1}>
        <span>
          <Translation id="web20_checkout_summary_heading_part1" />
        </span>{' '}
        <Translation id="web20_checkout_summary_heading_part2" />
      </h1>
      <p>
        <Translation id="web20_checkout_summary_description_part1" />{' '}
        <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
        {'. '}
        <Translation id="web20_checkout_summary_description_part3" />
      </p>
      <p>
        <Translation id="web20_checkout_summary_questions_part1" />{' '}
        <LinkWrapper
          translationLink={translate('web20_checkout_link_frequently_asked_questions')}
        />{' '}
        <Translation id="web20_checkout_summary_questions_part3" />
      </p>
      {content}
    </div>
  );
}
