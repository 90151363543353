import { useCallback, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Icon as GddsIcon } from '@geberit/gdds';
import axios from 'axios';

// components
import { Translation } from 'components/Translation/Translation';
import { BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

// utils
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import getEndpoint from 'utils/endpoints';
import { useXy } from 'utils/hooks/use-xy';

type ProductDetailInfoToolsProps = {
  productKey: string;
  scrollToDetails: (stateName, id) => void;
  sparePartsGiven: boolean;
  documentsGiven: boolean;
};

function ProductDetailInfoTools({
  productKey,
  scrollToDetails,
  sparePartsGiven,
  documentsGiven,
}: Readonly<ProductDetailInfoToolsProps>) {
  const link = useRef(null);
  const isXy = useXy();
  const catalog = useSelector(currentCatalogSelector);
  const state = useStore();

  const getProductpdf = useCallback(
    async (e) => {
      e.preventDefault();

      axios
        .get(`${getEndpoint('productpdf', state)}/products/${productKey}/pdf?lang=${catalog}`, {
          responseType: 'blob',
        })
        .then((response) => {
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', `${productKey}.pdf`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        });
    },
    [state, productKey, catalog],
  );

  return (
    <div className="c-product-detail__info-tools">
      <div>
        <a
          href="#"
          onClick={getProductpdf}
          className="c-product-detail__download-link link-button link-button--transparent"
          ref={link}
          download={`${productKey}.pdf`}
        >
          {isXy
            ? <GddsIcon symbol="document" color="#376EB4" />
            : <Icon symbol="file" />}
          <Translation id="web20_product_articles_download_datasheet" />
        </a>
      </div>
      {sparePartsGiven && (
        <div>
          <Button
            type={BUTTON_TYPES.SEAMLESS}
            {...(!isXy && { symbol: 'options' })}
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => scrollToDetails('spareParts', 'product-spareParts')}
          >
            {isXy && <GddsIcon symbol="options" />}
            <Translation id="web20_product_articles_scroll_to_spare_parts" />
          </Button>
        </div>
      )}
      {documentsGiven && (
        <div>
          <Button
            type={BUTTON_TYPES.SEAMLESS}
            {...(!isXy && { symbol: 'search-document' })}
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => scrollToDetails('documents', 'product-documents')}
          >
            {isXy && <GddsIcon symbol="searchdocument" />}
            <Translation id="web20_product_articles_scroll_to_documents" />
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProductDetailInfoTools;
