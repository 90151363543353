import React from 'react';
import { Typo } from '@geberit/gdds';

// types
import type { ContactPeople, Presscontact } from '../types/mynewsdesk.types';

// styles
import styles from './contact-tiles.module.scss';

interface SelectorProps {
  isMobile: boolean;
}

export function ContactTile({
  contact: media,
  isMobile,
}: {
  contact: ContactPeople | Presscontact;
} & SelectorProps) {
  const getImageUrl = (material: ContactPeople | Presscontact) => {
    if (!material) {
      return undefined;
    }

    if ((material as Presscontact).images) {
      if (isMobile) {
        return (material as Presscontact).images.find((i) => i.size === 'small')?.url;
      }

      return (material as Presscontact).images.find((i) => i.size !== 'small')?.url;
    }

    if (isMobile) return (material as ContactPeople).image_small;

    return (material as ContactPeople).image_medium;
  };

  const mediaUrl = getImageUrl(media);

  return (
    <div className={styles.contact}>
      {mediaUrl && <img className={styles.image} src={mediaUrl} alt={media.name} />}
      <Typo fontWeight={700} className={styles.contactName} variant="h4">
        {media.name}
      </Typo>
      <Typo className={styles.title} variant="p1">
        {media.title}
      </Typo>
      <a href={`mailto:${media.email}`}>
        <Typo className={styles.email} variant="p1">
          {media.email}
        </Typo>
      </a>
      <a href={`tel:${media.phone}`}>
        <Typo variant="p1">{media.phone}</Typo>
      </a>
    </div>
  );
}
