import { Icon } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';
import Tooltip from 'components/Tooltip/Tooltip';
import { BUTTON_TYPES, Button } from 'components/Button/Button';

// utils
import { useXy } from 'utils/hooks/use-xy';

type Article = {
  gallery: {
    list: any[];
  };
  key: string;
};

type ActionsProps = {
  article: Article;
};

function Actions({ article }: Readonly<ActionsProps>) {
  const isXy = useXy();
  const primaryImage = article.gallery.list.find((img) => img.orientation === 'PrimaryImageJPG');

  return (
    <div className="c-product-actions">
      {primaryImage && (
        <Tooltip
          trigger="click"
          portalClass="--plain"
          position="bottom"
          content={
            <a href={primaryImage.original} className="c-product-actions--download-image" rel="noopener noreferrer" target="_blank">
              {isXy ? <Icon symbol="image" color="#376EB4" /> : <i className="web20-icon web20-icon-picture" />}
              <Translation id="web20_product_download_image" />
            </a>
          }
        >
          <Button
            type={BUTTON_TYPES.PLAIN}
            className="c-product-actions__more"
            draggable
            {...(!isXy ? { symbol: 'more' } : {})}
          >
            {isXy && <Icon symbol="more_small" />}
            <Translation id="web20_more_label" />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

export default Actions;
