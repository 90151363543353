import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, Divider } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// types
import type { Course, Participants } from 'components/Campus/types';

// components
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';

// utils
import { campusDashboardUrlSelector } from 'utils/selectors/globalsSelectors';
import { getMetatag } from 'components/Campus/metatags';
import { useCampusEnv } from 'utils/hooks/useCampus';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { Log } from 'services/log';
import { IntroText } from '../../components/ContentElementsGdds/IntroText/intro-text';

interface CampusCancelConfirmationProps {
  course: Course;
}

export default function CampusCancelConfirmation({
  course,
}: Readonly<CampusCancelConfirmationProps>) {
  const { CAMPUS_META } = useCampusEnv();
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);
  const [dashboardButtonLoading, setDashboardButtonLoading] = useState(false);
  const translate = useTranslationFunction();

  let cancelledParticipants: Participants = [];
  try {
    cancelledParticipants = JSON.parse(sessionStorage.getItem('cancelledParticipants') ?? '[]');
  } catch (e) {
    Log.error('Failed to parse cancelled participants', e);
  }
  cancelledParticipants.sort((a, b) => a.firstname.localeCompare(b.firstname));

  const closeContentDashboard = (e) => {
    e.preventDefault();
    setDashboardButtonLoading(true);
    setTimeout(() => {
      window.location.href = campusDashboardUrl;
    }, 2000);
  };

  const metaTags = course?.metaTags;

  return (
    <div className={styles.cancelConfirmation}>
      {/* headline */}
      <IntroText
        title={translate('campus_cancel_confirm_headline')}
        subtitle={translate('campus_cancel_confirm_subline')}
        intro={''}
        text={''}
        border="disable-border"
        emphasize="title"
        containerClassName={styles.campusIntroTextContainer}
        disableInEdit
        isFlexItem
      />

      <div>
        {/* confirmation */}
        <div className={styles.confirmation}>
          <div className={styles.successIcon}>
            <span>
              <Icon symbol="Checkmark" width="1.15rem" color="#008819" />
            </span>
          </div>
          <b>
            <Translation
              id="campus_cancel_confirm_textbold"
              placeholder={{ coursename: `"${getMetatag(CAMPUS_META.NAME, metaTags)}"` }}
            />
          </b>
          <div className={styles.participantContainer}>
            <Divider />
            <div className={styles.participants}>
              {cancelledParticipants
                .map((p) => `${p.firstname || ''} ${p.lastname || ''}`.trim())
                .filter((p) => p)
                .join(', ')}
            </div>
            <Divider />
          </div>
          <Translation id="campus_cancel_confirm_text" />
          <div className={styles.buttonContainer}>
            {dashboardButtonLoading ? (
              <span>
                <Loader size="small" color="black" />
              </span>
            ) : (
              <Button stylingType="primary" onClick={closeContentDashboard}>
                <Translation id="campus_back_dashboard" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
