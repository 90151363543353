import { useMemo } from 'react';

// types
import type { FormControllerProps } from './nord/types';

// components
import { type FormContextType, FormContext } from './form-context';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface FormContextProviderProps extends React.PropsWithChildren {
  onStatusChange: FormControllerProps['onFormStatusChange'];
  submitMessages?: FormContextType['submitMessages'];
}

export function FormContextProvider({
  formType,
  preConNames,
  status,
  setStatus,
  uploadProgress,
  files,
  filesErrorMessage,
  addFile,
  deleteFile,
  preconditions,
  hasOxomiButton,
  oxomiButtonClick,
  hasFileUpload,
  captchaField,
  onStatusChange,
  setFilesErrorMessage,
  children,
}: Readonly<FormContextProviderProps & Omit<FormContextType, 'submitMessages'>>) {
  const translate = useTranslationFunction();
  const submitMessages = useMemo(
    () => ({
      error: translate('web20_forms_message_alert'),
      success: translate('web20_forms_message_success'),
      validate: translate('web20_forms_message_validate'),
    }),
    [translate],
  );
  const value = useMemo(
    () => ({
      formType,
      preConNames,
      status,
      setStatus,
      uploadProgress,
      files,
      filesErrorMessage,
      addFile,
      deleteFile,
      preconditions,
      hasOxomiButton,
      oxomiButtonClick,
      hasFileUpload,
      captchaField,
      submitMessages,
      setFilesErrorMessage,
    }),
    [
      formType,
      preConNames,
      setStatus,
      status,
      submitMessages,
      uploadProgress,
      addFile,
      deleteFile,
      files,
      preconditions,
      hasOxomiButton,
      oxomiButtonClick,
      hasFileUpload,
      captchaField,
      filesErrorMessage,
      setFilesErrorMessage,
    ],
  );

  if (typeof onStatusChange === 'function') {
    onStatusChange({
      status: status,
      submitMessages,
    });
  }

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
