'use client';

import { useSelector } from 'react-redux';

// types
import type { ContentElementPayload } from '../ContentElements/content-elements-payload';

// components
import { ContentAreaPlaceholder } from './ContentAreaPlaceholder';
import { ContentElement } from '../ContentElements/ContentElement';
import { Teaser } from '../ContentElements/contentElements.env';

// utils
import { TPP_TYPE_NEWSECTION } from '../ContentCreator/tppConstants';
import { ParentProvider } from 'scenes/parent-provider';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { currentPagePreviewIdSelector } from 'utils/selectors/pageSelectors';
import { useReactKeys } from 'utils/hooks/use-react-keys';
import { isEmpty } from 'utils/is-empty';

interface ContentAreaProps {
  content: ContentElementPayload[];
  contentType: string;
  from?: string;
}

export function ContentArea({ content, contentType, from }: Readonly<ContentAreaProps>) {
  const currentPagePreviewId = useSelector(currentPagePreviewIdSelector);
  const isPreview = useIsPreview();
  let contentForReactKeys: ContentElementPayload[] = [];
  if (content && Array.isArray(content)) {
    contentForReactKeys = content;
  }

  const contentWithKeys = useReactKeys(contentForReactKeys, ['type', 'previewId']);

  if (isEmpty(contentWithKeys) && isPreview) {
    return (
      <ContentAreaPlaceholder
        contentType={contentType}
        previewId={`custom:newSection:${currentPagePreviewId}:${contentType}`}
        editType={TPP_TYPE_NEWSECTION}
        editArea={contentType}
      />
    );
  }
  return (
    <ParentProvider value={{ parent: 'contentArea' }}>
      {contentType === 'tile' ? (
        <Teaser tile={content} from={from} />
      ) : (
        contentWithKeys?.map((item, contentIndex) => (
          <ContentElement
            // coding guideline exception: we spread all server props to the component
            {...item}
            key={item.key}
            contentIndex={contentIndex}
            contentLength={content.length}
            isSection
          />
        ))
      )}
    </ParentProvider>
  );
}
