import React from 'react';
import { useRouter } from 'next/navigation';
import { Breadcrumb, Container, Row, Col } from '@geberit/gdds';

// style
import styles from './breadcrumb.module.scss';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { breadcrumbTrackingAction } from '../Navigation/trackingActions';
import { gridSizes, buildSize } from 'utils/gridSize';
import { classNameBuilder as classNamesBuilder } from 'utils/classNameBuilder';
import { truncateAtSpecificChar } from 'utils/truncate';
import { isEmpty } from 'utils/is-empty';
import { useGroup } from 'utils/hooks/use-group';
import { useIsTablet } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';

type GddsBreadcrumbProps = {
  items: Partial<Breadcrumb>[];
  className?: string;
};

export default function GddsBreadcrumb({ items, className }: Readonly<GddsBreadcrumbProps>) {
  const isTablet = useIsTablet();
  const track = useTracking();
  const router = useRouter();
  const isGroup = useGroup();

  if (isEmpty(items)) return null;

  function truncateItems(items: Breadcrumb[]) {
    const allowedChars = isTablet ? 24 : 40;
    const dottedItems = items.map((item, index) => {
      if (index !== items.length - 1 && item.label.length > allowedChars) {
        return {
          ...item,
          label: truncateAtSpecificChar(item.label, allowedChars),
        };
      } else return item;
    });

    return dottedItems;
  }

  const breadcrumbItems = !isGroup ? truncateItems(items as Breadcrumb[]) : items;

  const onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void = (e) => {
    e.preventDefault();

    track.trackEvent(breadcrumbTrackingAction(e.currentTarget.text));
    router.push(e.currentTarget.pathname);
  };

  return (
    <Container
      className={classNamesBuilder(
        styles.breadcrumb,
        className ?? '',
        styles.gdds,
        !isGroup && 'nord',
        !isGroup && styles.nord,
      )}
      maxContentWidth={buildSize(gridSizes[!isGroup ? 'gddsFullGrid' : 'full'])}
    >
      <Row>
        <Col>
          <Breadcrumb
            items={breadcrumbItems.map((i, index) => ({
              text: index === 0 ? '' : decodingContent(i.label),
              url: (!i.breadcrumbDisabled && i.url) ? i.url : '',
              onClick,
            }))}
          />
        </Col>
      </Row>
    </Container>
  );
}
