import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';

// components
import DateOrTimePickerGdds from './date-or-time-picker-gdds';
import Datepicker from './date-picker/date-picker';
import TimePicker from './time-picker/time-picker';

// utils
import { isEmpty } from 'utils/is-empty';
import { useGdds } from 'utils/hooks/use-gdds';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { Log } from 'services/log';

export type DateOrTimePickerField = {
  name: string;
  type: string;
  fieldType: string;
  placeholder: string;
  label: string;
  comment: string;
  rules: Rule[];
  readonly?: boolean;
  disabled?: boolean;
};

interface DateOrTimePickerProps {
  field: DateOrTimePickerField;
  hasValidPrecondition?: string;
}

export default function DateOrTimePicker({
  hasValidPrecondition,
  field,
}: Readonly<DateOrTimePickerProps>) {
  const { name, fieldType } = field;

  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<Record<string, string>>();
  const dateformat = useDateFormat();
  const isGdds = useGdds();

  const isTimePicker = fieldType === 'form_field_timepicker';
  const Field = isTimePicker ? TimePicker : Datepicker;

  useEffect(() => {
    // if this field has a precondition and this is not enabled, don't throw an error
    if (errors[name] && hasValidPrecondition) {
      delete errors[name];
    }

    // reset value if it is hidden
    if (values[name] && hasValidPrecondition) {
      setFieldValue(name, '');
    }
  }, [errors, hasValidPrecondition, name, setFieldValue, values]);

  /**
   * Create timeString and set the value to formik
   *
   * @param time
   */
  const onChange = (date: Date | null) => {
    let timeString: string = '';

    if (date) {
      try {
        if (fieldType === 'form_field_timepicker') {
          timeString = format(new Date(isGdds ? `2000-01-01 ${date}` : date), 'HH:mm');
        } else if (!isGdds) {
          timeString = format(new Date(date), dateformat);
        }
      } catch (e) {
        Log.error('Failed to format date', e);
      }
    }

    setFieldTouched(name, true);
    setFieldValue(name, timeString);
    setSelectedTime(date);
  };

  const getClassNames = () => {
    const classes = ['form-item'];

    if (touched[name]) {
      classes.push('form-item--filled');
    }

    if (isTimePicker) {
      classes.push('form-item__timepicker');
    } else {
      classes.push('form-item__datepicker');
    }

    // isEmpty returns true if a number is written
    if (touched[name] && isEmpty(values[name]) && !hasValidPrecondition) {
      classes.push('form-item--empty');
    }

    if (!isEmpty(errors[name]) && touched[name]) {
      classes.push('form-item--error');
    }

    return classes.join(' ');
  };

  if (isGdds) {
    return <DateOrTimePickerGdds field={field} />;
  }

  return (
    <Field
      onChange={onChange}
      field={field}
      className={getClassNames()}
      selectedTime={selectedTime}
    />
  );
}
