import { useRef } from 'react';
import { AccordionItem } from '@geberit/gdds';

// styles
import styles from './detail-accordion.module.scss';

// types
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';
import type { ProductDetailAccordionProps } from './types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { ProductDetailArticleListing } from '../DetailArticleListing/ProductDetailArticleListing';
import ProductDetailList from '../DetailList/ProductDetailList';
import ProductDetailTechnicalDrawings from '../DetailTechnicalDrawings/ProductDetailTechnicalDrawings';
import ProductDetailTechnicalData from '../DetailTechnicalData/ProductDetailTechnicalData';
import ProductDetailDownloads from '../DetailDownloads/ProductDetailDownloads';
import ProductVideos from '../Videos/ProductVideos';
import ProductDetailSpareParts from '../DetailSpareParts/ProductDetailSpareParts';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { getSectionId } from 'utils/get-section-id';
import { panelUuids, sortScope } from './utils';

export function ProductDetailGddsAccordion(props: Readonly<ProductDetailAccordionProps>) {
  const {
    product: {
      additionalInformation,
      applicationPurposes,
      articles,
      categories,
      characteristics,
      documents,
      gallery,
      key,
      name: productName,
      scopeOfDelivery,
      spareParts,
      technicalDataList,
      videos,
    },
    catalogLanguage,
    isPanelActive,
    onChange,
  } = props;
  const hasArticleDrawings = articles.articlesList.some(
    (listItem) => !isEmpty(listItem.gallery.list),
  );
  const opened = useRef<typeof panelUuids[keyof typeof panelUuids][]>([]);

  const onAccordionChange = (e: boolean, panelUuid: typeof panelUuids[keyof typeof panelUuids]) => {
    if (e) {
      opened.current = [...opened.current, panelUuid];
    } else {
      opened.current = opened.current.filter((uuid) => uuid !== panelUuid);
    }
    onChange(opened.current);
  };

  const translate = useTranslationFunction();

  return (
    <div className={styles.productDetailGddsAccordion}>
      <Headline className={styles.accordionHeadline} isFlexItem title={translate('web20_product_details')} tag={Formats.h2} />

      <div id="product-detail">
        <AccordionItem
          fontSize="p1"
          tag="h3"
          iconAlignment="right"
          id={getSectionId(translate('web20_products_articles'))}
          title={translate('web20_product_details_articles', false, { no: `${articles.articlesListSize}` })}
          onChange={(e) => onAccordionChange(e, panelUuids.detail)}
        >
          {isPanelActive(panelUuids.detail) ? (
            <ProductDetailArticleListing
              articles={articles}
              productKey={key}
              categories={categories}
            />
          ) : <></>}
        </AccordionItem>
      </div>

      {!isEmpty(additionalInformation.list) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          id={getSectionId(translate('web20_product_basic_info'))}
          title={translate('web20_product_basic_info')}
          onChange={(e) => onAccordionChange(e, panelUuids.additionalInformation)}
        >
          {isPanelActive(panelUuids.additionalInformation) ? (
            <ProductDetailList content={additionalInformation.list[0]?.items ?? []} />
          ) : <></>}
        </AccordionItem>
      )}

      {(!isEmpty(gallery.list) || hasArticleDrawings) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_technical_drawings')}
          id={getSectionId(translate('web20_product_technical_drawings'))}
          onChange={(e) => onAccordionChange(e, panelUuids.technicalDrawings)}
        >
          {isPanelActive(panelUuids.technicalDrawings) ? (
            <>
              {!isEmpty(gallery.list) && (
                <ProductDetailTechnicalDrawings
                  categories={categories}
                  productName={productName}
                  productDrawings={gallery}
                />
              )}
              {hasArticleDrawings && (
                <ProductDetailTechnicalDrawings
                  articleDrawings={articles.articlesList.filter(
                    (listItem) => !isEmpty(listItem.gallery.list),
                  )}
                  categories={categories}
                  productName={productName}
                />
              )}
            </>
          ) : <></>}
        </AccordionItem>
      )}

      {!isEmpty(technicalDataList.list) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_technical_info')}
          id={getSectionId(translate('web20_product_technical_info'))}
          onChange={(e) => onAccordionChange(e, panelUuids.technicalDataList)}
        >
          {isPanelActive(panelUuids.technicalDataList) ? (
            <ProductDetailTechnicalData data={technicalDataList.list} />
          ) : <></>}
        </AccordionItem>
      )}

      {!isEmpty(applicationPurposes.list) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_application_purpose')}
          id={getSectionId(translate('web20_product_application_purpose'))}
          onChange={(e) => onAccordionChange(e, panelUuids.applicationPurposes)}
        >
          {isPanelActive(panelUuids.applicationPurposes) ? (
            <ProductDetailList content={sortScope(applicationPurposes.list)} />
          ) : <></>}
        </AccordionItem>
      )}

      {!isEmpty(characteristics.list) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_characteristics')}
          id={getSectionId(translate('web20_product_characteristics'))}
          onChange={(e) => onAccordionChange(e, panelUuids.characteristics)}
        >
          {isPanelActive(panelUuids.characteristics) ? (
            <ProductDetailList content={sortScope(characteristics.list)} />
          ) : <></>}
        </AccordionItem>
      )}

      {!isEmpty(scopeOfDelivery.included) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_scope_of_delivery')}
          id={getSectionId(translate('web20_product_scope_of_delivery'))}
          onChange={(e) => onAccordionChange(e, panelUuids.scopeOfDelivery)}
        >
          {isPanelActive(panelUuids.scopeOfDelivery) ? (
            <ProductDetailList content={sortScope(scopeOfDelivery.included)} />
          ) : <></>}
        </AccordionItem>
      )}

      {!isEmpty(scopeOfDelivery.notIncluded) && (
        <AccordionItem
          fontSize="p1"
          tag="h4"
          iconAlignment="right"
          title={translate('web20_product_to_order_additionally')}
          id={getSectionId(translate('web20_product_to_order_additionally'))}
          onChange={(e) => onAccordionChange(e, panelUuids.notIncluded)}
        >
          {isPanelActive(panelUuids.notIncluded) ? (
            <ProductDetailList content={sortScope(scopeOfDelivery.notIncluded)} />
          ): <></>}
        </AccordionItem>
      )}

      {(!isEmpty(documents.list) || !isEmpty(videos.list)) && (
        <div id="product-documents">
          <AccordionItem
            fontSize="p1"
            tag="h4"
            iconAlignment="right"
            title={translate('web20_product_files_assets')}
            id={getSectionId(translate('web20_product_files_assets'))}
            onChange={(e) => onAccordionChange(e, panelUuids.documents)}
          >
            {isPanelActive(panelUuids.documents) ? (
              <>
                <ProductDetailDownloads documents={documents} />
                <ProductVideos videos={videos.list} />
              </>
            ) : <></>}
          </AccordionItem>
        </div>
      )}

      {!isEmpty(spareParts.list) && (
        <div id="product-spareParts">
          <AccordionItem
            fontSize="p1"
            tag="h4"
            iconAlignment="right"
            title={translate('web20_products_spare_parts')}
            id={getSectionId(translate('web20_products_spare_parts'))}
            onChange={(e) => onAccordionChange(e, panelUuids.spareParts)}
          >
            {isPanelActive(panelUuids.spareParts) ? (
              <ProductDetailSpareParts
                content={spareParts.list}
                catalogLanguage={catalogLanguage}
              />
            ) : <></>}
          </AccordionItem>
        </div>
      )}
    </div>
  );
}
