'use client';

// types
import type { DownloadcenterProps, DownloadCenterContainerProps } from 'features/download-center/components/types';

// components
import DownloadCenterContainer from 'features/download-center/components/country/downloadcenter-container';
import Footer from 'components/Footer/Footer';

export interface DownloadCenterCountryProps extends DownloadcenterProps, DownloadCenterContainerProps {
  metaData: MetaData;
}

export default function DownloadCenterCountry({
  page,
  contentAreas,
  initialData,
}: Readonly<DownloadCenterCountryProps>) {
  return (
    <>
      <main>
        <DownloadCenterContainer
          pageFromServer={page}
          contentAreas={contentAreas}
          initialData={initialData}
        />
      </main>
      <Footer />
    </>
  );
}
