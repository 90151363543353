import { Dropdown as GddsDropdown } from '@geberit/gdds';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import key from 'weak-key';

// styles
import styles from 'components/ContentElements/Form/form.module.scss';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { isEmpty } from 'utils/is-empty';
import { useGdds } from 'utils/hooks/use-gdds';
import { decodingContent } from 'utils/decodingContent';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

export type DropdownField = {
  name: string;
  comment: string;
  type: string;
  label: string;
  options: Option[];
  rules: Rule[];
};

interface DropdownProps {
  field: DropdownField;
  hasValidPrecondition?: string;
}

export default function Dropdown({
  field: { options, name, rules, comment, type, label },
  hasValidPrecondition,
}: Readonly<DropdownProps>) {
  const isGdds = useGdds();
  const groupSelectionDefault = useTranslationByKey('web20_chat_group_selection_default_value');
  const { setFieldValue, setFieldTouched, handleBlur, handleChange, values, errors, touched } =
    useFormikContext<Record<string, string>>();

  useEffect(() => {
    // reset value if it is hidden
    if (values[name] && hasValidPrecondition && !isEmpty(options)) {
      setFieldValue(name, options[0].value);
    }
  }, [hasValidPrecondition, name, options, setFieldValue, values]);

  if (isGdds) {
    const required =
      !isEmpty(rules) && rules.some((attribute) => attribute.formValidator.includes('NotEmpty'));

    const onChange = (option) => {
      setFieldTouched(name, true);
      setFieldValue(name, option.value);
    };

    return (
      <div
        className={
          !isEmpty(errors[name]) && touched?.[name] ? `form-item--error ${styles.hasError}` : ''
        }
      >
        <GddsDropdown
          name={name}
          placeholder={
            ((type === 'group_select' || type === 'select') && groupSelectionDefault) || ''
          }
          errors={(touched?.[name] && (errors as Record<string, string>)) || {}}
          onChange={onChange}
          label={label}
          items={options?.map((i) => ({ id: i.title, text: i.title, ...i }))}
          values={values}
          outlined
          required={required}
        />
      </div>
    );
  }

  return (
    <div
      className={classNameBuilder(
        'form-item form-item--dropdown',
        !isEmpty(errors[name]) && touched[name] && 'form-item--error',
      )}
    >
      <div className="dropdown__trigger">
        <select
          title={comment}
          id={name}
          name={name}
          value={values[name]}
          onChange={(e) => {
            e.target.blur();
            handleBlur(e);
            handleChange(e);
          }}
        >
          {(type === 'group_select' || type === 'select') && (
            <option value="">{groupSelectionDefault}</option>
          )}
          {!isEmpty(options) &&
            options.map((option) => (
              <option value={option.value} key={key({ option })}>
                {decodingContent(option.title || option.label)}
              </option>
            ))}
        </select>
      </div>
      <InnerHtml
        as="p"
        className="form-item__error-message"
        content={
          (errors[name] &&
            touched[name] &&
            !isEmpty(rules) &&
            errors[name] !== 'keepErrorMessage' &&
            errors[name]) ||
          ''
        }
      />
    </div>
  );
}
