import styled from 'styled-components';

export const StyledContainer = styled.div`
  grid-area: secondary;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > .select {
    ul {
      li {
        min-height: 2.5rem;
        padding-left: 0.625rem;

        & > div {
          display: none;
        }

        :last-of-type {
          padding-bottom: 0.625rem;
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.medium} {
    flex-direction: row;
    flex-wrap: wrap;
    --gap: 1.5rem;
    gap: var(--gap);

    & > .select {
      flex: 0 0 calc(50% - (calc(var(--gap)) / 2));
    }
  }

  @media ${({ theme }) => theme.device.large} {
    flex-wrap: nowrap;
    & > .select {
      min-width: 10rem;
      flex: unset;

      & > div & > div & > div {
        background-color: transparent;
      }

      & div[name] {
        background-color: #f7f7f7;
      }
    }
  }
`;
