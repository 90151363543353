import { useRef, useEffect, useState, useId } from 'react';

// types
import type { VideoLightboxProps } from 'components/ContentElementsGdds/video-channel/types';

// components
import { VideoLightboxView } from './video-lightbox-view';
import VideoModalContent from 'components/ContentElementsGdds/video/video-modal-content';

// utils
import { trackMIVideo } from 'components/Video/miVideo/trackingActions';
import { useUCConsent } from 'features/cookie-consent';
import { useNord } from 'utils/hooks/use-nord';
import { useXy } from 'utils/hooks/use-xy';
import { getStringSpecificId } from 'utils/slug';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export default function VideoLightbox({
  videoId = '',
  isYoutubeVideo,
  onClose,
  title,
  isHeroImageForegroundVideoPlayer,
  ...props
}: Readonly<VideoLightboxProps>) {
  const [hasVideoError, setHasVideoError] = useState(false);
  const isNord = useNord();
  const isXy = useXy();
  const [videoReady, setVideoReady] = useState(false);
  const ucConsent = useUCConsent();
  const mounted = useRef(false);
  const videoTrackRef = useRef(0);
  const ga4VideoTrackRef = useRef(0);
  const track = useTracking();
  const { trackMI24Video } = useTracking2();
  const generatedId = useId();
  const id = getStringSpecificId('video-content', videoId, generatedId);
  let videoPlayerWindowObject;

  useEffect(() => {
    mounted.current = true;

    if (ucConsent.Comfort === true) {
      // check if mi video script is already given
      const mi24Script = document.getElementById('mi24-js-script');
      if (!isYoutubeVideo && !mi24Script) {
        const script = document.createElement('script');
        script.src = `https://e.video-cdn.net/v2/embed.js`;
        script.id = 'mi24-js-script';
        script.async = true;
        script.addEventListener('load', () => {
          script.setAttribute('data-loaded', 'true');
          if (mounted.current) setVideoReady(true);
        });
        document.head.appendChild(script);
      } else if (!isYoutubeVideo && mounted.current) {
        if (mi24Script?.getAttribute('data-loaded')) {
          setVideoReady(true);
        } else {
          mi24Script?.addEventListener('load', () => {
            if (mounted.current) setVideoReady(true);
          });
        }
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [ucConsent, isYoutubeVideo]);

  const handleClose = () => {
    onClose();

    if (typeof window !== 'undefined' && videoPlayerWindowObject && !isYoutubeVideo) {
      window.VideoPlayer.Collection.removePlayerById(id);
      videoPlayerWindowObject = false;
    }
  };

  const initVideo = () => {
    if (!isYoutubeVideo) {
      if (window.VideoPlayer && !videoPlayerWindowObject) {
        videoPlayerWindowObject = true;

        try {
          window.VideoPlayer.Collection.addPlayerById(id, {
            success: (playerApi) => {
              trackMIVideo(videoTrackRef, playerApi, title, videoId, track);
              trackMI24Video(ga4VideoTrackRef, playerApi, title, videoId);
            },
          });
        } catch {
          setHasVideoError(true);
        }
      }
    }
  };

  if (!ucConsent.Comfort) {
    return null;
  }

  if (isXy || isNord) {
    return (
      <VideoModalContent
        {...props}
        hasVideoError={hasVideoError}
        videoId={videoId}
        playerControl={props.playerControl}
        showLink={props.showLink}
        isYoutubeVideo={isYoutubeVideo}
        handleClose={handleClose}
        videoReady={videoReady}
        title={props.videoTitle}
      />
    );
  }

  return (
    <VideoLightboxView
      {...props}
      title={title}
      hasVideoError={hasVideoError}
      videoId={videoId ?? ''}
      isYoutubeVideo={isYoutubeVideo ?? false}
      initVideo={initVideo}
      handleClose={handleClose}
      videoReady={videoReady}
      isHeroImageForegroundVideoPlayer={isHeroImageForegroundVideoPlayer}
    />
  );
}
