import keyGen from 'weak-key';

// types
import type { ProductTechnicalDataItem } from '../product.types';

// compontents
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';

type ProductDetailTechnicalDataProps = {
  data: ProductTechnicalDataItem[];
};

const ProductDetailTechnicalData = ({ data }: Readonly<ProductDetailTechnicalDataProps>) => {
  const isDesktop = useIsDesktop();
  const columnNumber = isDesktop ? 2 : 1;
  const rows = data.map((row) => (
    <tr key={keyGen(row)}>
      <InnerHtml as="td" content={row.name} />
      <InnerHtml as="td" content={row.value} />
    </tr>
  ));

  const columns = rows.reduce(
    (acc, val, key) => {
      acc[key < rows.length / columnNumber ? 0 : 1].push(val);
      return acc;
    },
    Array(columnNumber)
      .fill()
      .map(() => []),
  );

  return (
    <div className="table-container table-container--technical-data">
      <div className="grid-x">
        {columns.map((column, key) => (
          <div className="cell large-6" key={keyGen({ key })}>
            <table className="table-full-width plain">
              <tbody>{column}</tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailTechnicalData;
