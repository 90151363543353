// types
import type { ProductDocument } from '../product.types';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { isEmpty } from 'utils/is-empty';

type ProductDetailDownloadsProps = {
  documents: {
    list: ProductDocument[];
  };
};

function ProductDetailDownloads({ documents: { list } }: Readonly<ProductDetailDownloadsProps>) {
  if (isEmpty(list)) {
    return null;
  }

  return (
    <table className="c-product-downloads plain">
      <thead>
        <tr>
          <th>
            <Translation id="web20_product_file" />
          </th>
        </tr>
      </thead>
      <tbody>
        {list.map((document) => (
          <tr key={document.url}>
            <td>
              <div className="row">
                <a href={document.url} target="_blank" rel="noopener noreferrer">
                  <span className="c-product-downloads__icon">
                    <i className="c-product-downloads__icon__background" />
                    <span className="c-product-downloads__icon__label">
                      {document.url.split('.').pop()}
                    </span>
                  </span>
                  <span className="c-product-downloads__title">{document.description}</span>
                </a>
              </div>
              <div className="row c-product-downloads__lang_List">
                <b>
                  <Translation id="web20_product_file_language" />:
                </b>
                {document.languages.join(', ')}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProductDetailDownloads;
