import axios from 'axios';
import { ListResult, SearchResult } from '../types/mynewsdesk.types';

// constants
import { ExcutionResult, RequestConfig } from '../types/press-portal.types';
import getEndpoint from 'utils/endpoints';

const commonParams = {
  order: 'published',
};

export async function* listGenerator({ state, ...params }: RequestConfig) {
  const listRoute = `${getEndpoint('newsDesk', state)}/list`;
  let offset = 0;

  while (true) {
    const response = await axios
      .get<ListResult>(listRoute, {
        params: {
          ...commonParams,
          ...params,
          offset,
        },
      })
      .then((r) => r.data);

    offset += response.items.length;
    if (response.items.length === 0 || response.total_count <= offset) {
      return response;
    }

    yield response;
  }
}

export async function* searchGenerator({ state, ...params }: RequestConfig) {
  const listRoute = `${getEndpoint('newsDesk', state)}/search`;
  let page = 1;

  while (true) {
    const response = await axios
      .get<SearchResult>(listRoute, {
        params: {
          ...commonParams,
          ...params,
          page,
        },
      })
      .then((response) => response.data);

    page = response.search_result.summary.page + 1;
    if (response.search_result.summary.page_count < page) {
      return response;
    }

    yield response;
  }
}

export function listResultsReducder(
  acc: ExcutionResult,
  meterial: IteratorResult<ListResult, ListResult>,
) {
  return {
    items: [...acc.items, ...(meterial.value ? meterial.value.items : [])],
    done: acc.done && !!meterial.done,
  };
}

export function searchResultsReducder(
  acc: ExcutionResult,
  meterial: IteratorResult<SearchResult, SearchResult>,
) {
  return {
    items: [...acc.items, ...(meterial.value ? meterial.value.search_result.items : [])],
    done: acc.done && !!meterial.done,
  };
}
