import React, { useEffect, useState, type JSX } from 'react';
import _throttle from 'lodash.throttle';
import key from 'weak-key';

// Components
import Swipeable from './Swipeable';
import PaginationButtons from '../Pagination/PaginationButtons';
import { BUTTON_TYPES, Button } from '../Button/Button';

interface SliderNavigationProps {
  length: number;
  isComparison?: boolean | null;
  onChange?: (index: number) => void;
  renderResponsiveTableColumn: (index: number) => JSX.Element;
}

function SliderNavigation({
  length,
  renderResponsiveTableColumn,
  isComparison = null,
  onChange = () => {},
  ...props
}: Readonly<SliderNavigationProps>) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastCol = length - 1;

  useEffect(() => {
    if (currentIndex > lastCol) {
      setCurrentIndex(lastCol);
    }
  }, [currentIndex]);

  const handleSwipe = _throttle(
    (isNext) => {
      if (isNext) {
        handleClickButtonRight();
      } else {
        handleClickButtonLeft();
      }
    },
    500,
    { trailing: false },
  );

  const handleClickDots = (index) => {
    if (index < length && index >= 0) {
      onChange(index);
      setCurrentIndex(index);
    }
  };

  const handleClickButtons = (direction) => {
    if (currentIndex + direction >= 0 && currentIndex + direction < length) {
      onChange(currentIndex + direction);
      setCurrentIndex(currentIndex + direction);
    }
  };

  const handleClickButtonLeft = () => {
    handleClickButtons(-1);
  };

  const handleClickButtonRight = () => {
    handleClickButtons(1);
  };

  const column = renderResponsiveTableColumn(currentIndex);

  return (
    <>
      <Swipeable
        onSwipingLeft={() => handleSwipe(true)}
        onSwipingRight={() => handleSwipe()}
        {...props}
      >
        <div className="grid-x slider--navigation">
          {length > 1 && (
            <Button
              type={BUTTON_TYPES.PLAIN}
              symbol="chevron-large-left"
              className={`${
                currentIndex <= 0
                  ? 'cell shrink align-middle arrow-left'
                  : 'cell shrink align-middle arrow-left is--active'
              }`}
              onClick={handleClickButtonLeft}
            />
          )}
          <div className="cell auto">{column}</div>
          {length > 1 && (
            <Button
              type={BUTTON_TYPES.PLAIN}
              symbol="chevron-large-right"
              className={`${
                currentIndex === lastCol
                  ? 'cell shrink align-middle arrow-right'
                  : 'cell shrink align-middle arrow-right is--active'
              }`}
              onClick={handleClickButtonRight}
            />
          )}
        </div>
        {length > 1 && (
          <PaginationButtons
            index={currentIndex}
            length={length}
            handleClickIndex={handleClickDots}
            key={key({ currentIndex })}
            isComparison={isComparison}
          />
        )}
      </Swipeable>
      {length === 1 && isComparison && (
        <div className="c-progress-bar c-progress-bar--fullwidth ">&nbsp;</div>
      )}
    </>
  );
}

export default SliderNavigation;
