// utils
import getEndpoint from 'utils/endpoints';
import { createCachedFunction } from './create-cached-function';

const cachedFetch = createCachedFunction(8000);

export const fetchPrice = async (article, lang, state): Promise<number> => {
  const endpoint = `${getEndpoint(
    'webshop',
    state,
  )}/article/${article}/price?lang=${lang}&quantity=1`;

  return new Promise(async (resolve, reject) => {
    if (!lang || !article) reject(new Error('No lang or article provided'));

    cachedFetch(async () => fetch(endpoint).then((response) => response.json()), endpoint)
      .then((response) => {
        resolve(response.price);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
