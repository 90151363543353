import { SyntheticEvent } from 'react';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import striptags from 'striptags';
import { Button } from '@geberit/gdds';

// styles
import styles from './courseTeaser.module.scss';

// types
import type { CourseDateList, CourseMetaItem } from '../types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Icon } from 'components/Icon/Icon';
import { Translation } from 'components/Translation/Translation';
import { CourseInformationItems } from './CourseInformationItems';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { isEmpty } from 'utils/is-empty';
import { initSamlWorkflow } from '../../Session/SessionContext';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { generateSlug } from 'utils/slug';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';

interface CourseTeaserProps {
  icon: string;
  expirationDate?: string;
  courseId: number;
  courseType: string;
  courseHeadline: string | undefined;
  courseDescription: string | undefined;
  targetGroup: string;
  courseDate?: CourseDateList;
  availableSeats?: number;
  seats?: number;
  duration?: string;
  costs?: string | number;
  costsIncluded?: CourseMetaItem[];
  // location?: string;
  language?: string;
  showDetailsButton?: boolean;
  className?: string;
  isLandingPage?: boolean;
  isPopular?: boolean;
}

export function CourseTeaser({
  icon,
  expirationDate,
  courseId,
  courseType,
  courseHeadline,
  courseDescription,
  targetGroup,
  courseDate,
  availableSeats,
  seats,
  duration,
  costs,
  costsIncluded,
  // location,
  language,
  isPopular = false,
  showDetailsButton = false,
  className = '',
  isLandingPage = false,
}: Readonly<CourseTeaserProps>) {
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const lang = useCurrentLanguage();
  const { login, loggedIn, campusLoggedIn } = useCiamLogin(gigyaWindow);
  const referenceUrl = useSelector(campusReferenceUrlSelector);
  const router = useRouter();
  const detailsLink = {
    target: `${referenceUrl}${generateSlug(courseHeadline)}/crs_${courseId}-${lang}/`,
    type: 'internal_link',
    showArrow: true,
  };
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const openOrLogin = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isLandingPage) {
      if (loggedIn) {
        if (!campusLoggedIn) {
          initSamlWorkflow(detailsLink.target, lang);
          return;
        }
      } else {
        const country = lang.slice(lang?.length - 2);
        login(lang, `${country}_Campus`, detailsLink.target);
        return;
      }
    }
    router.push(detailsLink.target);
  };

  return (
    <>
      <div className={styles.courseTeaser}>
        <div className={classNameBuilder(styles.courseIcons, styles[icon])}>
          <span>
            <Icon symbol={icon} />
          </span>
        </div>
        {isDesktop && !isEmpty(expirationDate) && (
          <div className={styles.expirationDateWrapper}>
            <span className={styles.expirationDateInner}>
              <Icon symbol="info-circle-outline" />
              <Translation id="campus_expirationdate" />
            </span>
            {expirationDate}
          </div>
        )}
        <div className={styles.courseTeaserBodyWrapper}>
          <div className={classNameBuilder(styles.courseTeaserBody, className)}>
            <div className={styles.type}>
              {courseType}
              {isPopular && (
                <span className={styles.popularFlag}>
                  <Translation id="campus_course_flag_status_popular" />
                </span>
              )}
            </div>
            <Headline
              tag={Formats.h4}
              title={courseHeadline}
              className={styles.courseHeadline}
              isFlexItem
              text={striptags(courseDescription)}
            />
          </div>
          {isDesktop && showDetailsButton && (
            <div>
              <Button className={styles.buttonDetails} buttonType="button" onClick={openOrLogin}>
                <Translation id="campus_course" />
              </Button>
            </div>
          )}
        </div>
        <div className={styles.courseInformation}>
          <CourseInformationItems
            targetGroup={targetGroup}
            courseDate={courseDate}
            availableSeats={availableSeats}
            seats={seats}
            duration={duration}
            costs={costs}
            costsIncluded={costsIncluded}
            // location={location}
            language={language}
          />
        </div>
        {(isMobile || isTablet) && !isEmpty(expirationDate) && (
          <div className={styles.expirationDateWrapper}>
            <span className={styles.expirationDateInner}>
              <Icon symbol="info-circle-outline" />
              <Translation id="campus_expirationdate" />
            </span>
            {expirationDate}
          </div>
        )}
        {(isMobile || isTablet) && showDetailsButton && (
          <div className={styles.buttonDefaultContainer}>
            <Button className={styles.buttonDetails} buttonType="button" onClick={openOrLogin}>
              <Translation id="campus_course" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
