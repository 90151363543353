import { useCallback, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

// types
import type { ContentElementPayload } from '../ContentElements/content-elements-payload';

// components
import TableOfContentsView from './TableOfContentsView';

// utils
import { convertItems } from './tocDataAdapter';
import { anchorClick } from './trackingActions';
import { ContentContext, SET_TOC_DATA } from '../ContentArea/ContentContext';
import { isClient } from 'utils/environment';
import { scrollToSection } from 'utils/scrollTo';
import { stickyHeaderShownSelector } from 'utils/selectors/navigationSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslations } from 'utils/hooks/use-translations';

interface TableOfContentsProps {
  type: string;
  content: ContentElementPayload[];
  headline: string;
  isPreview?: boolean;
}

function TableOfContents({
  type,
  content,
  isPreview = false,
  headline,
}: Readonly<TableOfContentsProps>) {
  const track = useTracking();
  const {
    state: { tocData },
    dispatch,
  } = useContext(ContentContext) || {};
  const isSticky = useSelector(stickyHeaderShownSelector);
  const translations = useTranslations();
  const items = convertItems(isPreview, translations, type, content, headline);

  const handleDropdownClick = useCallback(
    (item, e) => {
      e.preventDefault();
      track.trackEvent(anchorClick(item.id));

      scrollToSection(item.id);

      if (isClient()) {
        const event = new CustomEvent('scrollToSection', {
          detail: { id: item.id.replace('product-', '') },
        });
        window.dispatchEvent(event);
      }
    },
    [track],
  );

  useEffect(() => {
    if (!isEmpty(items) && isEmpty(tocData)) {
      dispatch({ type: SET_TOC_DATA, tocData: items });
    }
  }, [dispatch, items, tocData]);

  if (isEmpty(items) || isSticky) {
    return null;
  }

  return (
    <TableOfContentsView
      items={items}
      placeholder={headline}
      handleDropdownClick={handleDropdownClick}
      type={type}
    />
  );
}

export default TableOfContents;
