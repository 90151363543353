// components
import { CountryForm, type CountryFormProps } from './country-form';
import { NordicsForm, type NordicsFormProps } from './nord/form';

// utils
import { useNord } from 'utils/hooks/use-nord';

export function FormSwitch(props: Readonly<CountryFormProps & NordicsFormProps>) {
  const isNord = useNord();

  if (isNord) {
    return <NordicsForm {...props} />;
  }

  return <CountryForm {...props} />;
}
