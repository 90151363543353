import { Typo, InnerHtml } from '@geberit/gdds';
import { useEffect, useRef, type MouseEvent, type KeyboardEvent } from 'react';

// styles
import styles from './video-tile.module.scss';

// types
import type { VideoProps } from './types';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';
import { useHashState } from 'utils/hooks/use-hash-state';
import { useXy } from 'utils/hooks/use-xy';

function VideoTile({
  screenSize = 'small',
  open = false,
  video: {
    title,
    description,
    video: { type, videoId, videoPreviewImage, videoTitle },
  },
  onClick,
}: Readonly<VideoProps>) {
  const [hash] = useHashState();
  const ucConsent = useUCConsent();
  const videoItemRef = useRef<HTMLDivElement>(null);
  const isXy = useXy();
  let previewImage = '';

  const handleOpen = (e?: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    if (e) e.preventDefault();
    if (typeof onClick === 'function') onClick();
  };

  useEffect(() => {
    if (hash === videoId && open && videoItemRef.current) {
      videoItemRef.current.scrollIntoView();
      handleOpen();
    }
  }, [open, hash]);

  if (isEmpty(videoId)) return null;

  const truncateAtWordBoundary = (text, maxLength, ellipsis = '…') =>
    text.replace(new RegExp(`^(.{${maxLength}}[^\\s]*).*`), `$1${ellipsis}`);

  if (!isEmpty(videoPreviewImage)) {
    previewImage =
      screenSize === 'small'
        ? videoPreviewImage.mobile
        : videoPreviewImage.desktop || videoPreviewImage.mobile;
  }

  if (ucConsent.Comfort === false) {
    return (
      <div className="c-video-center__item" id={videoId}>
        <VideoConsent type={type} />
      </div>
    );
  }

  return (
    <div className={classNameBuilder('c-video-center__item', styles.tile)} id={videoId}>
      <div
        className={classNameBuilder('image', ucConsent.Comfort || 'noconsent')}
        style={{ backgroundImage: `url(${previewImage})` }}
        role="img"
        aria-label={videoTitle}
        title={videoTitle}
      >
        <div
          role="button"
          tabIndex={0}
          className="description"
          onClick={handleOpen}
          onKeyPress={handleOpen}
          ref={videoItemRef}
        >
          <InnerHtml
            as="p"
            content={truncateAtWordBoundary(decodingContent(description), 150)}
          />
          {ucConsent.Comfort && (
            <button type="button" className="button">
              <Translation id="web20_video_play_button" />
            </button>
          )}
        </div>
      </div>
      <Typo tag={isXy ? "h3" : "p"} variant="p1">{decodingContent(title)}</Typo>
    </div>
  );
}

export default VideoTile;
