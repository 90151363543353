import { trackTemplate } from 'utils/tracking';

/**
 * Form actions
 * @param string text
 */
export function formAction(action, url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Forms',
      action,
      label: url,
      value: '0',
    },
  });
}

/**
 * Lead module form action
 * @param string name
 * @param string module
 */
export function formModuleAction(name, module) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Forms',
      action: `Submit ${module}`,
      label: name,
      value: '0',
    },
  });
}
