import { Accordion } from 'react-accessible-accordion';

// types
import type { ProductDetailAccordionProps } from './types';

// components
import { Translation } from 'components/Translation/Translation';
import { ICON_COLORS, Icon } from 'components/Icon/Icon';
import { ProductDetailAccordionItem } from './ProductDetailAccordionItem';
import ProductDetailList from '../DetailList/ProductDetailList';
import ProductDetailTechnicalData from '../DetailTechnicalData/ProductDetailTechnicalData';
import ProductDetailDownloads from '../DetailDownloads/ProductDetailDownloads';
import ProductDetailSpareParts from '../DetailSpareParts/ProductDetailSpareParts';
import { ProductDetailArticleListing } from '../DetailArticleListing/ProductDetailArticleListing';
import ProductDetailTechnicalDrawings from '../DetailTechnicalDrawings/ProductDetailTechnicalDrawings';
import ProductVideos from '../Videos/ProductVideos';

// utils
import { isEmpty } from 'utils/is-empty';

const sortList = (a, b) => a.sequence - b.sequence;
const mapName = (item) => item.name;

function ProductDetailAccordion({
  product: {
    articles,
    key,
    additionalInformation,
    technicalDataList,
    applicationPurposes,
    characteristics,
    scopeOfDelivery,
    documents,
    videos,
    spareParts,
    gallery,
    categories,
    name: productName,
  },
  detailOpen,
  sparePartsOpen,
  documentsOpen,
  technicalDrawingsOpen,
  scopeOfDeliveryOpen,
  notIncludedOpen,
  applicationPurposesOpen,
  characteristicsOpen,
  technicalDataListOpen,
  additionalInformationOpen,
  onChange,
  isPanelActive,
  catalogLanguage,
}: Readonly<ProductDetailAccordionProps>) {
  const hasArticleDrawings = articles.articlesList.some(
    (listItem) => !isEmpty(listItem.gallery.list),
  );

  const sortScope = (scope) => scope.sort(sortList).map(mapName);

  return (
    <div className="c-product-detail">
      <h2>
        <Translation id="web20_product_details" />
      </h2>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={[
          detailOpen && 'detail',
          additionalInformationOpen && 'additionalInformation',
          technicalDrawingsOpen && 'technicalDrawings',
          technicalDataListOpen && 'technicalDataList',
          applicationPurposesOpen && 'applicationPurposes',
          characteristicsOpen && 'characteristics',
          scopeOfDeliveryOpen && 'scopeOfDelivery',
          notIncludedOpen && 'notIncluded',
          sparePartsOpen && 'spareParts',
          documentsOpen && 'documents',
        ].filter(Boolean)}
        className="c-accordion"
        onChange={(e) => onChange(e)}
      >
        <ProductDetailAccordionItem
          title={
            <Translation
              id="web20_product_details_articles"
              placeholder={{ no: articles.articlesListSize }}
            />
          }
          uuid="detail"
          id="product-detail"
        >
          {isPanelActive('detail') && (
            <ProductDetailArticleListing
              articles={articles}
              productKey={key}
              categories={categories}
            />
          )}
        </ProductDetailAccordionItem>

        {!isEmpty(additionalInformation.list) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_basic_info" />}
            icon={<Icon symbol="info-circle-full" color={ICON_COLORS.PRIMARY_DARK} />}
            uuid="additionalInformation"
            id="product-additionalInformation"
          >
            {isPanelActive('additionalInformation') && (
              <ProductDetailList content={additionalInformation.list[0].items} />
            )}
          </ProductDetailAccordionItem>
        )}

        {(!isEmpty(gallery.list) || hasArticleDrawings) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_technical_drawings" />}
            uuid="technicalDrawings"
            id="product-technicalDrawings"
          >
            {isPanelActive('technicalDrawings') && (
              <>
                {!isEmpty(gallery.list) && (
                  <ProductDetailTechnicalDrawings
                    productDrawings={gallery}
                    categories={categories}
                    productName={productName}
                  />
                )}
                {hasArticleDrawings && (
                  <ProductDetailTechnicalDrawings
                    articleDrawings={articles.articlesList.filter(
                      (listItem) => !isEmpty(listItem.gallery.list),
                    )}
                    categories={categories}
                    productName={productName}
                  />
                )}
              </>
            )}
          </ProductDetailAccordionItem>
        )}

        {!isEmpty(technicalDataList.list) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_technical_info" />}
            uuid="technicalDataList"
            id="product-technicalDataList"
          >
            {isPanelActive('technicalDataList') && (
              <ProductDetailTechnicalData data={technicalDataList.list} />
            )}
          </ProductDetailAccordionItem>
        )}

        {!isEmpty(applicationPurposes.list) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_application_purpose" />}
            uuid="applicationPurposes"
            id="product-applicationPurposes"
          >
            {isPanelActive('applicationPurposes') && (
              <ProductDetailList content={sortScope(applicationPurposes.list)} />
            )}
          </ProductDetailAccordionItem>
        )}

        {!isEmpty(characteristics.list) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_characteristics" />}
            uuid="characteristics"
            id="product-characteristics"
          >
            {isPanelActive('characteristics') && (
              <ProductDetailList content={sortScope(characteristics.list)} />
            )}
          </ProductDetailAccordionItem>
        )}

        {!isEmpty(scopeOfDelivery.included) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_scope_of_delivery" />}
            uuid="scopeOfDelivery"
            id="product-scopeOfDelivery"
          >
            {isPanelActive('scopeOfDelivery') && (
              <ProductDetailList content={sortScope(scopeOfDelivery.included)} />
            )}
          </ProductDetailAccordionItem>
        )}
        {!isEmpty(scopeOfDelivery.notIncluded) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_to_order_additionally" />}
            uuid="notIncluded"
            id="product-notIncluded"
          >
            {isPanelActive('notIncluded') && (
              <ProductDetailList content={sortScope(scopeOfDelivery.notIncluded)} />
            )}
          </ProductDetailAccordionItem>
        )}

        {(!isEmpty(documents.list) || !isEmpty(videos.list)) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_product_files_assets" />}
            uuid="documents"
            id="product-documents"
          >
            {isPanelActive('documents') && (
              <>
                <ProductDetailDownloads documents={documents} />
                <ProductVideos videos={videos.list} />
              </>
            )}
          </ProductDetailAccordionItem>
        )}

        {!isEmpty(spareParts.list) && (
          <ProductDetailAccordionItem
            title={<Translation id="web20_products_spare_parts" />}
            uuid="spareParts"
            id="product-spareParts"
          >
            {isPanelActive('spareParts') && (
              <ProductDetailSpareParts
                content={spareParts.list}
                catalogLanguage={catalogLanguage}
              />
            )}
          </ProductDetailAccordionItem>
        )}
      </Accordion>
    </div>
  );
}

export default ProductDetailAccordion;
