import { useContext, useRef } from 'react';
import { useFormikContext } from 'formik';
import key from 'weak-key';

// types
import type { InputFieldField } from './input-field';

// components
import UploadedFileInfo from '../file-upload/uploaded-file-info';
import FileUploadNordics from '../file-upload/file-upload-nordics';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { BUTTON_TYPES, Button } from 'components/Button/Button';

// utils
import { isEmpty } from 'utils/is-empty';
import { useNord } from 'utils/hooks/use-nord';
import { getErrorMessage, getUploadClassNames, useGetPlaceholder } from './utils';
import { FormContext } from 'components/ContentElements/Form/form-context';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface InputFieldViewProps {
  field: InputFieldField;
  className?: string;
  customType: string;
  deleteFile: (name: string, errorMessage: string, index: number) => void;
  handleOnChange: (
    e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    maxFileCount?: number,
  ) => void;
}

export function InputFieldView({
  field,
  className,
  customType,
  deleteFile,
  handleOnChange,
}: Readonly<InputFieldViewProps>) {
  const { type, name, rules, label, comment, maxFileCount, required } = field;
  const { values, errors, touched } = useFormikContext<Record<string, string>>();
  const { files, filesErrorMessage, setFilesErrorMessage } = useContext(FormContext);
  const inputRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);
  const isNordics = useNord();
  const placeholderString = useGetPlaceholder(field);
  const Field = type === 'textarea' ? 'textarea' : 'input';
  const errorMessage = getErrorMessage(errors, touched, name, rules, type, filesErrorMessage);
  const isFileUpload = type === 'file';
  const translate = useTranslationFunction();

  const handleDeleteFile = (index) => {
    deleteFile(name, required ? (rules[0].errorMessage ?? '') : '', index);
  };

  if (isFileUpload && isNordics) {
    return (
      <FileUploadNordics
        label={label}
        comment={comment}
        ref={inputRef as React.MutableRefObject<HTMLInputElement | null>}
        name={name}
        type={type}
        customType={customType}
        className={className}
        handleOnChange={handleOnChange}
        maxFileCount={maxFileCount ?? 0}
        placeholderString={placeholderString}
        deleteFile={deleteFile}
        rules={rules ?? []}
        errorMessage={errorMessage}
        files={files}
        values={values}
      />
    );
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={getUploadClassNames(isFileUpload, files, name, maxFileCount)}
      >
        <Field
          ref={inputRef}
          title={comment}
          id={name}
          name={name}
          value={isFileUpload ? undefined : values[name]}
          {...(type === 'number'
            ? { inputMode: 'numeric', pattern: '[0-9]*', type: 'text' }
            : { type: customType ?? type })}
          onChange={(e) => {
            handleOnChange(e, maxFileCount);
          }}
        />
        {placeholderString && <InnerHtml as="span" content={placeholderString} />}
        {isFileUpload && <InnerHtml as="span" content={label} />}
      </label>
      {isFileUpload &&
        !isEmpty(files) &&
        files[name]?.map((file, index) => (
          <UploadedFileInfo
            key={key(file)}
            fileName={file.name}
            deleteFile={() => handleDeleteFile(index)}
          />
        ))}
      <p className="form-item__error-message">
        <InnerHtml as="span" content={errorMessage} />
        {errorMessage === translate('web20_forms_invalid_filetype') && (
          <Button
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            onClick={() => {
              isEmpty(files[name]) && rules[0]?.errorMessage
                ? setFilesErrorMessage(rules[0].errorMessage)
                : setFilesErrorMessage(undefined);
            }}
          />
        )}
      </p>
    </div>
  );
}
