// components
import { Dropdown } from 'components/Dropdown/Dropdown';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

export interface TableOfContentsViewProps {
  placeholder: string | undefined;
  handleDropdownClick: (e: any, id: string) => void;
  items: any[];
  type?: string | null;
}

function TableOfContentsView({
  placeholder = undefined,
  handleDropdownClick,
  type = null,
  items = [],
}: Readonly<TableOfContentsViewProps>) {
  const filteredItems = type !== 'product' ? items.filter((item) => item.titleInAnchor) : items;
  const translate = useTranslationFunction();

  if (filteredItems.length < 1) {
    return null;
  }

  return (
    <div className="grid-container c-table-of-content--wrapper">
      <div className="c-table-of-content--wrapper">
        <Dropdown
          placeholder={placeholder}
          label={placeholder}
          items={filteredItems}
          handleDropdownClick={handleDropdownClick}
          textBeforePlaceholder={translate('web20_topic')}
          showArrowLeft
          className="c-table-of-content"
          showLabelAsItem={false}
        />
      </div>
    </div>
  );
}

export default TableOfContentsView;
