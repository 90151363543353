'use client';

// styles
import styles from './nordics.module.scss';

// types
import type { DownloadCenterContainerNordProps } from 'features/download-center/components/types';

// components
import DownloadCenterContainer from 'features/download-center/components/nordics/downloadcenter-container';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Layout from 'scenes/Nordics/layout';

export interface DownloadCenterNordicsProps extends DownloadCenterContainerNordProps {
  metaData: MetaData;
  breadcrumbs: Content['breadcrumbs'];
}

export default function DownloadCenterNordics({
  breadcrumbs,
  page,
  contentAreas,
  initialData,
}: Readonly<DownloadCenterNordicsProps>) {
  return (
    <Layout>
      <Breadcrumb items={breadcrumbs} className={styles.breadcrumbs} />
      <DownloadCenterContainer page={page} contentAreas={contentAreas} initialData={initialData} />
    </Layout>
  );
}
