import { useSelector } from 'react-redux';

// types
import { ProductDetailArticleListingProps } from './types';

// components
import { ResponsiveTable } from 'components/ResponsiveSlideTable/ResponsiveTable';
import ProductPrice from '../DetailFilters/ProductPrice';

// utils
import { pdpFiltersSelector, webshopEnabledSelector } from 'utils/selectors/globalsSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useIsMobile } from 'components/App/SizeProvider';

export function ProductDetailArticleListing({
  articles: { articlesList, attributesList },
  productKey,
  categories,
}: Readonly<ProductDetailArticleListingProps>) {
  const isMobile = useIsMobile();
  const priceTranslation = useTranslationByKey('web20_webshop_basket_price');
  const pdpFilters = useSelector(pdpFiltersSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);

  const visibleCols = isMobile ? 2 : 12;

  let hasPrices = false;
  const articlesListPrepared =
    pdpFilters && webshopEnabled && !webshopDowntime
      ? articlesList.map((article) => {
          if (article.state.buyable) {
            article.attributeValues.push({
              key: 'price',
              value: <ProductPrice articleId={article.key} />,
              renderReact: true,
            });
            hasPrices = true;
          }
          return article;
        })
      : articlesList;

  const attributesListPrepared =
    hasPrices && pdpFilters && webshopEnabled && !webshopDowntime
      ? [...attributesList, { key: 'price', name: priceTranslation }]
      : attributesList;

  return (
    <ResponsiveTable
      hasActionColumn
      numberOfVisibleCols={visibleCols}
      articlesList={articlesListPrepared}
      attributesList={attributesListPrepared}
      productKey={productKey}
      categories={categories}
    />
  );
}
