import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import striptags from 'striptags';
import { Search, Typo, Row, Col } from '@geberit/gdds';

// styles
import { SearchBarWrapper } from './search-bar-wrapper.styles';

// utils
import { fetchSuggestions, setSearchOffset, setSearchQuery } from '../../Search/actions';
import { Translation } from '../../Translation/Translation';
import { selectSearchQuery } from '../../../utils/selectors/searchSelectors';
import { useOutsideClick } from '../../../utils/hooks/use-outside-click';
import { useTranslationFunction } from '../../../utils/hooks/use-translations';

type Props = {
  count: number;
};

export default function SearchBar({ count }: Readonly<Props>) {
  const clickOutside = useRef(undefined);
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectSearchQuery);
  const translate = useTranslationFunction();
  const [suggestions, setSuggestions] = useState<{ content: string }[]>([]);
  const [suggestValue, setSuggestValue] = useState();

  useOutsideClick(clickOutside, () => {
    setSuggestions([]);
  });

  useEffect(() => {
    (async () => {
      if (suggestValue) {
        const data = await dispatch(fetchSuggestions(suggestValue));
        setSuggestions((data as unknown as string[]).map((el) => ({ content: striptags(el) })));
      }
    })();
  }, [dispatch, suggestValue]);

  return (
    <SearchBarWrapper>
      <Row>
        <Col size={[0, 0, 3]} />
        <Col size={[4, 8, 6]} className="search-wrapper">
          <div ref={clickOutside}>
            <Search
              onSubmit={(e) => {
                dispatch(setSearchQuery(e));
              }}
              onChange={(e) => {
                setSuggestValue(e);
                if (e === '') {
                  dispatch(setSearchQuery(e));
                  dispatch(setSearchOffset(0));
                }
              }}
              placeholder={translate('web20_search_placeholder')}
              suggestions={suggestions}
              val={searchQuery}
            />
          </div>
          <Typo as="span" variant="p1" className="result-hint">
            {count > 0 ? (
              <Translation
                id="web20_search_stats"
                placeholder={{
                  query: searchQuery || '',
                  count,
                }}
              />
            ) : (
              <Translation id="web20_search_no-matches" placeholder={{ query: searchQuery }} />
            )}
          </Typo>
        </Col>
      </Row>
    </SearchBarWrapper>
  );
}
