import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { InnerHtml, Typo, Col, Row, Icon, Button } from '@geberit/gdds';

// styles
import styles from './downloadcenter.module.scss';

// types
import type { DownloadcenterListItemProps } from '../types';

// utils
import { humanizeFileSize } from 'utils/fileSize';
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { clickDownloadsLink } from 'features/download-center/tracking';
import { classNameBuilder } from 'utils/classNameBuilder';
import mapItemFiletype from './utils/mapItemFileType';
import { decodingContent } from 'utils/decodingContent';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTracking as useGA4Tracking } from 'utils/tracking/track';
import { getFullPath } from '../../helpers';

export default function DownloadcenterListItem(props: Readonly<DownloadcenterListItemProps>) {
  const { item, setDownloadCounter, setOrderCounter, downloads, orders } = props;
  const isMobile = useIsMobile();
  const [downloaded, setDownloaded] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const translate = useTranslationFunction();
  const track = useTracking();
  const { trackClick } = useGA4Tracking();

  const toggleDownload = () => {
    setDownloaded(!downloaded);
    setDownloadCounter();

    let clickIntent = 'add to basket - download center';

    if (downloaded) {
      clickIntent = 'remove from basket - download center';
    }

    trackClick({
      click_intent: clickIntent,
      click_element: 'download center',
      file_name: decodingContent(item.title),
    });
  };

  const toggleOrder = () => {
    setOrdered(!ordered);
    setOrderCounter();

    let clickIntent = 'add to order - download center';

    if (ordered) {
      clickIntent = 'remove from order - download center';
    }

    trackClick({
      click_intent: clickIntent,
      click_element: 'download center',
      file_name: decodingContent(item.title),
    });
  };

  // show document
  const handleDownloadsLinkClick = (name, fileUrl = '', fileType = 'file') => {
    track.trackEvent(clickDownloadsLink(name, item.title));

    trackClick({
      click_intent: `open ${fileType} - download center`,
      click_url: getFullPath(fileUrl) ?? fileUrl,
      file_name: decodingContent(item.title),
      file_extension: fileType,
      value: 5,
    });
  };

  // set downloaded and ordered on load to set the corresponding button style
  useEffect(() => {
    if (downloads) {
      let isDownloaded = false;
      downloads.forEach((download) => {
        if (item.id === download.id) {
          isDownloaded = true;
        }
      });
      setDownloaded(isDownloaded);
    }

    if (orders) {
      let isOrdered = false;
      orders.forEach((order) => {
        if (item.id === order.id) {
          isOrdered = true;
        }
      });
      setOrdered(isOrdered);
    }
  }, [downloads, item.id, orders, setDownloaded, setOrdered]);

  return (
    <Row className={styles.resultRow}>
      {isMobile && (
        <Col size={[2, 2, 2]} className={styles.mobileDate}>
          {format(new Date(item.date), 'dd.MM.yyyy')}
        </Col>
      )}
      <Col size={[4, 4, 6]}>
        <InnerHtml as="div" content={decodingContent(item.title, true)} />
      </Col>
      {isMobile && (
        <Col size={[8, 4, 2]}>
          <Row className={classNameBuilder(styles.mobileFileInfo, styles.fileInfoRow)}>
            <span className={styles.fileIcon}>
              <Icon
                symbol={
                  mapItemFiletype[item?.meta?.indexer_filetype?.[0]]
                    ? mapItemFiletype[item?.meta?.indexer_filetype?.[0]]
                    : 'File'
                }
              />
            </span>
            {`${
              item?.meta?.fileExtension
                ? item?.meta?.fileExtension?.[0].toUpperCase()
                : item?.meta?.indexer_filetype?.[0]?.toUpperCase()
            } (${humanizeFileSize(item.size * 1000, true).toUpperCase()})`}
          </Row>
        </Col>
      )}
      <Col size={[4, 2, 2]}>
        <div
          className={
            isMobile
              ? classNameBuilder(styles.iconsContainerMobile, styles.iconsContainer)
              : styles.iconsContainer
          }
        >
          <Row>
            <Col size={[2, 2, 4]} className={styles.iconContainer}>
              <span className={styles.iconWrapper}>
                <a href={item.meta?.fileUrl?.[0]} target="_blank" rel="noreferrer">
                  <Button
                    stylingType={isMobile ? 'icon' : 'flat'}
                    rounded
                    symbol="Catalogue"
                    isIcon
                    onClick={() =>
                      handleDownloadsLinkClick(
                        'Open',
                        item.meta?.fileUrl?.[0],
                        item?.meta?.fileExtension?.[0] ?? item.meta?.indexer_filetype?.[0],
                      )
                    }
                  />
                </a>
                {isMobile && (
                  <Typo className={styles.iconTextMobile}>
                    {translate('group_downloadcenter_open')}
                  </Typo>
                )}
              </span>
            </Col>
            <Col size={[2, 2, 4]} className={styles.iconContainer}>
              <span className={styles.iconWrapper}>
                <div>
                  <Button
                    onClick={() => {
                      toggleDownload();
                    }}
                    stylingType={isMobile ? 'icon' : 'flat'}
                    selected={downloaded}
                    rounded
                    symbol="Download"
                    isIcon
                  />
                </div>
                {isMobile && (
                  <Typo className={styles.iconTextMobile}>
                    {translate('group_downloadcenter_download')}
                  </Typo>
                )}
              </span>
            </Col>
            <Col size={[2, 2, 4]} className={styles.iconContainer}>
              <span className={styles.iconWrapper}>
                <div>
                  <Button
                    onClick={() => {
                      toggleOrder();
                    }}
                    stylingType={isMobile ? 'icon' : 'flat'}
                    rounded
                    selected={ordered}
                    symbol="Onlineshop"
                    isIcon
                    disabled={!(item.meta?.order?.[0] && item.meta?.order?.[0] === 'true')}
                  />
                </div>
                {isMobile && (
                  <Typo className={styles.iconTextMobile}>
                    {translate('group_downloadcenter_order')}
                  </Typo>
                )}
              </span>
            </Col>
          </Row>
        </div>
      </Col>
      {!isMobile && (
        <Col size={[8, 2, 2]}>
          <Row className={styles.fileInfoRow}>
            <span className={styles.fileIcon}>
              <Icon
                symbol={
                  mapItemFiletype[item?.meta?.indexer_filetype?.[0]]
                    ? mapItemFiletype[item?.meta?.indexer_filetype?.[0]]
                    : 'File'
                }
              />
            </span>
            {`${
              item?.meta?.fileExtension
                ? item?.meta?.fileExtension?.[0].toUpperCase()
                : item?.meta?.indexer_filetype?.[0]?.toUpperCase()
            } (${humanizeFileSize(item?.size * 1000, true).toUpperCase()})`}
          </Row>
        </Col>
      )}
      {!isMobile && <Col size={[2, 2, 2]}>{format(new Date(item.date), 'dd.MM.yyyy')}</Col>}
    </Row>
  );
}
