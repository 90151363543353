'use client';

import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import key from 'weak-key';

// components
import { ImageThumbnail } from 'components/image/image-thumbnail';
import { ProductTile } from 'components/ContentElementsGdds/Tiles/Product/product-tile';
import { SingleTileView } from './SingleTileView';

// utils
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { seoConformProductUrl, seoConformPLPUrl } from 'utils/seoConformProductUrl';
import { sortProductImages } from 'utils/sortProductImages';
import { responsiveImage } from 'utils/responsiveImage';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { useNord } from 'utils/hooks/use-nord';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';
import { useIsMobile } from 'components/App/SizeProvider';
import { getPrimaryImage } from '../DetailGallery/SelectPrimaryImage';
import { useTracking } from 'utils/tracking/track';
import { shoppingBasketItemsSelector } from 'utils/selectors/shoppingBasketSelector';
import { fetchPrice } from '../DetailFilters/actions';

interface SingleTileProps {
  product?: BastetItem;
  isCategoryPage?: boolean;
  total?: number;
  listingPage?: boolean;
  categoryName?: string;
  linkType?: string;
  cagegoryName?: string;
  language?: string;
  navigation?: NavigationItem[];
  withoutCategory?: boolean;
  categories?: { name: string; key: string }[];
  categoryId?: string;
  catalogUrl?: string;
  catalogLanguage?: string;
  isWebshop?: boolean;
  useProductTile?: boolean;
  okvType?: string;
  hideCount?: boolean;
  tracking?: (name: string) => void;
}

export function SingleTile({
  product,
  isCategoryPage = false,
  total,
  listingPage = false,
  categoryName,
  linkType,
  categories,
  withoutCategory = false,
  categoryId,
  isWebshop = false,
  useProductTile = false,
  ...props
}: Readonly<SingleTileProps>) {
  const isMobile = useIsMobile();
  const language = useCurrentLanguage();
  const navigation = useSelector(navigationMainItemsSelector);
  const translate = useTranslationFunction();
  const catalogUrl = translate('web20_ocb_link_target');
  const catalogLanguage = useSelector(currentCatalogSelector);
  const { trackWebshop } = useTracking();
  const basketItems = useSelector(shoppingBasketItemsSelector);
  const state = useStore();

  const [currentImage, setCurrentImage] = useState(null);
  const isNord = useNord();
  const productIsLoaded = () =>
    product.constructor === Object && product.gallery && product.gallery.list;

  useEffect(() => {
    const imageList = productIsLoaded();
    const image = imageList && getPrimaryImage(imageList);

    if (image) {
      setCurrentImage(image.medium);
    }

    if (isCategoryPage && product.pictureObject) {
      const setImage = responsiveImage(
        isMobile,
        product.pictureObjectMobile,
        product.pictureObject,
      );
      setCurrentImage(setImage.url);
    }
  }, [product]);

  const filterProductThumbnails = (imageContent, description) =>
    sortProductImages(product.gallery)
      .slice(0, 5)
      .map((imageItem) => (
        <ImageThumbnail
          key={key(imageItem)}
          className="product-tile"
          thumbnail={imageItem.thumbnail}
          content={imageContent}
          description={description}
          onChange={() => setCurrentImage(imageItem.medium)}
        />
      ));

  if (isEmpty(navigation) || !currentImage) {
    return null;
  }

  const getLinkPath = linkType === 'product_detail_page' ? seoConformProductUrl : seoConformPLPUrl;

  const productLinks =
    !withoutCategory &&
    getLinkPath(
      product,
      navigation[0].url,
      language,
      categories,
      isCategoryPage,
      categoryName,
      categoryId,
    );

  let productLink = !withoutCategory ? productLinks : `/${product.key}-${language}`;
  if (!isWebshop && catalogUrl) {
    try {
      const catalogUri = new URL(catalogUrl).origin;
      const lang = catalogLanguage.replace('_', '-');
      if (product.key) {
        productLink = `${catalogUri}/${lang}/${product.key}.html`;
      } else {
        productLink = `${catalogUri}/${catalogLanguage.replace('_', '-')}/systems/${
          product.categoryId
        }`;
      }
    } catch {
      productLink = '#';
    }
  }

  async function handleTracking() {
    if (isWebshop) {
      const articlesList = product.articles.articlesList;
      // if there are multiple articles, we don't want to track the view_item event here.
      // We track the event if the user use the dropdown to select a variant
      if (articlesList.length > 1) return;

      const variant = articlesList[0];
      const { key: item_id, articleId } = variant;
      const index = basketItems?.findIndex(
        (entry) => entry.productId === product.productId && entry.variantId === articleId,
      );
      const price = await fetchPrice(item_id, catalogLanguage, state);
      trackWebshop('view_item', {
        value: price,
        items: [
          {
            item_id,
            item_name: product.name,
            price,
            index,
            quantity: 1,
          },
        ],
      });
    }
  }

  if (isNord || useProductTile) {
    return (
      <ProductTile
        name={product.name}
        articles={product.articles}
        categories={product.categories}
        productLink={productLink}
        currentImage={currentImage}
        useFormatAsAspectRatio={false}
        onClick={handleTracking}
      />
    );
  }

  return (
    <SingleTileView
      isWebshop={isWebshop}
      productLink={productLink}
      isCategoryPage={isCategoryPage}
      product={product}
      currentImage={currentImage}
      filterProductThumbnails={filterProductThumbnails}
      total={total}
      categoryName={categoryName}
      listingPage={listingPage}
      imageAltPrefix={translate('web20_products_image_of')}
      imageAltSuffix={translate('web20_products_imagealt_suffix')}
      linkType={linkType}
      onClick={handleTracking}
      {...props}
    />
  );
}
