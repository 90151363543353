import * as React from 'react';
import { Container, Row } from '@geberit/gdds';

// styles
import styles from './teaser.module.scss';

// components
import TeaserElement from './teaser-element';
import TeaserLarge from './teaser-large';

// utils
import { isInViewport } from '../../../utils/hooks/useLazyLoad';
import { useIsMobile } from 'components/App/SizeProvider';

interface ListRowProps {
  row: Array<any>;
  isLandingPage?: boolean;
}

function ListRow({ row, isLandingPage = false }: Readonly<ListRowProps>) {
  const isMobile = useIsMobile();
  const leftImageRef = React.useRef(undefined);
  const rowRef = React.useRef(undefined);
  const [leftImageHeight, setLeftImageHeight] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const handleAnimation = () => {
    if (isInViewport(rowRef.current, -50)) {
      setVisible(true);
    }
  };

  React.useEffect(() => {
    if (rowRef.current && !visible) {
      handleAnimation();
      window.addEventListener('scroll', handleAnimation);
      window.addEventListener('resize', handleAnimation);
    }

    return () => {
      window.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', handleAnimation);
    };
  }, [visible]);

  React.useEffect(() => {
    // the timeout is needed to ensure the react element is rendered
    const timeout = setTimeout(() => {
      setLeftImageHeight(leftImageRef.current?.offsetHeight || 0);
    }, 50);
    return () => {
      clearTimeout(timeout);
    };
  }, [leftImageRef.current]);

  // large teaser
  if (row[0].tile.type === 'large') {
    return (
      <div ref={rowRef}>
        <TeaserLarge
          link={{
            label: row[0].tile.linkText,
            url: row[0].url,
          }}
          animated
          visible={visible}
          isMobile={isMobile}
          imageForLargeObject={row[0].tile.imageObject}
          imageForLargeObjectMobile={row[0].tile.imageObjectMobile}
          {...row[0].tile}
        />
      </div>
    );
  }

  // small and medium
  return (
    <div className={styles.listRow} ref={rowRef}>
      <Container maxContentWidth="78rem">
        <Row>
          {/* first column */}
          <TeaserElement
            size={row[0].tile?.type || 'small'}
            isCarousel={false}
            isMobile={isMobile}
            imageRef={leftImageRef}
            link={{
              label: row[0].tile.linkText,
              url: row[0].url,
            }}
            animated
            visible={visible}
            imageForSmallAndMediumObject={row[0].tile.imageObject}
            imageForSmallAndMediumObjectMobile={row[0].tile.imageObjectMobile}
            {...row[0].tile}
            isUppercase
            isFirstItem
            isLandingPage={isLandingPage}
          />

          {/* second column */}
          {row.length > 1 && (
            <TeaserElement
              size={row[1].tile?.type || 'small'}
              isCarousel={false}
              isMobile={isMobile}
              link={{
                label: row[1].tile.linkText,
                url: row[1].url,
              }}
              animated
              visible={visible}
              offsetTop={isMobile ? 40 : leftImageHeight / 2}
              imageForSmallAndMediumObject={row[1].tile.imageObject}
              imageForSmallAndMediumObjectMobile={row[1].tile.imageObjectMobile}
              {...row[1].tile}
              isUppercase
              isLastItem
              isLandingPage={isLandingPage}
            />
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ListRow;
