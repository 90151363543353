import { useRef } from 'react';

// components
import { Dropdown } from 'components/Dropdown/Dropdown';
import { BUTTON_TYPES, Button } from '../../Button/Button';

// utils
import { TAB_PRODUCT, TAB_CONTENT } from '../constants';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface SearchTabDropdownProps {
  activeTab: number;
  handleClick: (tab: number) => void;
  softContentCount: number;
  productsCount: number;
}

function SearchTabDropdown({
  handleClick,
  activeTab = 0,
  softContentCount = 0,
  productsCount = 0,
}: Readonly<SearchTabDropdownProps>) {
  const dropdownRef = useRef<any>(null);
  const translate = useTranslationFunction();

  const contentLabel = `${translate('web20_content')} (${softContentCount})`;

  const productsLabel = `${translate('web20_products_articles')} (${productsCount})`;

  const placeholder = activeTab === TAB_PRODUCT ? productsLabel : contentLabel;

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 medium-6">
        <Dropdown placeholder={placeholder} label={placeholder} ref={dropdownRef}>
          <Button
            type={BUTTON_TYPES.PLAIN}
            onClick={() => {
              handleClick(TAB_CONTENT);
              dropdownRef?.current?.setDropdownActive(false);
            }}
          >
            {contentLabel}
          </Button>

          <Button
            type={BUTTON_TYPES.PLAIN}
            onClick={() => {
              handleClick(TAB_PRODUCT);
              dropdownRef?.current?.setDropdownActive(false);
            }}
          >
            {productsLabel}
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}

export default SearchTabDropdown;
