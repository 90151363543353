import { useEffect, useState } from 'react';
import { Button, Divider, FormField, Dropdown } from '@geberit/gdds';

// styles
import styles from './registrationForm.module.scss';

// types
import type { FormikProps } from 'formik';
import { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { fieldHasError } from './validationFields';
import { useCreateFormLabel } from './hooks';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

interface PersonalDataProps {
  deleteParticipant?(event?: React.SyntheticEvent<HTMLButtonElement>): void;
  mandatoryErrorMessage?: string;
  invalidMailErrorMessage?: string;
  index?: number | string;
  ciamData?: boolean;
  roleItems?: SelectOption[];
  participateSelf?: boolean;
}

export function PersonalData({
  deleteParticipant,
  mandatoryErrorMessage,
  invalidMailErrorMessage,
  index = '',
  ciamData,
  roleItems = [],
  touched,
  handleBlur,
  handleChange,
  values,
  errors,
  participateSelf,
}: Readonly<
  PersonalDataProps &
    Pick<FormikProps<any>, 'errors' | 'values' | 'touched' | 'handleBlur' | 'handleChange'>
>) {
  const [fieldErrors, setFieldErrors] = useState({});
  const translate = useTranslationFunction();
  const createLabel = useCreateFormLabel();

  useEffect(() => {
    let newFieldErrors = {};
    let error;

    Object.entries(errors).forEach(([key, entry]) => {
      if (Array.isArray(entry)) {
        entry.forEach((obj) =>
          Object.keys(obj).forEach((fieldName) => {
            error = fieldHasError(fieldName, index, errors, touched);
            newFieldErrors = { ...newFieldErrors, [fieldName]: error };
          }),
        );
      } else {
        error = fieldHasError(key, index, errors, touched);
        newFieldErrors = { ...newFieldErrors, [key]: error };
      }
    });

    setFieldErrors(newFieldErrors);
  }, [errors, touched, values, index]);

  const currentValues = values.participants?.[index] ?? values;
  const salutationItems: SelectOption[] = [
    { id: '2', value: '2', text: translate('campus_courseregistration_salutation_1') },
    { id: '1', value: '1', text: translate('campus_courseregistration_salutation_2') },
    { id: '3', value: '3', text: translate('campus_courseregistration_salutation_3') },
  ];

  return (
    <div className="form-section">
      {deleteParticipant && (index !== 1 || participateSelf) && (
        <>
          <Divider />
          <div className="grid-x headline-button-wrapper">
            <strong>
              <Translation id="campus_headline_participants_information" />
            </strong>
            <Button
              stylingType="flatHighlight"
              className="delete-button"
              onClick={deleteParticipant}
              symbol="Trash"
              size="small"
            >
              <Translation id="campus_delete" />
            </Button>
          </div>
        </>
      )}
      <div className="grid-x">
        <div className="small-12 medium-6 left">
          {/* salutation */}
          <Dropdown
            errorMessage={mandatoryErrorMessage}
            errors={fieldErrors[`salutation${index}`]}
            name={`salutation${index}`}
            placeholder={translate('campus_please_select')}
            onChange={(item) =>
              handleChange({ target: { name: `salutation${index}`, value: item.value } })
            }
            items={salutationItems}
            values={currentValues}
            onBlur={handleBlur}
            label={createLabel('salutation')}
            outlined
            readOnly={ciamData}
          />
        </div>
      </div>
      <div className="grid-x">
        <div
          className={`small-12 medium-6 left ${
            fieldErrors[`firstname${index}`] ? styles.errorBorder : ''
          }`}
        >
          {/* firstname */}
          <FormField
            errorMessage={mandatoryErrorMessage}
            errors={fieldErrors[`firstname${index}`]}
            name={`firstname${index}`}
            label={createLabel('firstname')}
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            values={currentValues}
            readOnly={ciamData}
          />
        </div>
        <div
          className={classNameBuilder(
            'small-12 medium-6 right',
            fieldErrors[`lastname${index}`] && styles.errorBorder,
          )}
        >
          {/* lastname */}
          <FormField
            errorMessage={mandatoryErrorMessage}
            errors={fieldErrors[`lastname${index}`]}
            name={`lastname${index}`}
            label={createLabel('lastname')}
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            values={currentValues}
            readOnly={ciamData}
          />
        </div>
      </div>
      <div className="grid-x">
        <div
          className={`small-12 medium-6 left ${
            fieldErrors[`email${index}`] ? styles.errorBorder : ''
          }`}
        >
          {/* email */}
          <FormField
            name={`email${index}`}
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            label={createLabel('email')}
            errorMessage={invalidMailErrorMessage}
            invalidMailErrorMessage={invalidMailErrorMessage}
            errors={fieldErrors[`email${index}`]}
            values={currentValues}
            readOnly={ciamData}
          />
        </div>
        <div className="small-12 medium-6 right">
          {/* role */}
          <Dropdown
            errors={fieldErrors[`role${index}`]}
            name={`role${index}`}
            id="role"
            placeholder=""
            onChange={(item) =>
              handleChange({ target: { name: `role${index}`, value: item.value } })
            }
            items={roleItems}
            values={currentValues}
            onBlur={handleBlur}
            errorMessage={mandatoryErrorMessage}
            label={createLabel('role')}
            outlined
          />
        </div>
      </div>
    </div>
  );
}
