import { format } from 'date-fns';
import key from 'weak-key';
import { AccordionItem } from '@geberit/gdds';

// styles
import styles from './orderhistory.module.scss';

// types
import type { GddsOrdersAccordionProps, Order } from './order-history.types';

// components
import OrderHistoryDetails from './OrderHistoryDetails';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useDateFormat } from 'utils/hooks/use-dateformat';

export function GddsOrdersAccordion({ ciam, data }: Readonly<GddsOrdersAccordionProps>) {
  const translate = useTranslationFunction();
  const dateFormat = useDateFormat();

  const makeAccordionTitle = (item: Order) => {
    const orderNumber = `${translate('web20_orderhistory_no')} ${item.id}`;
    return `${format(new Date(item.date), dateFormat || 'yyyy-MM-dd')} ${orderNumber}`
  };

  return (
    <div className={styles.gddsOrdersAccordion}>
      {data.map((item) => (
        <AccordionItem
          key={key(item)}
          titleBold
          fontSize="p1"
          iconAlignment="right"
          title={
            item.id === 'PENDING'
              ? translate('web20_orderhistory_pending_order')
              : makeAccordionTitle(item)}
        >
          <div className="c-accordion__content">
            <OrderHistoryDetails orderId={item.id} ciam={ciam} />
          </div>
        </AccordionItem>
      ))}
    </div>
  );
}
