import { useEffect, type PropsWithChildren } from 'react';
import Modal from 'react-modal';

// types
import type { LightboxProps } from './lightbox.types';

// utils
import { useBodyScroll } from 'components/ContentElementsGdds/locator/utils/use-body-scroll';

export function Lightbox({
  additionalClass = '',
  additionalStyle,
  children,
  onAfterOpen = () => {},
  onCloseRequest,
  onKeyLeft = (e) => {
    e.preventDefault();
  },
  onKeyRight = (e) => {
    e.preventDefault();
  },
}: PropsWithChildren<LightboxProps>) {
  Modal.setAppElement(document.querySelector('body')?.firstChild);

  useEffect(() => {
    Modal.setAppElement(document.querySelector('body')?.firstChild);
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeyEvents);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleKeyEvents);
      }
    };
  });
  useBodyScroll(true);
  const handleKeyEvents = (e) => {
    // exclude form elements
    if (['input', 'select', 'button', 'textarea'].indexOf(e.target.tagName.toLowerCase()) > -1) {
      return null;
    }

    switch (e.key) {
      case 'ArrowLeft':
        onKeyLeft(e);
        break;
      case 'ArrowRight':
        onKeyRight(e);
        break;
      default:
        return e;
    }
    e.preventDefault();
    return null;
  };

  return (
    <Modal
      isOpen
      onAfterOpen={onAfterOpen}
      onRequestClose={onCloseRequest}
      style={{
        content: additionalStyle,
      }}
      className={{
        base: `c-lightbox ${additionalClass}`,
        afterOpen: '',
        beforeClose: '',
      }}
      overlayClassName={{
        base: 'c-lightbox__overlay',
        afterOpen: '',
        beforeClose: '',
      }}
    >
      {children}
    </Modal>
  );
}
