import React, { PropsWithChildren, useRef, useState } from 'react';
import { Button } from '@geberit/gdds';

// styles
import styles from './fixed-item-slider.module.scss';

// components
import { RangeIndicator } from '../range-indicator';

type FixedItemSliderProps = PropsWithChildren<{
  numColumns: number;
  isFixed?: boolean;
}>;

export const FixedItemSlider = (props: Readonly<FixedItemSliderProps>) => {
  const {
    children,
    isFixed = true,
    numColumns,
  } = props;

  const refSlider = useRef<HTMLDivElement>(null);
  const refSliderContainer = useRef<HTMLDivElement>(null);
  const [currentColumn, setCurrentColumn]= useState<number>(2);
  const previousIndicatorValue = useRef<number>(0);

  const handleForwardNavigation = (toColumn?: number) => {
    const nextItem = refSlider.current?.querySelector(`& > *:nth-child(${toColumn ?? currentColumn})`) as HTMLElement;
    refSlider.current?.scroll({
      left: nextItem?.offsetLeft,
      behavior: 'smooth',
    });
    setCurrentColumn(currentColumn => toColumn !== undefined ? (toColumn + 1) : (currentColumn + 1));
  };

  const handleBackwardsNavigation = (toColumn?: number) => {
    const previousItem = refSlider.current?.querySelector(`& > *:nth-child(${toColumn ?? currentColumn - 1})`) as HTMLElement;
    refSlider.current?.scroll({
      left: previousItem?.offsetLeft - previousItem?.clientWidth,
      behavior: 'smooth',
    });
    setCurrentColumn(currentColumn => toColumn ?? (currentColumn - 1));
  };

  const onRangeIndicatorChange = (value: number) => {
    if (value > previousIndicatorValue.current) {
      handleForwardNavigation(value + 1);
    } else {
      handleBackwardsNavigation(value + 2);
    }
    previousIndicatorValue.current = value;
  };

  const [FirstItem, ...RestItems] = React.Children.toArray(children) || [];

  return (
    <div className={styles.container}>
      <Button
        isIcon
        stylingType="icon"
        symbol="chevron_left"
        disabled={currentColumn === 2}
        className={styles.sliderNavigationButton}
        onClick={() => handleBackwardsNavigation()}
      />
      <div className={styles.content}>
        <div ref={refSliderContainer} className={styles.sliderContainer}>
          <div ref={refSlider} className={styles.slider}>
            {isFixed
              ? (
                <>
                  <div className={styles.sliderFixedItem}>
                    {FirstItem}
                  </div>
                  {RestItems}
                </>
              )
              : children
            }
          </div>
        </div>

        <RangeIndicator
          max={`${numColumns - 2}`}
          value={`${currentColumn - 2}`}
          handleChange={(e) => onRangeIndicatorChange(parseInt(e.target.value, 10))}
        />
      </div>
      <Button
        isIcon
        stylingType="icon"
        symbol="chevron_right"
        disabled={currentColumn === numColumns}
        className={styles.sliderNavigationButton}
        onClick={() => handleForwardNavigation()}
      />
    </div>
  );
};
