import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import { format, getYear } from 'date-fns';

// types
import type { DateOrTimePickerField } from '../date-or-time-picker';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// styles
import styles from '../styles.module.scss';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';
import { useLocale } from 'utils/use-locale';

interface DatePickerProps {
  field: DateOrTimePickerField;
  onChange: (date: Date | null) => void;
  className: string;
  selectedTime: Date | null;
  language: string;
}

export default function DatePicker({
  field: { name, rules, comment },
  onChange,
  className,
  selectedTime,
}: Readonly<DatePickerProps>) {
  const { values, errors, touched, setFieldTouched } = useFormikContext<Record<string, string>>();
  const dateformat = useDateFormat();
  const lang = useCurrentLanguage();
  const locale = useLocale(lang);
  const ref = useRef<ReactDatePicker>(null);
  const [hideDatePicker, setHideDatePicker] = useState<boolean>(false);

  useEffect(() => {
    if (locale.code) registerLocale(locale.code, locale);
  }, [locale]);

  const forceClose = () => {
    setHideDatePicker(true);
    // Needs a delay until the unexpected open event was thrown.
    setTimeout(function () {
      ref?.current?.setOpen(false);
      setHideDatePicker(false);
    }, 100);
  };

  const onChangeFunc = (date: Date | null) => {
    onChange(date);
    forceClose();
  };
  const onSelectFunc = () => {
    forceClose();
  };

  return (
    <div
      className={classNameBuilder(
        className,
        values[name] && 'form-item--filled',
        hideDatePicker ? styles.hideDatePicker : undefined,
      )}
    >
      {/* prevent events so the datepicker closes when selecting a date */}
      {/* prettier-ignore */}
      <label htmlFor={name} onClick={(e) => e.preventDefault()} onKeyUp={(e) => e.preventDefault()}>{/* NOSONAR */}
        <ReactDatePicker
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
        className='react-datepicker__custom-header'
        >
          <button className='react-datepicker__navigation react-datepicker__navigation--previous' onClick={decreaseMonth} disabled={prevMonthButtonDisabled} aria-label="Previous Month">
           <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
          </button>
           {date ? format(date, 'MMMM', { locale }): ""} {getYear(date)}

          <button className='react-datepicker__navigation react-datepicker__navigation--next' onClick={increaseMonth} disabled={nextMonthButtonDisabled} aria-label="Next Month">
           <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
          </button>
        </div>
      )}
          locale={locale.code}
          title={comment}
          id={name}
          value={values[name]}
          name={name}
          selected={selectedTime}
          dateFormat={dateformat}
          onChange={onChangeFunc}
          onSelect={onSelectFunc}
          ref={ref}
          onBlur={() => setFieldTouched(name, true)}
          autoComplete="off"
          popperPlacement="bottom-start"
        />
        {dateformat && <InnerHtml as="span" content={dateformat} />}
      </label>
      <InnerHtml
        as="p"
        className="form-item__error-message"
        content={
          (errors[name] &&
            touched[name] &&
            !isEmpty(rules) &&
            errors[name] !== 'keepErrorMessage' &&
            errors[name]) ||
          ''
        }
      />
    </div>
  );
}
