import key from 'weak-key';

// components
import { VideoSlider } from 'components/Slider';
import ChannelCarousel from 'components/ContentElementsGdds/video-channel/channel-carousel';
import { ContentAreaPlaceholder } from 'components/ContentArea/ContentAreaPlaceholder';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';

// utils
import { TPP_TYPE_VIDEOCHANNEL } from 'components/ContentCreator/tppConstants';
import { TOC_DATA_VIDEOCENTER } from 'components/TableOfContents/tocDataAdapter';
import { useNord } from 'utils/hooks/use-nord';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { isEmpty } from 'utils/is-empty';
import { useXy } from 'utils/hooks/use-xy';

interface VideoGalleryProps {
  pageHeadline: string;
  showAnchor?: boolean;
  previewId?: string;
  channels: any[];
  metaData?: MetaData;
}

export default function VideoGallery({
  pageHeadline,
  showAnchor = false,
  previewId,
  channels,
  metaData,
}: Readonly<VideoGalleryProps>) {
  const isPreview = useIsPreview();
  const isXy = useXy();
  const isNord = useNord();
  const isNordOrXy = isNord || isXy;
  const SliderComponent = isNordOrXy ? ChannelCarousel : VideoSlider;

  return (
    <>
      {showAnchor && (
        <TableOfContents type={TOC_DATA_VIDEOCENTER} headline={pageHeadline} content={channels} />
      )}
      <div className={classNameBuilder(!isNordOrXy && 'grid-container', 'c-video-center')}>
        {!isEmpty(channels) &&
          channels
            .filter((channel) => isPreview || !isEmpty(channel.videos))
            .sort((channelA, channelB) => channelA.index - channelB.index)
            .map((channel) => {
              const replaceSlashInPathFnc = (pathname) =>
                pathname.slice(-1) === '/' ? pathname.replace(/\/$/, '') : pathname;
              const url = `${replaceSlashInPathFnc(channel.url)}/?channel=${channel.id}`;
              return (
                <EditWrapper
                  editType={TPP_TYPE_VIDEOCHANNEL}
                  previewId={channel.previewId}
                  key={key(channel)}
                >
                  <SliderComponent
                    title={channel.title}
                    url={url}
                    videos={channel.videos}
                    metaData={metaData}
                  />
                </EditWrapper>
              );
            })}
        {isEmpty(channels) && isPreview && (
          <ContentAreaPlaceholder
            editType={TPP_TYPE_VIDEOCHANNEL}
            contentType="videos"
            previewId={`custom:newSection:${previewId}:videos`}
            editArea="Video Channels"
          />
        )}
      </div>
    </>
  );
}
