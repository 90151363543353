import React, { useRef } from 'react';
import Link from 'next/link';
import key from 'weak-key';

// components
import { Dropdown } from 'components/Dropdown/Dropdown';

// types
import type { Channel } from 'components/ContentElementsGdds/video-channel/types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface VideocenterDropdownProps {
  channels: Channel[];
  searchQueryChannelId: string;
}

function VideocenterDropdown({
  channels,
  searchQueryChannelId,
}: Readonly<VideocenterDropdownProps>) {
  const dropdownRef = useRef<any>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const translate = useTranslationFunction();
  const replaceSlashInPathFnc = (pathname) =>
    pathname.slice(-1) === '/' ? pathname.replace(/\/$/, '') : pathname;
  const currentChannel = channels.find((item) => item.id === searchQueryChannelId);
  const dropdownTitle = currentChannel && `${currentChannel.title} - `;
  const placeholder = `${dropdownTitle} ${translate('web20_videocenter_channel_change')}`;

  return (
    <div ref={wrapperRef}>
      <Dropdown
        placeholder={placeholder}
        label={placeholder}
        className="c-video-center__channel__selector"
        ref={dropdownRef}
      >
        <ul>
          {channels
            .filter((c) => c.id !== searchQueryChannelId)
            .map((channel) => (
              <li key={key(channel)}>
                <Link
                  href={`${replaceSlashInPathFnc(channel.url)}/?channel=${channel.id}`}
                  key={`${channel.reactKey}-routerlink`}
                  onClick={() => dropdownRef?.current?.setDropdownActive(false)}
                >
                  {decodingContent(channel.title)}
                </Link>
              </li>
            ))}
        </ul>
      </Dropdown>
    </div>
  );
}

export default VideocenterDropdown;
