// styles
'use client';

import { HiddenH1 } from './home.styles';

// components
import Layout from './Layout';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import { ContentArea } from 'components/ContentArea/ContentArea';

// utils
import { isEmpty } from 'utils/is-empty';
import { useContent } from 'components/Page/page-hooks';

export function Home() {
  const pageContent = useContent<{}, { slider: HeroImageGddsProps }>();

  if (isEmpty(pageContent?.contentAreas)) {
    return null;
  }

  const {
    metaData,
    contentAreas: { content },
    page: { slider },
    ...props
  } = pageContent;
  return (
    <Layout metaData={metaData}>
      {metaData?.title && <HiddenH1 className="gdds-headline">{metaData.title}</HiddenH1>}
      <HeroImageGdds {...slider}  />
      <ContentArea content={content} contentType="content" {...props} />
    </Layout>
  );
}
