import { format } from 'date-fns';

// types
import type { TilesProps } from '../../types';

// components
import { ICON_COLORS, Icon } from 'components/Icon/Icon';
import { Translation } from 'components/Translation/Translation';
import { Image as StaticImage } from 'components/image/image';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { formatBytes } from 'utils/format-bytes';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useLocale } from 'utils/use-locale';
import {
  clickAddToOrderCart,
  clickAddToDownloadCart,
  clickDownloadsLink,
} from 'features/download-center/tracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { isEmpty } from 'utils/is-empty';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { getFullPath } from '../../../helpers';
import { decodingContent } from 'utils/decodingContent';

export default function Tiles(props: Readonly<TilesProps>) {
  const { dateFormat, updateOrder, updateDownload, downloads, data, hasOrder } = props;
  const track = useTracking();
  const { trackClick } = useTracking2();
  const translate = useTranslationFunction();

  const {
    size: fileSize,
    date: translationDownloadPublishdate,
    // eslint-disable-next-line camelcase
    meta: {
      title,
      fileUrl,
      flipbookUrl,
      indexer_filetype,
      fileExtension,
      picture,
      order: showOrder,
    },
  } = data;

  const language = useCurrentLanguage();
  const locale = useLocale(language);

  const hasDownload = downloads.some((download) => download.id === data.id);

  const handleClickOrder = () => {
    // add tile product with default amount 1 to order section in cart
    const order = { ...data, amount: 1, title: title[0] };
    updateOrder(order);

    track.trackEvent(clickAddToOrderCart(title[0]));

    let clickIntent = 'add to order - download center';

    if (hasOrder) {
      clickIntent = 'remove from order - download center';
    }

    trackClick({
      click_intent: clickIntent,
      click_element: 'download center',
      file_name: decodingContent(title?.[0]),
    });
  };

  const handleClickDownload = () => {
    // add tile product to download section in cart
    updateDownload(data);

    track.trackEvent(clickAddToDownloadCart(data.title));

    let clickIntent = 'add to basket - download center';

    if (hasDownload) {
      clickIntent = 'remove from basket - download center';
    }

    trackClick({
      click_intent: clickIntent,
      click_element: 'download center',
      file_name: decodingContent(title?.[0]),
    });
  };

  const renderDownloadBadge = () => {
    if (hasDownload) {
      return <Icon symbol="download" color={ICON_COLORS.WHITE} />;
    }
    return null;
  };

  const handleDownloadsLinkClick = (name: 'Open' | 'Flipbook', fileUrl = '', fileType = 'file') => {
    track.trackEvent(clickDownloadsLink(name, data.title));
    if (name === 'Open')
      trackClick({
        click_intent: `open ${fileType} - download center`,
        click_url: getFullPath(fileUrl) ?? fileUrl,
        file_name: decodingContent(title?.[0]),
        file_extension: fileType,
        value: 5,
      });
  };

  const checkBooleanInArray = (array) => array.some((string) => string !== 'false');

  return (
    <div className="dc-tiles cell small-12 medium-12 large-4">
      <div className="dc-tiles__border">
        <div className="dc-tiles__badges">
          {renderDownloadBadge()}
          {hasOrder && <Icon symbol="shoppingcart" color={ICON_COLORS.WHITE} />}
        </div>
        <div className="dc-tiles__description">
          {!isEmpty(picture) && !isEmpty(picture[0]) && (
            <StaticImage src={picture[0]} alt={title[0]} title={title[0]} staticImage external />
          )}
          {!isEmpty(title) && !isEmpty(title[0]) && <InnerHtml as="p" content={title[0]} />}
        </div>
        <div className="dc-tiles__info">
          {!isEmpty(translationDownloadPublishdate) &&
            Boolean(locale) &&
            translationDownloadPublishdate !== '1970-01-01T00:00:00Z' && (
              <p>{format(new Date(translationDownloadPublishdate), dateFormat, { locale })}</p>
            )}
          {!isEmpty(indexer_filetype) && !isEmpty(indexer_filetype[0]) && (
            <span>
              <Icon symbol="file" color={ICON_COLORS.PRIMARY} />
              {String(indexer_filetype[0]).toUpperCase()}
            </span>
          )}
          {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
            <span>
              <Icon symbol="hard-drive" color={ICON_COLORS.PRIMARY} />
              {formatBytes(fileSize * 1000, 2)}
            </span>
          )}
        </div>
        <div className="dc-tiles__overlay">
          {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
            <a
              className="dc-tiles__overlay__interaction"
              target="_blank"
              rel="noopener noreferrer"
              href={fileUrl[0]}
              onClick={() =>
                handleDownloadsLinkClick(
                  'Open',
                  fileUrl[0],
                  fileExtension?.[0] ?? indexer_filetype?.[0],
                )
              }
            >
              <Icon symbol="file" color={ICON_COLORS.PRIMARY_DARK} />
              {translate('web20_downloadcenter_open')}
            </a>
          )}
          {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
            <button
              type="button"
              className="dc-tiles__overlay__interaction"
              onClick={handleClickDownload}
            >
              <Icon symbol="download" color={ICON_COLORS.WHITE} />
              {translate('web20_downloadcenter_download')}
            </button>
          )}
          {!isEmpty(showOrder) && checkBooleanInArray(showOrder) && (
            <button
              type="button"
              className="dc-tiles__overlay__interaction"
              onClick={handleClickOrder}
            >
              <Icon symbol="shoppingcart" color={ICON_COLORS.WHITE} />
              {translate('web20_downloadcenter_order')}
            </button>
          )}
          {!isEmpty(flipbookUrl) && !isEmpty(flipbookUrl[0]) && (
            <a
              className="dc-tiles__overlay__interaction"
              target="_blank"
              rel="noopener noreferrer"
              href={flipbookUrl[0]}
              onClick={() => handleDownloadsLinkClick('Flipbook')}
            >
              <Icon symbol="download-book" color={ICON_COLORS.PRIMARY_DARK} />
              <Translation id="web20_downloadcenter_flipbook" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
