// utils
import { isEmpty } from 'utils/is-empty';

export function checkHasValidPrecodition(preconditions: Preconditions['conditions'], values, name) {
  const fieldPreCondition = preconditions?.find((condition) => condition.fieldName === name);
  let hasValidPrecondition = '';

  if (!isEmpty(fieldPreCondition)) {
    const { targetFieldName, pattern } = fieldPreCondition;

    // if field has a precondition and its values does not match the given pattern hide it
    hasValidPrecondition =
      !isEmpty(values[targetFieldName]) && values[targetFieldName].includes(pattern) ? '' : ' hide';
  }

  return hasValidPrecondition;
}
