import { useRef } from 'react';
import SlickSlider from 'react-slick';
import key from 'weak-key';

// components
import ProductDetailSlide from './ProductDetailSlide';
import { imageAltSubcategory, imageAlt, getImageAltSuffix } from '../../../utils/imageAltTag';
import { Translation } from '../../Translation/Translation';

// utils
import { sortProductImages } from '../../../utils/sortProductImages';

function renderSingleSlide(slide) {
  return (
    <div className="slick-initialized slick-slider">
      <div className="slick-list">
        <div className="slick-track">
          <div className="slick-slide slick-current">
            <div>{slide}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ProductDetailGalleryProps {
  product: {
    gallery: {
      list: {
        orientation: string;
        type: string;
        medium: string;
        thumbnail: string;
      }[];
      state: {
        indicator: boolean;
      };
      categories: string[];
      name: string;
    };
  };
  imageAltPrefix?: string | null;
  imageAltSuffix?: string | null;
}

function ProductDetailGallery({
  product,
  imageAltPrefix = null,
  imageAltSuffix = null,
}: Readonly<ProductDetailGalleryProps>) {
  const sliderMain = useRef(undefined);
  const sliderThumbnail = useRef(undefined);

  /**
   * Render thumbnail slider with all images and sort it by the primary Image
   *
   * @param mode
   */
  const getProductGalleryItems = (mode) => {
    const { gallery, categories, name } = product;
    const imageAltCategory = imageAltSubcategory(categories);
    const imagePrefix = imageAlt(imageAltPrefix);
    const imageSuffix = getImageAltSuffix(imageAltSuffix);

    const imageContent = `${imagePrefix}${name} - ${imageAltCategory}${imageSuffix}`;

    return sortProductImages(gallery).map((imageItem) => {
      const image = mode === 'main' ? imageItem.medium : imageItem.thumbnail;
      return (
        <ProductDetailSlide
          format="1_1"
          image={image}
          imageAlt={imageContent}
          title={imageContent}
          key={key(imageItem)}
        />
      );
    });
  };

  const renderThumbnailSlider = (slides) => (
    <SlickSlider
      {...ProductDetailGallery.SLICK_THUMBNAIL_SETTINGS}
      // asNavFor={sliderMain}
      ref={sliderThumbnail}
      beforeChange={(_, newIndex) => sliderMain.current?.slickGoTo(newIndex)}
      slidesToShow={Math.min(slides.length, 4)}
    >
      {slides}
    </SlickSlider>
  );

  const renderMainSlider = (slides) => (
    <SlickSlider
      {...ProductDetailGallery.SLICK_MAIN_SETTINGS}
      // asNavFor={sliderThumbnail}
      ref={sliderMain}
      beforeChange={(_, newIndex) =>
        sliderMain.current && sliderThumbnail.current?.slickGoTo(newIndex)
      }
    >
      {slides}
    </SlickSlider>
  );

  const renderMainCarousel = () => {
    const slides = getProductGalleryItems('main');

    if (slides.length <= 1) {
      return renderSingleSlide(slides[0]);
    }

    return renderMainSlider(slides);
  };

  const renderThumbnailCarousel = () => {
    const slides = getProductGalleryItems('thumbnail');

    if (slides.length <= 1) {
      return renderSingleSlide(slides[0]);
    }

    return renderThumbnailSlider(slides);
  };

  const {
    state: { indicator },
  } = product;

  return (
    <div className="grid-container">
      <div className="c-product-detail__gallery grid-x grid-margin-x">
        <div className="c-product-detail__thumbnails-slider cell">{renderThumbnailCarousel()}</div>
        <div className="c-product-detail__main-slider cell medium-12 large-auto">
          {indicator && (
            <div className="c-product-detail__main-slider--state">
              <Translation id="web20_new_label" />
            </div>
          )}
          {renderMainCarousel()}
        </div>
      </div>
    </div>
  );
}

ProductDetailGallery.SLICK_MAIN_SETTINGS = {
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991, // Breakpoint "large"
      settings: {
        dots: false,
      },
    },
    {
      breakpoint: 767, // Breakpoint "small"
      settings: {
        dots: true,
      },
    },
  ],
};

ProductDetailGallery.SLICK_THUMBNAIL_SETTINGS = {
  dots: false,
  arrows: true,
  slidesToScroll: 1,
  vertical: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 767, // Breakpoint "small"
      settings: {
        vertical: false,
      },
    },
  ],
};

export default ProductDetailGallery;
